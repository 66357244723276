<div [ngStyle]="{
    'padding': '0px',
    'background-color': widgetSettings.widgetBackgroundColor ? widgetSettings.widgetBackgroundColor : 'white',
    'overflow': widgetSettings.widgetOverflow && !widgetEditMode && !widgetConfigureMode ? widgetSettings.widgetOverflow : 'auto',
    'height': '100%'
}" [ngClass]="{'loading in': loading || rejecting || signing}">

	<!-- View Mode-->
	<div class="content-container" *ngIf="!widgetEditMode && !widgetConfigureMode">
		<div class="sidebar" *ngIf="widgetSettings.displayViewer || (widgetSettings.displayMetadata || widgetSettings.displayWorkflow || widgetSettings.displayComments)">
			<div *ngIf="widgetSettings.displayViewer" class="viewer" (click)="showViewer()" [ngClass]="!displayMetadata && !displayComments ? 'selected' : ''">Viewer
			</div>
			<div *ngIf="widgetSettings.displayMetadata" class="metadata" (click)="showMetadata()" [ngClass]="displayMetadata ? 'selected' : ''">Metadata
			</div>
			<div *ngIf="commentsEnabled && widgetSettings.displayComments && type === 0" class="comments" (click)="showComments()" [ngClass]="displayComments ? 'selected' : ''">Comments ({{ commentsCount }})
			</div>
		</div>
		<!-- Viewer -->
		<div class="fill" [ngClass]="!widgetSettings.displayViewer || displayMetadata || displayComments ? 'hidden' : ''">

			<div *ngIf="!item" class="widget-message">
				<em _ngcontent-wsj-c5="" href="javascript:" class="far fa-file-image"></em> &nbsp; Viewer
			</div>
			<div *ngIf="showViewerHeader()" class="comments viewer-header">
				<ng-container *ngIf="enableAnnotations && canAnnotate && !widgetSettings.disableAnnotations && !viewError">
					<button type="button" [disabled]="loading" (click)="openAnnotationModal()" class="btn btn-danger">{{ annotateButtonLabel }}</button>&nbsp;
				</ng-container>
				<div *ngIf="viewError && !helloSignLoaded && !widgetSettings.displayWorkflow" class="error-message">
					<p>An error has occurred.</p>
				</div>
				<ng-container *ngIf="helloSignLoaded && !viewError">
					<button type="button" [disabled]="loading || signed" (click)="openModal(rejectCommentDialog, 'lg')" class="btn btn-danger">{{ helloSignRejectLabel }}</button>&nbsp;
					<button type="button" [disabled]="loading || signed" (click)="sign()" class="btn btn-primary">{{ helloSignSignLabel }}</button>&nbsp;
					<a *ngIf="helloSignMessage" class="info-link" href="javascript:" (click)="openModal(signInfoModal, 'lg')">
						<em class="fas fa-question tooltip-icon" tooltip="Text property" placement="bottom"></em>
					</a>
				</ng-container>
				<span class="viewer-workflow-section" [ngClass]="{'loading in': workflowLoading, 'dropdown': widgetSettings.workflowStateType === 'dropdown'}" *ngIf="widgetSettings.displayWorkflow && widgetSettings.alwaysDisplayWorkflow && workflowInfo && workflowInfo.id && !viewError">
					<span *ngIf="!widgetSettings.hideWorkflowName">Workflow: {{ workflowInfo.name }}</span>&nbsp;
					<span *ngIf="widgetSettings.workflowStateType === 'buttons'">
						<ng-container *ngFor="let state of workflowInfo.states">
							<button *ngIf="!widgetSettings.hideCurrentWorkflowState || (state.selectable && state.id !== workflowInfo.stateId)" class="btn btn-success" (click)="openUpdateState(state.id)" [disabled]="!state.selectable || state.id === workflowInfo.stateId"><img *ngIf="state.icon" alt="Workflow State Icon" [src]="'data:image/jpeg;base64,' + state.icon">&nbsp;{{ state.name }}</button>
						</ng-container>
					</span>
					<span *ngIf="widgetSettings.workflowStateType === 'dropdown'">
						<select (change)="openUpdateState($event.target.value)" [(ngModel)]="workflowInfo.stateId">
							<ng-container *ngFor="let state of workflowInfo.states">
								<option *ngIf="state.selectable" [value]="state.id">{{ state.name }}</option>
							</ng-container>
						</select>
					</span>
				</span>
			</div>
			<div class="the-preview" *ngIf="streamingVideoURL == ''  && !viewError">
				<div class="the-document" [ngClass]="!item ? 'hidden' : ''">
					<div *ngIf="viewerEnabled && configService.unsupportedPrizmFormats.indexOf(extension?.toLowerCase()) == -1" class="full-width viewer pccv" [ngClass]="{'header-present': showViewerHeader()}" id="prizmviewer"></div>
					<div *ngIf="viewerEnabled && objectTypeIcons[type]" class="full-width viewer pccv disabled-viewer object">
						<div class="object-icon-container">
							<em class="icon icon-file icon-other preview-icon" [ngStyle]="{'background': 'url(' + objectTypeIcons[type].changingThisBreaksApplicationSecurity + ') no-repeat', 'background-size': 'contain'}"></em>
						</div>
					</div>
					<div *ngIf="!viewerEnabled || (configService.unsupportedPrizmFormats.indexOf(extension?.toLowerCase()) > -1 && streamingVideoURL == '' && configService.supportedVideoFormats.indexOf(extension.toLowerCase()) == -1)" class="full-width viewer pccv disabled-viewer object">
						<embed *ngIf="embedable && downloadedUrl" [src]="downloadedUrl" width="100%" height="100%" />
						<div *ngIf="!embedable || !downloadedUrl || configService.unsupportedPrizmFormats.indexOf(extension?.toLowerCase()) > -1" class="object-icon-container">
							<em class="icon icon-file icon-other preview-icon" [ngClass]="'icon-' + extension"></em>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="streamingVideoURL !== ''">
				<iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" height="500" type="text/html" [src]="streamingVideoURL | safeUrl"></iframe>
			</div>
			<div *ngIf="streamingVideoURL == '' && configService.supportedVideoFormats.indexOf(extension?.toLowerCase()) > -1" class="auto-resizable-iframe">
				<div>
					<video [src]="downloadedUrl" width="100%" height="auto" controls>
						Your browser does not support the video tag.
					</video>
				</div>
			</div>

		</div>
		<!-- Metadata / Workflow-->
		<div class="fill" [ngClass]="!displayMetadata ? 'hidden' : ''  && !viewError">
			<!-- Metdata -->
			<div *ngIf="!(properties.length > 0)" class="widget-message">
				<em _ngcontent-wsj-c5="" href="javascript:" class="far fa-file-alt"></em> &nbsp; Metadata
			</div>
			<!-- Workflow -->
			<div class="workflow-container" [ngClass]="{'loading in': workflowLoading}" *ngIf="widgetSettings.displayWorkflow && workflowInfo && workflowInfo.id">
				<p *ngIf="!widgetSettings.hideWorkflowName">Workflow: <span>{{ workflowInfo.name }}</span></p>
				<span *ngIf="widgetSettings.workflowStateType === 'buttons'">
					<ng-container *ngFor="let state of workflowInfo.states">
						<button *ngIf="!widgetSettings.hideCurrentWorkflowState || (state.selectable && state.id !== workflowInfo.stateId)" class="btn btn-md btn-success" (click)="openUpdateState(state.id)" [disabled]="!state.selectable || state.id === workflowInfo.stateId"><img *ngIf="state.icon" alt="Workflow State Icon" [src]="'data:image/jpeg;base64,' + state.icon">&nbsp;{{ state.name }}</button>
					</ng-container>
				</span>
				<span *ngIf="widgetSettings.workflowStateType === 'dropdown'">
					<select (change)="openUpdateState($event.target.value)" [(ngModel)]="workflowInfo.stateId">
						<ng-container *ngFor="let state of workflowInfo.states">
							<option *ngIf="state.selectable" [value]="state.id">{{ state.name }}</option>
						</ng-container>
					</select>
				</span>
			</div>
			<!-- Metadata -->
			<div perfectScrollbar class="custom-scroll" *ngIf="widgetSettings.displayMetadata && properties.length > 0">
				<div class="metadata-table-outer">
					<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted metadata-table">
						<thead>
							<tr>
								<th id="property">Property</th>
								<th id="value">Value</th>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let prop of properties">
								<tr *ngIf="prop.show" [attr.style]="widgetSettings.customCss | customCssExtractor:'table-row'">
									<td class="title" [attr.style]="widgetSettings.customCss | customCssExtractor:'table-cell'"><span [attr.style]="widgetSettings.customCss | customCssExtractor:'property-label'">{{prop.name}}</span></td>
									<td [attr.style]="widgetSettings.customCss | customCssExtractor:'table-cell'"><span [attr.style]="widgetSettings.customCss | customCssExtractor:'property-value'">{{prop.value.displayValue}}</span></td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- Comments -->
		<div class="fill" [ngClass]="{'hidden': !displayComments, 'loading in': loadingComments}">
			<div class="metadata-table-outer">
				<div *ngIf="!item" class="widget-message">
					<em _ngcontent-wsj-c5="" href="javascript:" class="far fa-comments"></em> &nbsp; Comments
				</div>
				<div *ngIf="displayComments && type === 0 && item" class="comments">
					<div class="rootCommentResponse">
						<form (ngSubmit)="onCommentSubmit($event, commentArea)">
							<textarea #commentArea name="comment" placeholder="Enter a comment..." class="form-control"></textarea>
							<a href="" (click)="clearCommentForm(commentArea)"><button type="reset">Cancel</button></a>
							<a><button type="submit">Reply</button></a>
						</form>
					</div>
					<app-comments *ngFor="let comment of comments" (newComment)="onNewComment($event)" [comment]="comment" [item]="item"></app-comments>
				</div>
			</div>
		</div>
	</div>

	<!-- Edit Mode -->
	<div *ngIf="widgetEditMode" class="widget-message">This widget contains no editable properties</div>

	<!-- Configure Mode -->
	<div *ngIf="widgetConfigureMode" class="widget-configure">
		<div class="formGroup">
			<ul class="nav nav-tabs" id="myTab" role="tablist">
				<li class="nav-item" [ngClass]="{'active': configTab === 'config'}" (click)="configTab = 'config'">
					<a class="nav-link" [ngClass]="{'active': configTab === 'config'}" id="config-tab" data-toggle="tab" role="tab" aria-controls="dataview-config" aria-selected="true">Configuration</a>
				</li>
				<!--
				<li class="nav-item" [ngClass]="{'active': configTab === 'display'}" (click)="configTab = 'display'">
					<a class="nav-link" [ngClass]="{'active': configTab === 'display'}" id="display-tab" data-toggle="tab" role="tab" aria-controls="dataview-display" aria-selected="false">Display</a>
				</li>
				-->
			</ul>
			<div class="tab-content" id="myTabContent">
				<div class="tab-pane" [ngClass]="{'active': configTab === 'config'}" id="dataview-config" role="tabpanel" aria-labelledby="config-tab">
					<div class="col-md-12">
						<div class="col-md-12">
							<label for="displayViewer" class="control-label heavy">
								<span class="text-nowrap">Display Document Viewer</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="displayViewer" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.displayViewer" (ngModelChange)="onChange()" [disabled]="!widgetSettings.displayMetadata && !widgetSettings.displayWorkflow && !widgetSettings.displayComments" />
										<label for="displayViewer" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Display Document Viewer</strong> setting to hide or show an HTML5 rendition of a document.</div>
					</div>

					<div class="col-md-12">
						<div class="col-md-12">
							<label for="displayMetadata" class="control-label heavy">
								<span class="text-nowrap">Display Metadata</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="displayMetadata" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.displayMetadata" (ngModelChange)="onChange()" />
										<label for="displayMetadata" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Display Metadata</strong> setting to hide or show the object's metadata.</div>
					</div>

					<div class="col-md-12">
						<div class="col-md-12">
							<label for="displayWorkflow" class="control-label heavy">
								<span class="text-nowrap">Display Workflow</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="displayWorkflow" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.displayWorkflow" (ngModelChange)="onChange()" />
										<label for="displayWorkflow" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Display Workflow</strong> setting to hide or show the object's workflow information.</div>
					</div>

					<div class="col-md-12" *ngIf="widgetSettings.displayWorkflow">
						<div class="col-md-12">
							<label for="workflowStateType" class="control-label heavy">
								<span class="text-nowrap">Workflow States Type</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<select id="workflowStateType" [(ngModel)]="widgetSettings.workflowStateType" class="form-control" (change)="onChange()">
									<option value="buttons">Buttons</option>
									<option value="dropdown">Dropdown</option>
								</select>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Workflow States Type</strong> setting to determine how workflow states should be displayed.</div>
					</div>

					<div class="col-md-12" *ngIf="widgetSettings.displayWorkflow">
						<div class="col-md-12">
							<label for="workflowStateType" class="control-label heavy">
								<span class="text-nowrap">Hide Workflow Name</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="hideWorkflowName" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.hideWorkflowName" (ngModelChange)="onChange()" />
										<label for="hideWorkflowName" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Hide Workflow Name</strong> setting to not display the name of the object's workflow.</div>
					</div>

					<div class="col-md-12" *ngIf="widgetSettings.displayWorkflow">
						<div class="col-md-12">
							<label for="workflowStateType" class="control-label heavy">
								<span class="text-nowrap">Hide Current Workflow State</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="hideCurrentWorkflowState" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.hideCurrentWorkflowState" (ngModelChange)="onChange()" />
										<label for="hideCurrentWorkflowState" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Hide Current Workflow State</strong> setting to not display the current workflow state.</div>
					</div>

					<div class="col-md-12" *ngIf="widgetSettings.displayWorkflow">
						<div class="col-md-12">
							<label for="alwaysDisplayWorkflow" class="control-label heavy">
								<span class="text-nowrap">Display Workflow on All Tabs</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="alwaysDisplayWorkflow" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.alwaysDisplayWorkflow" (ngModelChange)="onChange()" />
										<label for="alwaysDisplayWorkflow" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5"><strong>Display Workflow on All Tabs</strong> setting to show the object's workflow information on all viewer widget tabs.</div>
					</div>

					<div class="col-md-12" *ngIf="commentsEnabled">
						<div class="col-md-12">
							<label for="displayWorkflow" class="control-label heavy">
								<span class="text-nowrap">Display Comments</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="displayComments" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.displayComments" (ngModelChange)="onChange()" />
										<label for="displayComments" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Display Comments</strong> setting to hide or show the object's comments.</div>
					</div>

					<div class="col-md-12" *ngIf="helloSignEnabled">
						<div class="col-md-12">
							<label for="helloSignEnabled" class="control-label heavy">
								<span class="text-nowrap">Enable HelloSign</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<div class="switch-row">
									<div class="switch">
										<input id="helloSignEnabled" type="checkbox" class="switch-input" [(ngModel)]="widgetSettings.helloSignEnabled" (ngModelChange)="onChange()" />
										<label for="helloSignEnabled" class="switch-label">Switch</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-5"><strong>Enable HelloSign</strong> in order to add HelloSign embedded signatures to the widget.</div>
					</div>
				</div>
				<div class="tab-pane" [ngClass]="{'active': configTab === 'display'}" id="dataview-display" role="tabpanel" aria-labelledby="display-tab">

					<div class="col-md-12">
						<div class="col-md-12">
							<label for="widgetPadding" class="control-label text-left">
								<span class="text-nowrap">Widget Padding</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<input id="widgetPadding" [(ngModel)]="widgetSettings.widgetPadding" class="form-control" (change)="onChange()" />
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Widget Padding</strong> setting to control the amount of padding added to the widget.</div>
					</div>

					<div class="col-md-12">
						<div class="col-md-12">
							<label for="widgetBackgroundColor" class="control-label text-left">
								<span class="text-nowrap">Background Color</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<input id="widgetBackgroundColor" [(colorPicker)]="widgetSettings.widgetBackgroundColor" id="widgetBackgroundColor" [(ngModel)]="widgetSettings.widgetBackgroundColor" [cpCancelButton]="true" [cpCancelButtonClass]="'btn btn-primary btn-xs'" class="form-control" (colorPickerChange)="onChange()" />
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Background Color</strong> setting to change the background color of the widget.</div>
					</div>

					<div class="col-md-12">
						<div class="col-md-12">
							<label for="widgetOverflow" class="control-label text-left">
								<span class="text-nowrap">Widget Overflow</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<select id="widgetOverflow" [(ngModel)]="widgetSettings.widgetOverflow" class="form-control" (change)="onChange()">
									<option value="auto">Scroll</option>
									<option value="hidden">Hidden</option>
								</select>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Widget Overflow</strong> setting to change how the widget displays data that is too large for the visible area of the widget. Scroll causes the display area to scroll as needed while Hidden keeps the scroll bars
							hidden.</div>
					</div>

					<div class="col-md-12">
						<div class="col-md-12">
							<label for="customCss" class="control-label text-left">
								<span class="text-nowrap">Custom CSS</span>
							</label>
						</div>
						<div class="col-md-7">
							<div class="control-cell">
								<textarea id="customCss" [(ngModel)]="widgetSettings.customCss" class="form-control" rows="5" (change)="onChange()"></textarea>
							</div>
						</div>
						<div class="col-md-5">Use the <strong>Custom CSS</strong> setting to modify the look and feel of the form. Use the following tags:
							<ul>
								<li>.table-row</li>
								<li>.table-cell</li>
								<li>.property-label</li>
								<li>.property-value</li>
							</ul>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #downloadDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-download"></em> Download</h5>
	</div>
	<div class="modal-body share-dialog">
		Your download has been prepared. Please click <a [href]="downloadLink" target="_blank">here</a> to download the file.
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Close</button>
	</div>
</ng-template>

<ng-template #workflowCommentDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-pencil"></em> Comment</h5>
	</div>
	<div class="modal-body share-dialog">
		<textarea class="workflow-comments-text-area" placeholder="Comment" [(ngModel)]="workflowComment"></textarea>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="cancelStateTransition()">Cancel</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="updateState()">Update</button>
	</div>
</ng-template>

<ng-template #rejectCommentDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-pencil"></em> Comment</h5>
	</div>
	<div class="modal-body share-dialog">
		<textarea class="workflow-comments-text-area" placeholder="Comment" [(ngModel)]="rejectComment"></textarea>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="reject()">{{ helloSignRejectLabel }}</button>
	</div>
</ng-template>

<ng-template #signInfoModal>
	<div class="modal-body">
		<div *ngIf="helloSignMessage" [innerHTML]="helloSignMessage | safeHtml"></div>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Close</button>
	</div>
</ng-template>

<ng-template #annotationModal>
	<div class="modal-body">
		<div class="the-preview">
			<div class="the-document">
				<div class="full-width viewer modal-viewer pccv" id="annotationviewer"></div>
			</div>
		</div>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" data-dismiss="modal" (click)="saveAnnotationsHandler(); modalRef.hide()">{{ saveAnnotationsButtonLabel }}</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>