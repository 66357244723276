import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommentObject } from 'src/app/models/comment.model';

@Component({
	selector: 'app-comments',
	templateUrl: './comments.component.html'
})
export class CommentsComponent implements OnInit {

	@Input() comment: CommentObject;
	@Input() item: number;

	@Output() newComment: EventEmitter<any> = new EventEmitter();

	constructor(
	) { }

	ngOnInit() {
	}

	onCommentSubmit(event: any) {
		const newComment = {
			id: this.item,
			parentComment: event.target.commentId.value,
			comment: event.target.comment.value
		};
		this.newComment.emit(newComment);
	}

	onNewComment(event: any) {
		this.newComment.emit(event);
	}


}
