import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Property } from '../models/property.model';
import { CommentObject } from '../models/comment.model';

@Injectable({
	providedIn: 'root'
})
export class ExternalShareService {

	constructor(
		private http: HttpClient
	) { }

	getItems(token: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);

		const requestUrl = `${environment.serviceUrl}/externalShare/items`;

		return this.http.get<any[]>(requestUrl, { headers, params });
	}

	updateSingleProperty(token: string, type: number, id: number, propertyDef: number, dataType: number, value: any) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('propertyDef', propertyDef + '');
		params = params.append('dataType', dataType + '');
		params = params.append('value', value + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/singleProperty`;

		return this.http.post(requestUrl, '', { headers, params });
	}

	updateObject(token: string, type: number, id: number, props: Property[]) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('type', type + '');
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/update`;

		return this.http.post(requestUrl, JSON.stringify(props), { headers, params });
	}

	getInfo(token: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);

		const requestUrl = `${environment.serviceUrl}/externalShare/info`;

		return this.http.get<any>(requestUrl, { headers, params });
	}

	getObjectTypes(token: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);

		const requestUrl = `${environment.serviceUrl}/externalShare/objectTypes`;

		return this.http.get<any[]>(requestUrl, { headers, params });
	}

	getVaultProperties(token: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);

		const requestUrl = `${environment.serviceUrl}/externalShare/properties`;

		return this.http.get<any[]>(requestUrl, { headers, params });
	}

	getClasses(type: number, token: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('token', token);

		return this.http.get<any[]>(`${environment.serviceUrl}/externalShare/classes`, { headers, params });
	}

	getClassProperties(classId: number, token: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('classId', classId + '');
		params = params.append('token', token);

		const requestUrl = `${environment.serviceUrl}/externalShare/classProperties`;

		return this.http.get<Property[]>(requestUrl, { headers, params });
	}

	getValueListOptions(id: number, token: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.set('id', id + '');
		params = params.append('token', token);

		const requestUrl = `${environment.serviceUrl}/externalShare/valueListOptions`;

		return this.http.get(requestUrl, { params, headers, observe: 'response' });
	}

	getRelated(token: string, type: number, id: number, version: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('version', version + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/related`;

		return this.http.get(requestUrl, { headers, params });
	}

	getObjectTypeIcon(token: string, type: number) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('type', type + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/objectTypeIcon`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, params, observe: 'response' });
	}

	download(token: string, type: number, id: number, fileIndex: number) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('fileIndex', fileIndex + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/download`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, params, observe: 'response' });
	}

	downloadDocCollection(token: string, item: any) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('token', token);

		const requestUrl = `${environment.serviceUrl}/externalShare/download-doc-collection`;

		return this.http.post(requestUrl, JSON.stringify(item), { responseType: 'blob', headers, params, observe: 'response' });
	}

	getPreviewId(token: string, id: number, version: number, fileIndex: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('id', id + '');
		params = params.append('version', version + '');
		params = params.append('fileIndex', fileIndex + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/getPreviewId`;

		return this.http.get(requestUrl, { headers: headers, params: params, responseType: 'text' });
	}

	checkinNew(token: string, properties: Property[], file: File) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('properties', JSON.stringify(properties));

		let params = new HttpParams();
		params = params.append('token', token);

		return this.http.post(`${environment.serviceUrl}/externalShare/checkin`, formData, { params });
	}

	uploadRevision(token: string, id: number, fileIndex: number, file: File) {
		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('id', id + '');
		params = params.append('fileIndex', fileIndex + '');

		const formData = new FormData();
		formData.append('file', file);

		return this.http.post(`${environment.serviceUrl}/externalShare/checkin/revision`, formData, { params });
	}

	deleteObject(token: string, type: number, id: number, destroy: boolean) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('destroy', destroy + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/delete`;

		return this.http.delete(requestUrl, { headers, params, observe: 'response' });
	}

	getComments(token: string, id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/externalShare/comments`;

		return this.http.get<CommentObject[]>(requestUrl, { headers, params });
	}

	postComment(token: string, id: number, parentComment: number, comment: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('id', id + '');
		params = params.append('comment', comment);

		if (parentComment && parentComment != null) {
			params = params.append('parentComment', parentComment + '');
		}
		const requestUrl = `${environment.serviceUrl}/externalShare/comment`;

		return this.http.post<CommentObject[]>(requestUrl, '', { headers, params });
	}

	requestCoauthor(token: string, id: number, type: number, email: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('token', token);
		params = params.append('id', id + '');
		params = params.append('type', type + '');
		params = params.append('email', email);

		const requestUrl = `${environment.serviceUrl}/externalShare/requestCoAuthor`;

		return this.http.post(requestUrl, '', { headers, params });
	}

}
