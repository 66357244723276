import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserConfigurationService } from '../services/user-configuration.service';

@Component({
	selector: 'app-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {
	modalRef: BsModalRef;

	avatarsEnabled = false;
	avatarName: string;
	multifactorAuthEnabled = false;
	userMultifactorAuthEnabled = false;
	displayName = this.authService.getFullName() ? this.authService.getFullName() : this.authService.name;

	loading = false;

	constructor(
		private configService: ConfigurationService,
		private userConfigService: UserConfigurationService,
		private toastr: ToastrService,
		public authService: AuthService
	) { }

	ngOnInit() {
		this.loading = true;

		let configLoading = true;
		let userLoading = true;
		this.configService.list(true).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'multifactorAuthEnabled') {
					this.multifactorAuthEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'avatarsEnabled') {
					this.avatarsEnabled = config.value.toLowerCase() === 'true';
				}
			}
			configLoading = false;
			if (!userLoading) {
				this.loading = false;
			}
		});
		this.userConfigService.list(true).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'multifactorAuthEnabled') {
					this.userMultifactorAuthEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'avatar') {
					this.avatarName = config.value;
				}
			}
			userLoading = false;
			if (!configLoading) {
				this.loading = false;
			}
		});
	}

	propertyChanged(field: string, e: any) {
		if (field === 'userMultifactorAuthEnabled') {
			field = 'multifactorAuthEnabled';
		}
		const property = field.replace(/([A-Z])/g, ' $1');
		const propertyWithSpaces = property.charAt(0).toUpperCase() + property.slice(1);
		if (field === 'avatar') {
			this.loading = true;
			this.avatarName = e.target.files[0].name;
			this.userConfigService.updateImage(field, e.target.files[0].name, e.target.files[0]).subscribe(response => {
				this.toastr.success('The setting `' + propertyWithSpaces + '` has been successfully updated.');
				this.userConfigService.avatarChanged.next('');
				this.loading = false;
			}, err => {
				this.toastr.error('The setting `' + propertyWithSpaces + '` has failed to be updated.');
				this.loading = false;
			});
		} else {
			this.userConfigService.update(field, e).subscribe(response => {
				this.toastr.success('The setting `' + propertyWithSpaces + '` has been successfully updated.');
			}, err => {
				this.toastr.error('The setting `' + propertyWithSpaces + '` has failed to be updated.');
			});
		}
	}

	clearAvatar() {
		this.avatarName = '';
		this.userConfigService.updateImage('avatar', '', null).subscribe(response => {
			this.toastr.success('The setting `Avatar` has been successfully cleared.');
			this.userConfigService.avatarChanged.next('');
		});
	}
}
