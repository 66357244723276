<td *ngIf="completedEnabled" class="icon-cell">
	<a class="icon-link share" href="javascript:" *ngIf="!item.loaded"><em class="fas fa-spinner fa-spin" title="Loading completed status..."><span class="sr-only">Loading completed status...</span></em></a>
	<a class="icon-link share" href="javascript:" (click)="reloadCompleted()" *ngIf="item.loaded && item.error"><em class="fas fa-check red" title="Error loading completed status. Click to re-load."><span class="sr-only">Error loading completed status. Click to re-load.</span></em></a>
	<a class="icon-link share" href="javascript:" (click)="openCompleteModal(completeDialog)" *ngIf="item.loaded && !item.error && !item.completed && item.completeCriteria === '[]'"><em class="fas fa-check" title="No Completed criteria set. Click to edit the completed criteria."><span class="sr-only">No Completed criteria set. Click to edit the completed criteria.</span></em></a>
	<a class="icon-link share" href="javascript:" (click)="openCompleteModal(completeDialog)" *ngIf="item.loaded && !item.error && !item.completed && item.completeCriteria !== '[]'"><em class="fas fa-check orange" title="Not completed. Click to view or edit the completed criteria."><span class="sr-only">Not completed. Click to view or edit the completed criteria.</span></em></a>
	<a class="icon-link share" href="javascript:" (click)="openCompleteModal(completeDialog)" *ngIf="item.loaded && !item.error && item.completed"><em class="fas fa-check green" title="Completed. Click to view or edit the completed criteria."><span class="sr-only">Completed. Click to view or edit the completed criteria.</span></em></a>
</td>
<td (click)="loadPath(item.path)">
	<em class="icon icon-file icon-folder"></em>
	<a class="icon-pad" href="javascript:">{{item.name}}</a>
</td>
<td *ngIf="alertsEnabled" (click)="openAlertConfigModal()" class="mconnect-alert-config-button">
	<em class="fas fa-bell" title="Configure Alert"></em>
</td>

<ng-template #completeDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-check"></em>Set Document Completed Criteria</h5>
	</div>
	<div class="modal-body">
		<button class="btn btn-success btn-md" (click)="addCriteria()">Add</button>
		<p>&nbsp;</p>
		<table [hidden]="completeCriteria.length === 0" class="complete-criteria-form">
			<thead>
				<tr>
					<th id="field">Field</th>
					<th id="comparison">Comparison</th>
					<th id="value">Value</th>
					<th id="remove"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let criteria of completeCriteria;let i = index">
					<td>
						<ng-select class="typeahead" [items]="vaultProperties" [clearable]="false" bindLabel="name" bindValue="id" placeholder="Select Criteria Property" [(ngModel)]="criteria.id" (change)="criteriaFieldChanged($event, i)">
						</ng-select>
					</td>
					<td>
						<select [(ngModel)]="criteria.comparison" class="form-control comparison">
							<option *ngIf="objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 10" value="eq">Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 10" value="ne">Not</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="lt">Less Than</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="gt">Greater Than</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="lte">Less Than Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="gte">Greater Than Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 2 && objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 9" value="con">Contains</option>
						</select>
					</td>
					<td>
						<input *ngIf="!objectLists[criteria.id] && objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7" [(ngModel)]="criteria.value" class="form-control">
						<input *ngIf="objectTypes[criteria.id] === 5 || objectTypes[criteria.id] === 6 || objectTypes[criteria.id] === 7" type="text" [(ngModel)]="criteria.value" bsDatepicker class="form-control">
						<ng-select *ngIf="objectLists[criteria.id]" class="typeahead" [items]="objectLists[criteria.id]" [clearable]="false" bindLabel="name" bindValue="id" placeholder="Select Criteria Value" [(ngModel)]="criteria.value">
						</ng-select>
					</td>
					<td>
						<button class="btn-xs btn btn-danger form-control" (click)="removeCriteria(i)">Remove</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="setCriteria()">Accept</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #alertConfigDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-bell"></em>Configure Alert - {{ item.name }}</h5>
	</div>
	<div class="modal-body alert-config-modal-body" [ngClass]="{'loading in': loading}">
		<div class="alert-config-form-field">
			<div class="alert-config-form-label">Send me alerts when:</div>
			<ng-container *ngFor="let trigger of alertTriggers">
				<input type="checkbox" [name]="trigger.name" [id]="trigger.name" [checked]="alertConfigExists && configHasTrigger(trigger)" class="alert-config-form-checkbox" (change)="handleAlertTriggerChange($event.target.checked, trigger)">
				<label [for]="trigger.name"><span class="no-bold">{{ trigger.name }}</span></label>
				<br>
			</ng-container>
		</div>

		<div class="alert-config-form-field">
			<div class="alert-config-form-label">Alert frequency:</div>
			<ng-container *ngFor="let freq of alertFrequencies">
				<label [for]="freq.name">
					<input type="radio" [id]="freq.name" [checked]="freq.name === 'Immediately' || (alertConfigExists && configHasFrequency(freq))" name="alertFrequencies" class="alert-config-form-radio" (change)="handleAlertFrequencyChange(freq)">
					<span class="no-bold">{{ freq.name }}</span>
				</label>
				<br>
			</ng-container>
		</div>

		<div class="alert-config-form-field">
			<ng-container *ngIf="alertConfig.alertFrequency.name === 'Weekly'"> <!-- Show only if Alert Frequency = Weekly -->
				<div class="alert-config-form-label">Only send alerts on the following day(s):</div>
				<ng-container *ngFor="let day of alertDays">
					<input type="checkbox" [name]="day.name" [id]="day.name" [checked]="alertConfigExists && configHasDay(day)" class="alert-config-form-checkbox" (change)="handleAlertDayChange($event.target.checked, day)">
					<label [for]="day.name"><span class="no-bold">{{ day.name }}</span></label>
					<br>
				</ng-container>
			</ng-container>
		</div>

		<div class="alert-config-form-field">
			<ng-container *ngIf="alertConfig.alertFrequency.name !== '' && alertConfig.alertFrequency.name !== 'Immediately'"> <!-- Show only if Alert Frequency = Weekly or Daily -->
				<div class="alert-config-form-label">Alert time:</div>
				<input type="time" class="form-control" [(ngModel)]="alertConfig.alertTime" (ngModelChange)="handleAlertTimeChange($event)">
			</ng-container>
		</div>
	</div>
	<div class="modal-footer no-bg">
		<button *ngIf="!alertConfigExists" type="button" class="btn btn-primary btn-md" [disabled]="configNotValid()" (click)="createAlertConfig()">Save</button>
		<button *ngIf="alertConfigExists" type="button" class="btn btn-primary btn-md" [disabled]="configNotValid()" (click)="updateAlertConfig()">Update</button>
		<button *ngIf="alertConfigExists" type="button" class="btn btn-danger btn-md" (click)="deleteAlertConfig()">Delete</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="closeAlertConfigModal()">Close</button>
	</div>
</ng-template>