import { Component, OnInit, Input } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UntypedFormGroup } from '@angular/forms';
import { MFilesService } from 'src/app/services/mfiles.service';
import { ExternalShareService } from 'src/app/services/external-share.service';

@Component({
	selector: 'app-uploader-field',
	templateUrl: './uploader-field.component.html'
})
export class UploaderFieldComponent implements OnInit {

	@Input() field: any;
	@Input() bsConfig: Partial<BsDatepickerConfig>;
	@Input() form: UntypedFormGroup;
	@Input() multiValues: any[][];
	@Input() external = false;
	@Input() token: string;

	optionLists = {};
	multiselectValues = [];

	constructor(
		private externalShareService: ExternalShareService,
		private mfilesService: MFilesService
	) { }

	ngOnInit() {
		if (!this.optionLists[this.field.property] && (this.field.dataType === 9 || this.field.dataType === 10)) {
			if (this.field.property === -1) {
				this.mfilesService.getObjectTypes().subscribe((result) => {
					const objectTypes: any = result.body;
					this.optionLists[-1] = objectTypes;
				});
			} else {
				if (this.external) {
					this.externalShareService.getValueListOptions(this.field.valueList, this.token).subscribe((result) => {
						this.setValueListOptions(result);
					});
				} else {
					this.mfilesService.getValueListOptions(this.field.valueList).subscribe((result) => {
						this.setValueListOptions(result);
					});
				}
			}
		}
	}

	setValueListOptions(rawOptions: any) {
		const options: any = rawOptions.body;
		this.optionLists[this.field.property] = options.items;

		if (this.field.dataType === 10 && this.multiValues[this.field.id]) {
			for (const value of this.multiValues[this.field.id]) {
				for (const option of this.optionLists[this.field.property]) {
					if (value === option.id) {
						this.multiselectChanged(option);
						break;
					}
				}
			}
		}
	}

	multiselectChanged(event: any) {
		let containsValue = false;

		if (!this.multiselectValues) {
			this.multiselectValues = [];
		} else {
			this.multiselectValues.forEach(value => {
				if (value.id === event.id) {
					containsValue = true;
				}
			});
		}

		if (!containsValue) {
			this.multiselectValues.push({ name: event.name, id: event.id });
		}
		this.updateMultiselectValue();
	}

	removeMultiselect(i: number) {
		this.multiselectValues.splice(i, 1);
		this.updateMultiselectValue();
	}

	updateMultiselectValue() {
		let fieldValue = '';
		for (const multiValue of this.multiselectValues) {
			fieldValue = fieldValue + multiValue.id + ',';
		}
		if (fieldValue.length > 0) {
			fieldValue = fieldValue.substring(0, fieldValue.length - 1);
		}
		this.form.controls[this.field.id].setValue(fieldValue, { emitEvent: true });
	}

}
