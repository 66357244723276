import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { Configuration } from '../models/configuration.model';
import { environment } from '@environments/environment';


@Injectable({
	providedIn: 'root'
})
export class UserConfigurationService {
	public avatarChanged = new Subject();

	private defaultHeaders: HttpHeaders = new HttpHeaders().set('Accept', 'application/json');
	requestUrl = `${environment.serviceUrl}/user-configurations`;
	constructor(
		protected httpClient: HttpClient
	) { }

	list(loadImages: boolean): Observable<any> {
		let params = new HttpParams();
		params = params.set('loadImages', loadImages + '');

		return this.httpClient.get<Array<Configuration>>(this.requestUrl,
			{
				headers: this.defaultHeaders,
				params: params
			}
		);
	}

	get(name: string): Observable<any> {

		return this.httpClient.get<Configuration>(this.requestUrl + `/${encodeURIComponent(String(name))}`,
			{
				headers: this.defaultHeaders
			}
		);
	}

	getList(names: string[]): Observable<any> {
		return this.httpClient.post<Configuration>(this.requestUrl + `/configList`,
			names,
			{
				headers: this.defaultHeaders
			}
		);
	}

	getImage(name: string): Observable<any> {
		return this.httpClient.get(this.requestUrl + `/${encodeURIComponent(String(name))}/image`,
			{
				headers: new HttpHeaders(),
				responseType: 'blob',
				observe: 'response'
			}
		);
	}

	update(name: string, value: string): Observable<any> {

		if (name === null || name === undefined) {
			throw new Error('Required parameter name was null or undefined when calling update.');
		}

		if (value === null || value === undefined) {
			throw new Error('Required parameter value was null or undefined when calling update.');
		}

		let queryParameters = new HttpParams();
		if (value !== undefined && value !== null) {
			queryParameters = queryParameters.set('value', <any>value);
		}

		return this.httpClient.put<Configuration>(`${environment.serviceUrl}/user-configurations/${encodeURIComponent(String(name))}`,
			null,
			{
				params: queryParameters,
				headers: this.defaultHeaders
			}
		);
	}

	updateImage(name: string, value: string, file: File): Observable<any> {

		if (name === null || name === undefined) {
			throw new Error('Required parameter name was null or undefined when calling update.');
		}

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('value', <any>value);

		const formData = new FormData();
		formData.append('image', file);

		return this.httpClient.put<Configuration>(`${environment.serviceUrl}/user-configurations/${encodeURIComponent(String(name))}/image`,
			formData,
			{
				params: queryParameters,
				headers: this.defaultHeaders
			}
		);
	}

	create(configuration: Configuration): Observable<any> {

		if (configuration === null || configuration === undefined) {
			throw new Error('Required parameter configuration was null or undefined when calling create.');
		}

		return this.httpClient.post<Configuration>(this.requestUrl,
			configuration,
			{
				headers: this.defaultHeaders
			}
		);
	}
}
