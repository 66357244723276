export class Lookup {
	public item: number;
	public displayValue: string;
	public deleted: boolean;

	constructor(item: number) {
		this.item = item;
		this.displayValue = "";
		this.deleted = false;
	}
}