<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<h1>Shares Component&nbsp;&nbsp;&nbsp;<a href="javascript:" (click)="loadShares()"><em class="fa fa-redo-alt"></em></a></h1>
		</header>
	</div>
</div>
<div class="main-content-area" [ngClass]="{'loading in': loading }" *ngIf="!sharesLoaded">&nbsp;</div>
<div class="main-content-area" [ngClass]="{'loading in': loading }" *ngIf="sharesLoaded">
	<div class="main-content-body">
		<div class="button-container">
			<button class="btn btn-md btn-success" (click)="openCreateShare()">Create File Share</button>
		</div>
		<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted">
			<thead>
				<tr>
					<th id="name">Name</th>
					<th id="revisions"></th>
					<th id="share"></th>
					<th id="download"></th>
					<th id="delete"></th>
					<th id="coauthor"></th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let share of shares; let i = index">
					<tr class="file-share">
						<td><a href="javascript:" (click)="shareClicked(share, i)"><em class="icon" [ngClass]="activeShare == i ? 'fas fa-minus-circle' : 'fas fa-plus-circle'"></em></a> <a href="javascript:" (click)="shareClicked(share, i)">{{ share.name }}</a> <span class="expire-icon-container"><em *ngIf="share.expired" class="icon fa fa-clock" title="Share is Expired"></em></span></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="icon-cell"><a href="javascript:" (click)="editFileShares(share)" title="Edit Share"><em class="fa fa-edit"></em></a></td>
						<td class="icon-cell"><a href="javascript:" (click)="openDeleteShare(share)" title="Delete Share"><em class="fas fa-trash"></em></a></td>
					</tr>
					<ng-container *ngIf="activeShare == i">
						<tr *ngFor="let doc of share.docs; let j = index">
							<td><em class="icon icon-file icon-other preview-icon left-margin" [ngClass]="doc?.extension !== undefined ? 'icon-' + doc?.extension : ''"></em> <a href="javascript:" (click)="fileClicked(doc, i, j)">{{ doc.name }}.{{ doc.extension }}</a></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr *ngFor="let link of share.links">
							<td><em class="icon icon-file fa fa-link left-margin"></em> <a href="javascript:" (click)="openShareLink(link)">{{ link.name }}</a></td>
							<td></td>
							<td></td>
							<td class="icon-cell"><a href="javascript:" (click)="copyShareLink(link)" title="Copy Share URL to clipboard"><em class="fa fa-share"></em></a></td>
							<td></td>
							<td></td>
						</tr>
						<tr *ngIf="!share.hasDocs && !loading">
							<td>No shared documents</td>
						</tr>
						<tr *ngIf="!share.hasLinks && !loading">
							<td>No secure links</td>
						</tr>
					</ng-container>
				</ng-container>

			</tbody>
		</table>

		<!--
			<div *ngFor="let share of shares">
				<li *ngFor="let doc of share.docs">{{ doc.name }}.{{ doc.extension }}</li>
				<li *ngFor="let link of share.links">{{ link.name }}</li>
			</div>
-->

	</div>
</div>


<ng-template #fileShareCreateModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-share"></em> File Share -
			<span *ngIf="fileShareWizardStep == 1">(1/3) - Share Details</span>
			<span *ngIf="fileShareWizardStep == 2">(2/3) - Manage Documents</span>
			<span *ngIf="fileShareWizardStep == 3">(3/3) - Manage Links</span>
		</h5>
	</div>
	<div class="modal-body edit-share" [ngClass]="{'loading in': loading }">

		<ng-container *ngIf="showShareEmptyConfirmMessage">
			<div *ngIf="shareDocs.length == 0 && shareLinks.length > 0" class="confirm-message">
				There are no documents associated with this share. <br /> Continue?
			</div>
			<div *ngIf="shareDocs.length > 0 && shareLinks.length == 0" class="confirm-message">
				There are no links associated with this share. <br /> Continue?
			</div>
			<div *ngIf="shareDocs.length == 0 && shareLinks.length == 0" class="confirm-message">
				There are no documents or links associated with this share. <br /> Continue?
			</div>
		</ng-container>

		<ng-container *ngIf="fileShareWizardStep == 1 && !showShareEmptyConfirmMessage">
			<div *ngFor="let field of fields" class="field-container">
				<ng-container *ngIf="field.property !== -1 && field.property !== 100">
					<label [for]="field.property" class="form-label"><span *ngIf="field.required">*</span>{{ field.name }}</label>

					<div *ngIf="field.dataType === 1">
						<input type="text" [id]="field.property" [(ngModel)]="formData[field.property]" class="form-control" (change)="inputChanged()">
					</div>
					<div *ngIf="field.dataType === 2 || field.dataType === 3">
						<input type="number" [id]="field.property" [(ngModel)]="formData[field.property]" class="form-control" (change)="inputChanged()">
					</div>
					<div *ngIf="field.dataType === 5 || field.dataType === 6 || field.dataType === 7">
						<input type="text" [id]="field.property" [(ngModel)]="formData[field.property]" bsDatepicker class="form-control" (change)="inputChanged()">
					</div>
					<div *ngIf="field.dataType === 8">
						<div class="switch-row">
							<div class="switch">
								<input [id]="'completed' + i" type="checkbox" [(ngModel)]="formData[field.property]" class="switch-input" (change)="inputChanged()">
								<label [for]="'completed' + i" class="switch-label">Switch</label>
							</div>
						</div>
					</div>
					<div *ngIf="field.dataType === 9">
						<ng-select class="typeahead form-typeahead" [items]="optionLists[field.property].items ? optionLists[field.property].items : []" [clearable]="false" bindLabel="name" bindValue="id" [id]="field.property" [(ngModel)]="formData[field.property]" (change)="selectFieldValueChanged(field)">
						</ng-select>
					</div>
					<div *ngIf="field.dataType === 10">
						<ng-select class="typeahead form-typeahead" [items]="optionLists[field.property].items ? optionLists[field.property].items : []" [clearable]="false" bindLabel="name" bindValue="id" [id]="field.property" [(ngModel)]="tempFormData[field.property]" (change)="multiselectChanged(field, $event); selectFieldValueChanged(field)">
						</ng-select>
						<div class="multiselect-value-row">
							<span *ngFor="let value of formData[field.property]; let i = index">
								<span class="multiselect-value">{{value.name}} <em class="fas fa-times" (click)="removeMultiselect(field, i)"></em></span>
							</span>
						</div>
					</div>
					<div *ngIf="field.dataType === 13">
						<textarea [id]="field.property" [(ngModel)]="formData[field.property]" class="form-control" (change)="inputChanged()"></textarea>
					</div>
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="fileShareWizardStep == 2 && !showShareEmptyConfirmMessage">
			<label class="form-label mt-15">
				Find a Document
			</label>
			<ng-select class="typeahead form-typeahead" [items]="searchDocs" [clearable]="true" bindLabel="title" bindValue="objVer.id" id="shareDoc" [(ngModel)]="shareDoc" (change)="shareDocChanged($event)" (search)="shareDocSearch($event)">
			</ng-select>
			<div class="multiselect-value-row">
				<div *ngFor="let shareDoc of shareDocs; let i = index">
					{{ shareDoc.title }} <em class="fas fa-times" (click)="removeShareDoc(i)"></em>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="fileShareWizardStep == 3 && !showShareEmptyConfirmMessage">
			<label class="form-label mt-15">
				Manage Secure Links
			</label>
			<span class="right" (click)="addLink()">Create Link</span>
			<div class="table-container">
				<table class="full-width table table-striped table-fixed share-links-table">
					<tr>
						<th class="input-cell"></th>
						<th class="icon-cell">
							<em class="fa fa-file-alt"></em>
							<div class="text">View</div>
						</th>
						<th class="icon-cell">
							<em class="fa fa-file-download"></em>
							<div class="text">Download</div>
						</th>
						<th class="icon-cell">
							<em class="fa fa-file-upload"></em>
							<div class="text">Update Doc</div>
						</th>
						<th class="icon-cell">
							<em class="fa fa-trash"></em>
							<div class="text">Delete</div>
						</th>
						<th class="icon-cell">
							<em class="fa fa-edit"></em>
							<div class="text">Update Metadata</div>
						</th>
						<th class="icon-cell">
							<em class="fa fa-upload"></em>
							<div class="text">Create</div>
						</th>
						<th class="icon-cell">
							<em class="fa fa-comment"></em>
							<div class="text">Comment</div>
						</th>
						<th class="icon-cell">
							<em class="fa fa-user-edit"></em>
							<div class="text">Coauthor</div>
						</th>
						<th class="action-cell"></th>
						<th class="action-cell"></th>
					</tr>
					<tr *ngFor="let link of shareLinks; let i = index">
						<td>
							<input type="text" [(ngModel)]="link.name" class="form-control">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.view">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.download">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.updateDocument">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.delete">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.updateMetadata">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.create">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.comment">
						</td>
						<td class="icon-cell">
							<input type="checkbox" [(ngModel)]="link.coauthor">
						</td>
						<td class="action-cell"><em class="fa fa-link"></em></td>
						<td class="action-cell"><em class="fa fa-times-circle" (click)="removeLink(i)"></em></td>
					</tr>
				</table>
			</div>
		</ng-container>

	</div>
	<div class="modal-footer message-footer">

		<ng-container *ngIf="showShareEmptyConfirmMessage">
			<div class="left">
				&nbsp;
			</div>
			<div class="right">
				<button type="button" class="btn btn-md" (click)="cancelCreateShare()" [disabled]="loading">Cancel</button>
				<button type="button" class="btn btn-md" (click)="!updateShare ? createShare(true) : updateFileShare(true)" [disabled]="loading">Confirm</button>
			</div>
		</ng-container>

		<ng-container *ngIf="fileShareWizardStep == 1 && !showShareEmptyConfirmMessage">
			<div class="left">
				<button type="button" class="btn btn-md" [disabled]="!validForm() || loading" (click)="!updateShare ? createShareAndMoveStep() : fileShareWizardStep = 2">Next: Select Documents</button>
			</div>
			<div class="right">
				<button type="button" class="btn btn-md" [disabled]="loading" (click)="modalRef.hide(); resetCreateShare();">Cancel</button>
				<button type="button" class="btn btn-md" [disabled]="!validForm() || loading" (click)="!updateShare ? createShare(false) : updateFileShare()">Finish</button>
			</div>
			<!--
			<button type="button" class="btn btn-md" *ngIf="" [disabled]="!validForm() || (updateShare && !hasChanged)" (click)="fileShareWizardStep = 2">Next: Select Documents</button>
			-->
		</ng-container>

		<ng-container *ngIf="fileShareWizardStep == 2 && !showShareEmptyConfirmMessage">
			<div class="left">
				<button type="button" class="btn btn-md" [disabled]="loading" (click)="fileShareWizardStep = 1">Back: Share Details</button>
				<button type="button" class="btn btn-md" [disabled]="loading" (click)="fileShareWizardStep = 3">Next: Manage Share Links</button>
			</div>
			<div class="right">
				<button type="button" class="btn btn-md" [disabled]="loading" (click)="modalRef.hide(); resetCreateShare();">Cancel</button>
				<button type="button" class="btn btn-md" [disabled]="!validForm() || loading" (click)="updateFileShare(false)">Finish</button>
			</div>
		</ng-container>

		<ng-container *ngIf="fileShareWizardStep == 3 && !showShareEmptyConfirmMessage">
			<div class="left">
				<button type="button" class="btn btn-md" [disabled]="loading" (click)="fileShareWizardStep = 2">Back: Select Documents</button>
			</div>
			<div class="right">
				<button type="button" class="btn btn-md" [disabled]="loading" (click)="modalRef.hide(); resetCreateShare();">Cancel</button>
				<button type="button" class="btn btn-md" [disabled]="!validForm() || loading" (click)="updateFileShare(false)">Finish</button>
			</div>
		</ng-container>

	</div>
</ng-template>

<ng-template #fileShareDeleteModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-share"></em>Delete File Share</h5>
	</div>
	<div class="modal-body">
		Are you sure you want to delete File Share {{ deleteTarget.name }} and all of its links?
	</div>
	<div class="modal-footer message-footer">
		<button type="button" class="btn btn-md" (click)="modalRef.hide()">Cancel</button>
		<button type="button" class="btn btn-md" (click)="deleteFileShare()">Delete</button>
	</div>
</ng-template>

<ng-template #viewerModal>
	<div class="modal-body">
		<div class="viewer-container">
			<app-widget-viewer [widgetSettings]="viewerModalSettings"></app-widget-viewer>
		</div>
	</div>
	<div class="modal-footer message-footer">
		<div class="col-md-6 left">

			<button class="btn btn-light btn-md" (click)="prevFileClicked()" *ngIf="shares[selectedShareIndex].docs.length > 0" [disabled]="selectedDocumentIndex == 0">Prev</button>
			<button class="btn btn-light btn-md" (click)="nextFileClicked()" *ngIf="shares[selectedShareIndex].docs.length > 0" [disabled]="selectedDocumentIndex == (shares[selectedShareIndex].docs.length-1)">Next</button>

		</div>
		<div class="col-md-6">
			<button class="btn btn-light btn-md" (click)="closeViewerModal()">Close</button>
		</div>
	</div>
</ng-template>