import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable, Subscription } from 'rxjs';
import { Property } from 'src/app/models/property.model';
import { ExternalShareService } from 'src/app/services/external-share.service';
import { MFilesService } from 'src/app/services/mfiles.service';
import { BytesPipe } from '../../shared/pipes/bytes.pipe';
import { Uploader } from './uploader';
import { UploadQueue } from './uploadqueue';

@Component({
	selector: 'cwx-uploader',
	templateUrl: './uploader.component.html',
	styleUrls: ['./uploader.component.css'],
	providers: [BytesPipe]
})
export class UploaderComponent implements OnInit, OnDestroy {

	selectedItem: UploadQueue;

	// getter : get overall progress
	get progress(): number {
		let psum = 0;

		for (const entry of this.uploader.queue) {
			psum += entry.progress;
		}

		if (psum === 0) {
			return 0;
		}

		return Math.round(psum / this.uploader.queue.length);
	}

	set progress(e) {
		let psum = 0;

		for (const entry of this.uploader.queue) {
			psum += entry.progress;
		}

		this.progress = Math.round(psum / this.uploader.queue.length);
	}

	@Input() defaultClass: number;
	@Input() autopopulateName = false;
	@Input() useClassNameProp = false;
	@Input() nameProp: number;
	@Input() allowUsersToAddProps = true;
	@Input() multiple = true;
	@Input() max_size = 100000000;
	@Input() isForm = false;
	@Input() isRevision = false;
	@Input() filelessObjectType: number;
	@Input() filelessClass: number;
	@Input() inheritSelected = false;
	@Input() associatedObjectType: number;
	@Input() associatedObject: number;
	@Input() associatedProp: number;
	@Input() autoSubmit = false;
	@Input() external = false;
	@Input() token: string;
	@Input() fileOnly = false;
	@Input() existingFiles: any[] = [];	// Files that have already been uploaded to the form widget using the file upload field need to be prepopulated as if they are existing items in the uploader. This field will take in the existing files.
	@Input() keepFileUploadBrowse = false;
	@Input() isFolderUpload = false;

	clearQueueSubscription: Subscription;
	@Input() clearQueue: Observable<void>;

	showObjectTypes = false;
	objectTypes: any[] = [];
	objectType = 0;

	forms: UntypedFormGroup[] = [];
	activeForm = 0;
	formSubs: Subscription[] = [];
	loading = false;
	classes: any[] = [];
	formProperties: Property[][] = [];
	formClasses: any[] = [];

	allProperties = [];
	additionalProp = 0;
	defaultClassName = '';
	classNameProp = 0;

	nonDocumentMode = false;

	associatedProperties = [];
	inheritedProperties = [];
	multiValues = [];

	requiredMissing = false;

	@Output() queue: EventEmitter<any> = new EventEmitter();
	@Output() formObj: EventEmitter<UntypedFormGroup[]> = new EventEmitter();
	@Output() formProps: EventEmitter<Property[][]> = new EventEmitter();
	@Output() select: EventEmitter<any> = new EventEmitter();
	@Output() removed: EventEmitter<any> = new EventEmitter();
	@Output() objectTypeId: EventEmitter<number> = new EventEmitter();
	@Output() addedFiles: EventEmitter<any> = new EventEmitter();
	@Output() removedFile: EventEmitter<any> = new EventEmitter();

	public uploader: Uploader = new Uploader();
	bsConfig: Partial<BsDatepickerConfig>;

	warning_message = '';

	constructor(
		private fb: UntypedFormBuilder,
		private bytesPipe: BytesPipe,
		private externalShareService: ExternalShareService,
		private mfilesService: MFilesService
	) {
	}

	ngOnInit() {
		if (this.existingFiles.length > 0) {
			for (const file of this.existingFiles) {
				this.uploader.queue.push(new UploadQueue(file.content));
			}
		}

		if (this.clearQueue) {
			this.clearQueueSubscription = this.clearQueue.subscribe(() => {
				this.uploader.queue = [];
			});
		}

		if (!this.fileOnly) {
			if (this.filelessObjectType && this.filelessClass) {
				this.enterNonDocumentMode(null);
				this.objectType = this.filelessObjectType;
				this.defaultClass = this.filelessClass;

				if (this.inheritSelected && this.associatedObjectType && this.associatedObject) {
					this.loading = true;
					this.mfilesService.getProperties(this.associatedObjectType, this.associatedObject, -1).subscribe((result) => {
						this.associatedProperties = result.body.properties;
						this.classChanged(this.defaultClass);
					});
				} else {
					this.classChanged(this.defaultClass);
				}
			} else {
				if (this.inheritSelected && this.associatedObjectType && this.associatedObject) {
					this.loading = true;
					this.mfilesService.getProperties(this.associatedObjectType, this.associatedObject, -1).subscribe((result) => {
						this.associatedProperties = result.body.properties;
						this.loading = false;
					});
				}
			}

			if (this.external) {
				this.externalShareService.getObjectTypes(this.token).subscribe((result) => {
					for (const objType of result) {
						if (objType.id === 0) {
							this.objectTypes.push(objType);
						}
					}
				});
			} else {
				this.mfilesService.getObjectTypes().subscribe((result) => {
					for (const objType of result.body) {
						if (objType.addingAllowedForUser) {
							this.objectTypes.push(objType);
						}
					}
				});
			}

			if (this.isForm) {
				this.bsConfig = Object.assign({}, {});

				if (this.external) {
					this.externalShareService.getClasses(this.objectType, this.token).subscribe((result) => {
						this.classes = result;

						if (this.defaultClass || this.defaultClass === 0) {
							for (const c of this.classes) {
								if (c.id === this.defaultClass) {
									this.defaultClassName = c.name;
									this.classNameProp = c.namePropertyDef;
								}
							}
						}
					});
				} else {
					this.mfilesService.getClasses(this.objectType).subscribe((result) => {
						this.classes = result;

						if (this.defaultClass || this.defaultClass === 0) {
							for (const c of this.classes) {
								if (c.id === this.defaultClass) {
									this.defaultClassName = c.name;
									this.classNameProp = c.namePropertyDef;
								}
							}
						}
					});
				}
			}


			if (this.external) {
				this.externalShareService.getVaultProperties(this.token).subscribe((result) => {
					this.allProperties = result;
				});
			} else {
				this.mfilesService.getVaultProperties().subscribe((result) => {
					const propertyList: any = result.body;
					this.allProperties = propertyList;
				});
			}
		}
	}

	ngOnDestroy() {
		if (this.clearQueueSubscription) {
			this.clearQueueSubscription.unsubscribe();
		}

		for (const formSub of this.formSubs) {
			if (formSub) {
				formSub.unsubscribe();
			}
		}
	}

	onFilesChange(fileList: File[]) {
		this.warning_message = '';

		const addedFiles: UploadQueue[] = [];

		for (const file of fileList) {
			const queueFile = new UploadQueue(file);
			this.uploader.queue.push(queueFile);
			addedFiles.push(queueFile);

			this.formProperties.push([]);
			this.formSubs.push();

			if (!this.multiple) {
				break;
			}
		}

		this.selectedItem = this.uploader.queue[0];

		if (this.uploader.queue.length > 0) {
			this.queue.emit(this.uploader.queue);
			this.addedFiles.emit(addedFiles);

			if (this.defaultClass || this.defaultClass === 0) {
				this.classChanged(this.defaultClass);
			}
		}
	}

	onFileInvalids(fileList: File[] = []) {
		if (fileList.length > 0) {
			this.warning_message = `You selected at least one file that exceeded the maximum ${this.bytesPipe.transform(+this.max_size)} allowed.`;
		}
	}

	onSelectChange(event: any) {
		this.warning_message = '';

		const files: FileList = event.target.files as FileList;
		const invalid_files: File[] = [];
		const addedFiles: UploadQueue[] = [];

		for (let i = 0; i < files.length; i++) {

			const file = new UploadQueue(files[i]);

			/** Temporarily disable file size validation until configs can be created.
			if (file
				&&
				(
				(file.file.size <= this.max_size)
				||
				(+this.max_size === 0)
				)
			) {*/
			this.uploader.queue.push(file);
			addedFiles.push(file);
			if (this.selectedItem === undefined) {
				this.selectedItem = file;
			}/**
      } else {
        invalid_files.push(file.file);
      }*/

			this.formProperties.push([]);
			this.formSubs.push();
		}

		/** Temporarily disable file size validation until configs can be created.
		if (invalid_files.length) {
			this.onFileInvalids(invalid_files);
		} */

		this.queue.emit(this.uploader.queue);
		this.addedFiles.emit(addedFiles);
		if (this.defaultClass || this.defaultClass === 0) {
			this.classChanged(this.defaultClass);
		}
	}

	download(item: UploadQueue) {
		saveAs(item.file, item.file.name);
	}

	remove(item: UploadQueue) {

		this.warning_message = '';

		let i = 0;

		for (i = 0; i < this.uploader.queue.length; i++) {
			if (this.uploader.queue[i] === item) {
				this.uploader.queue.splice(i, 1);
				this.forms.splice(i, 1);
				this.formProperties.splice(i, 1);
				if (this.formSubs[i]) {
					this.formSubs[i].unsubscribe();
				}
				this.formSubs.splice(i, 1);
				if (this.activeForm === i) {
					this.activeForm = 0;
				}
				this.removed.emit(i);
				break;
			}
		}

		if (item === this.selectedItem) {
			if (i === this.uploader.queue.length) {
				i = i - 1;
			}
			this.selectedItem = this.uploader.queue[i];
		}

		this.queue.emit(this.uploader.queue);
		this.removedFile.emit(item);
	}

	removeAll() {

		this.warning_message = '';

		this.uploader.queue = [];
	}

	selected(item: UploadQueue, i: number) {
		this.activeForm = i;
		this.selectedItem = item;
		this.select.emit(this.selectedItem);
	}

	addProperty() {
		for (const prop of this.allProperties) {
			if (prop.id === this.additionalProp) {
				this.formProperties[this.activeForm].push(JSON.parse(JSON.stringify(prop)));
				this.forms[this.activeForm].addControl(prop.id, new UntypedFormControl(null, []));
				break;
			}
		}
		this.additionalProp = 0;
	}

	confirmObjectType() {
		if (this.nonDocumentMode) {
			this.formProperties[0] = [];
		}
	}

	classChanged(classId: number) {
		this.loading = true;

		if (this.external) {
			this.externalShareService.getClassProperties(classId, this.token).subscribe((result) => {
				this.setClassProperties(result);
			});
		} else {
			this.mfilesService.getClassProperties(classId).subscribe((result) => {
				this.setClassProperties(result);
			});
		}
	}

	setClassProperties(rawProperties: any) {
		const properties: Property[] = JSON.parse(JSON.stringify(rawProperties));

		if (this.defaultClass || this.defaultClass === 0) {
			this.initializeFormsWhenDefaultClass(properties);
		} else {
			this.initializeFormsWhenDifferentClasses(properties);
		}

		for (let i = 0; i < this.forms.length; i++) {
			for (const propId of Object.keys(this.forms[i].getRawValue())) {
				for (const prop of this.formProperties[i]) {
					if (prop.id === parseInt(propId, 10)) {
						prop.value = this.forms[i].getRawValue()[propId];
						break;
					}
				}
			}
		}
		this.formProps.emit(this.formProperties);
		this.loading = false;
	}

	// This method basically does the same thing as the else block in classChanged.
	// The difference is that it needs to have two separate copies of the properties arrays
	// even though the classes for all files will be the same (because default class is set)
	initializeFormsWhenDefaultClass(properties: Property[]) {
		for (let i = 0; i < this.formProperties.length; i++) {
			const propertiesCopy = properties.map(x => Object.assign({}, x));

			this.formProperties[i] = propertiesCopy;
			if (this.autopopulateName) {
				let classNameProp = this.nameProp;
				if (this.useClassNameProp) {
					classNameProp = this.classNameProp;
				}

				for (let j = 0; j < this.formProperties[i].length; j++) {
					const prop = this.formProperties[i][j];
					if (prop.id === classNameProp) {
						const filename = this.uploader.queue[i].file.name;
						const extension = filename.lastIndexOf('.');
						this.formProperties[i][j].value = filename.substring(0, extension);
						break;
					}
				}
			}
			this.formProps.emit(this.formProperties);

			const formGroup = this.initializeFormGroup(propertiesCopy);

			const form = this.fb.group(formGroup);
			form.updateValueAndValidity();
			this.forms[i] = form;
			this.formObj.emit(this.forms);

			if (this.formSubs[i]) {
				this.formSubs[i].unsubscribe();
			}
			this.formSubs[i] = form.valueChanges.subscribe((formResult) => {
				for (const propId of Object.keys(formResult)) {
					for (const prop of this.formProperties[i]) {
						if (prop.id === parseInt(propId, 10)) {
							prop.value = formResult[propId];
							break;
						}
					}
				}
				this.formProps.emit(this.formProperties);
				this.formObj.emit(this.forms);
			});
		}
	}

	initializeFormsWhenDifferentClasses(properties: Property[]) {
		this.formProperties[this.activeForm] = properties;
		this.formProps.emit(this.formProperties);

		const formGroup = this.initializeFormGroup(properties);

		const form = this.fb.group(formGroup);
		form.updateValueAndValidity();
		this.forms[this.activeForm] = form;
		this.formObj.emit(this.forms);

		if (this.formSubs[this.activeForm]) {
			this.formSubs[this.activeForm].unsubscribe();
		}
		this.formSubs[this.activeForm] = form.valueChanges.subscribe((formResult) => {
			for (const propId of Object.keys(formResult)) {
				for (const prop of this.formProperties[this.activeForm]) {
					if (prop.id === parseInt(propId, 10)) {
						prop.value = formResult[propId];
						break;
					}
				}
			}
			this.formProps.emit(this.formProperties);
			this.formObj.emit(this.forms);
		});
	}

	initializeFormGroup(properties: Property[]) {
		const formGroup = {};
		for (const prop of properties) {
			if (prop.id !== 100 && prop.id !== this.nameProp && !(this.useClassNameProp && prop.id === this.classNameProp)) {
				let defaultValue = null;
				if (this.inheritSelected) {
					for (const associatedProp of this.associatedProperties) {
						if (associatedProp.propertyDef === prop.id) {
							if (prop.dataType === 9 && associatedProp.value.hasValue) {
								defaultValue = associatedProp.value.lookup.item;
							} else if (prop.dataType === 10 && associatedProp.value.hasValue) {
								this.multiValues[prop.id] = [];
								for (const propValue of associatedProp.value.lookups) {
									this.multiValues[prop.id].push(propValue.item);
								}

								if (this.autoSubmit) {
									defaultValue = "";
									for (const prop of Object.keys(this.multiValues)) {
										defaultValue = defaultValue + this.multiValues[prop] + ",";
									}
									defaultValue = defaultValue.slice(0, defaultValue.length - 1);
								} else {
									defaultValue = '-1'; // this gets overridden by uploader-field, just used to set requiredMissing
								}
							} else if (prop.dataType === 5 && associatedProp.value.hasValue) {
								defaultValue = new Date(associatedProp.value.value);
							} else {
								defaultValue = associatedProp.value.value;
							}
							break;
						}
					}
					if (defaultValue) {
						this.inheritedProperties.push(prop.id);
					}
				}

				if (this.associatedObject && prop.id === this.associatedProp) {
					defaultValue = this.associatedObject;
				}

				if (prop.required) {
					if (defaultValue === null) {
						this.requiredMissing = true;
					}
					formGroup[prop.id] = new UntypedFormControl(defaultValue, [Validators.required]);
				} else {
					formGroup[prop.id] = new UntypedFormControl(defaultValue, []);
				}
			}
		}

		return formGroup;
	}

	enterNonDocumentMode(event: Event) {
		if (event) {
			event.preventDefault();
		}

		this.nonDocumentMode = true;
		this.formProperties[0] = [];
	}

	showPropertyField(prop: any) {
		let show = prop.id !== 100; // don't show Class
		show = show && prop.id !== this.nameProp; // don't show the configured name property
		show = show && !(this.useClassNameProp && prop.id === this.classNameProp); // don't show the automatic name property
		show = show && !(this.associatedObject && prop.id === this.associatedProp); // don't show the associated object property if it's set
		show = show && !(this.inheritSelected && this.inheritedProperties.indexOf(prop.id) > -1 && prop.userPermissions !== 'Update'); // don't show properties that are inherited and the user doesn't have Update access to

		return show;
	}
}
