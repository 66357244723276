<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<h1 class="pull-left">User Settings</h1>
		</header>
	</div>
</div>
<div class="main-content-area global-settings" [ngClass]="{'loading in': loading }">
	<div class="col-md-12">
		<div class="widget-holder">
			<div class="panel panel-default">
				<div class="col-md-2">
					<div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
						<a class="nav-link admin active" id="v-pills-general-tab" data-toggle="pill" href="#v-pills-general" role="tab" aria-controls="v-pills-general" aria-selected="true"><em class="fas fa-cog" title="General"></em> <span class="very-title">General</span></a>
					</div>
				</div>
				<div class="col-md-10">
					<div class="tab-content" id="v-pills-tabContent">

						<!-- General -->
						<div class="tab-pane active" id="v-pills-general" role="tabpanel" aria-labelledby="v-pills-general-tab">
							<!--
                            <div class="col-md-12 pill-row">
                                <div class="col-md-12">
                                    <p class="header double-space">Use the M-Connect <strong>General</strong> settings to control the look and feel of both the portal site and e-mail communications sent to the users. <br /> These settings should be updated prior to giving external users access to the site.</p>
                                </div>
                            </div>
                            -->
							<div *ngIf="!multifactorAuthEnabled && !avatarsEnabled" class="no-settings-message">
								There are no configurable user settings.
							</div>
							<div class="col-md-12 pill-row" *ngIf="multifactorAuthEnabled && authService.isExternalUser()">
								<div class="col-md-4">
									<label for="displayName" class="control-label heavy">Display Name</label>
									<p><label class="control-label light double-space">Your Display Name to be used around M-Connect</label></p>
								</div>
								<div class="col-md-1"></div>
								<div class="col-md-7">
									<input id="displayName" disabled type="text" class="form-control" (change)="propertyChanged('displayName', $event.target.value)" [(ngModel)]="displayName" />
								</div>
							</div>
							<div class="col-md-12 pill-row" *ngIf="multifactorAuthEnabled && authService.isExternalUser()">
								<div class="col-md-4">
									<label for="userMultifactorAuthEnabled" class="control-label heavy">Enable Multi-factor Authentication</label>
									<p><label class="control-label light double-space">When enabled, an authentication code will be emailed to the user during each login to confirm the user identity.</label></p>
								</div>
								<div class="col-md-1"></div>
								<div class="col-md-7">
									<div class="switch-row">
										<div class="switch">
											<input id="userMultifactorAuthEnabled" type="checkbox" class="switch-input" [(ngModel)]="userMultifactorAuthEnabled" (ngModelChange)="propertyChanged('userMultifactorAuthEnabled', $event)" />
											<label for="userMultifactorAuthEnabled" class="switch-label">Switch</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12 pill-row last" *ngIf="avatarsEnabled">
								<div class="col-md-4">
									<label for="avatar" class="control-label heavy">Upload Custom Avatar</label>
									<p><label class="control-label light double-space">Overwrites the user icon in the top right corner.</label></p>
								</div>
								<div class="col-md-1"></div>
								<div class="col-md-7">
									<label for="avatar" class="control-label heavy">Avatar Image</label>
									<div>
										<input id="avatar" type="file" (change)="propertyChanged('avatar', $event)" />
										<label for="avatar" class="btn">Choose a file...</label>
									</div>
									<div *ngIf="avatarName">
										Current Avatar: {{avatarName}} <button type="button" class="btn btn-danger clear" (click)="clearAvatar()">Clear Avatar</button>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>