import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { UploaderComponent } from './uploader/uploader.component';
import { DndDirective } from './uploader/uploader.directive';
import { PipesModule } from '../shared/pipes/pipes.module';
import { UploaderFieldComponent } from './uploader/uploader-field/uploader-field.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		CollapseModule,
		PipesModule,
		BsDatepickerModule.forRoot(),
		ReactiveFormsModule,
		NgSelectModule
	],
	declarations: [
		UploaderComponent,
		DndDirective,
		UploaderFieldComponent
	],
	exports: [
		UploaderComponent,
		DndDirective,
		BsDatepickerModule,
		FormsModule,
		ReactiveFormsModule
	]
})
export class UploadModule { }
