import {
	Directive, HostListener, HostBinding,
	EventEmitter, Output, Input
} from '@angular/core';

@Directive({
	selector: '[cwxDnd]'
})
export class DndDirective {

	@Input() allowed_extensions: Array<string> = [];
	@Input() max_size = 100000000;

	@Output() private filesChange: EventEmitter<File[]> = new EventEmitter();
	@Output() private filesInvalidChange: EventEmitter<File[]> = new EventEmitter();

	@HostBinding('style.background') private background = '#fff';

	constructor() { }

	@HostListener('dragover', ['$event']) public onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#eee';
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#fff';
	}

	@HostListener('drop', ['$event']) public onDrop(e: any) {
		e.preventDefault();
		e.stopPropagation();

		this.background = '#fff';

		const files = e.dataTransfer.files;
		const valid_files: File[] = [];
		const invalid_files: File[] = [];

		if (files.length > 0) {

			for (const file of files) {
				// const ext = file.name.split('.')[file.name.split('.').length - 1];
				// && this.allowed_extensions.lastIndexOf(ext) !== -1
				if ((file.size <= this.max_size) || (+this.max_size === 0)) {
					valid_files.push(file);
				} else {
					invalid_files.push(file);
				}
			}

			this.filesChange.emit(valid_files);

			if (invalid_files.length) {
				this.filesInvalidChange.emit(invalid_files);
			}


		}

	}

}
