import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MFilesService } from '../services/mfiles.service';

@Component({
	selector: 'messages',
	templateUrl: './messages.component.html',
	styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

	title = 'Recent System Messages';
	loading = true;

	entries = [];
	errorLogs = [];

	constructor(
		private titleService: Title,
		private mfilesService: MFilesService
	) { }

	ngOnInit() {
		this.titleService.setTitle('Recent System Messages');
		this.entries = JSON.parse(sessionStorage.getItem('ngx_toastr'));

		this.mfilesService.getErrorLogs().subscribe((result) => {
			const errors: any = result.body;
			this.errorLogs = errors;
			console.log(this.errorLogs);
			this.loading = false;
		});
	}

}
