import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mfObjectSize'
})
export class MFObjectSizePipe implements PipeTransform {
	transform(size: number): string {
		if (size === 0) {
			return '-';
		} else {
			const sizeInKB: number = Math.floor(size / 1000);
			const sizeInMB: number = Math.floor(sizeInKB / 1000);
			if (Math.floor(sizeInMB / 1000) > 0) {
				const sizeInGB: number = Math.floor(sizeInMB / 1000);
				return sizeInGB.toString() + ' GB';
			} else if (Math.floor(sizeInKB / 1000) > 0) {
				const sizeInMB: number = Math.floor(sizeInKB / 1000);
				return sizeInMB.toString() + ' MB';
			} else {
				return sizeInKB.toString() + ' KB';
			}
		}
	}
}
