<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<h1 class="pull-left">
				<a class="icon-link share" href="javascript:" (click)="openModal(lockDialog, 'lg')" *ngIf="(finalizeEnabled && !finalized) && (authService.isAuthenticated() || checkSharePermissions('edit'))"><em class="fas fa-lock-open" title="Finalize document"><span class="sr-only">Finalize document</span></em></a>
				<a class="icon-link share" href="javascript:" (click)="openModal(unlockDialog, 'lg')" *ngIf="(finalizeEnabled && finalized) && (authService.isAuthenticated() || checkSharePermissions('edit'))"><em class="fas fa-lock" title="Unfinalize document"><span class="sr-only">Unfinalize document</span></em></a>
				<button type="button" class="btn btn-md btn-primary" *ngIf="sourcePage !== -1" (click)="back()">Back</button>
				<span *ngIf="objectFiles.length <= 1">
					{{ filename }}
				</span>

				<span dropdown *ngIf="objectFiles.length > 1" class="dropdown-container">
					<a href="javascript:" dropdownToggle class="title">{{ filename }} <em class="fas fa-caret-down"></em></a>
					<ul *dropdownMenu class="dropdown-menu">
						<li *ngFor="let file of objectFiles; let i=index" [ngClass]="i == fileIndex ? 'selectedFile' : ''">
							<a href="javascript:void(0)" (click)="loadFile(i)">{{ file.name + (file.extension ? '.' + file.extension : '') }}</a>
						</li>
					</ul>
				</span>

			</h1>

			<span *ngIf="completedEnabled && (authService.isAuthenticated() || checkSharePermissions('edit'))">
				<a class="icon-link share" href="javascript:" *ngIf="!loaded"><em class="fas fa-spinner fa-spin" title="Loading completed status..."><span class="sr-only">Loading completed status...</span></em>
				</a>
				<a class="icon-link share" href="javascript:" (click)="openModal(completeDialog, 'lg')" *ngIf="loaded && !completed && completeCriteria === []"><em class="fas fa-check" title="No Completed criteria set. Click to edit the completed criteria."><span class="sr-only">No Completed criteria set. Click to edit the completed criteria.</span></em></a>
				<a class="icon-link share" href="javascript:" (click)="openModal(completeDialog, 'lg')" *ngIf="loaded && !completed && completeCriteria !== []"><em class="fas fa-check orange" title="Not completed. Click to view or edit the completed criteria."><span class="sr-only">Not completed. Click to view or edit the completed criteria.</span></em></a>
				<a class="icon-link share" href="javascript:" (click)="openModal(completeDialog, 'lg')" *ngIf="loaded && completed"><em class="fas fa-check green" title="Completed. Click to view or edit the completed criteria."><span class="sr-only">Completed. Click to view or edit the completed criteria.</span></em></a>
			</span>
			<span>
				<a class="icon-link share" href="javascript:" (click)="coauthor()" [ngClass]="showUpload ? 'hidden' : ''" *ngIf="!isBasicViewer && authService.getToken('azure') === 'true' && coauthorEnabled && isCoauthorItem()"><em class="fas fa-user-edit" [ngClass]="item.coauthored ? 'orange' : 'green'" title="Coauthor document"><span class="sr-only">Coauthor document</span></em>
				</a>
				<a class="icon-link share" href="javascript:" (click)="openModal(shareDialog, 'xl')" [ngClass]="showUpload ? 'hidden' : ''" *ngIf="!isBasicViewer && authService.isAuthenticated() && !authService.isExternalUser() && shares.length < 1"><em class="fas fa-share" title="Share document"><span class="sr-only">Share document</span></em></a>
				<a class="icon-link share" href="javascript:" (click)="openModal(shareDialog, 'xl')" [ngClass]="showUpload ? 'hidden' : ''" *ngIf="!isBasicViewer && authService.isAuthenticated() && !authService.isExternalUser() && shares.length > 0"><em class="fas fa-share green" title="Share document"><span class="sr-only">Share document</span></em></a>
				<a class="icon-link file-download" href="javascript:" (click)="download(item)" [ngClass]="showUpload ? 'hidden' : ''" *ngIf="canDownload && (authService.isAuthenticated() || checkSharePermissions('download'))"><em class="fas fa-download" title="Download"><span class="sr-only">Download</span></em></a>
				<a *ngIf="!isBasicViewer && (!finalizeEnabled || !finalized) && ((authService.isAuthenticated() && !authService.isExternalUser()) || checkSharePermissions('upload'))" href="javascript:" (click)="showUpload = !showUpload" [ngClass]="showUpload ? 'hidden' : ''" class="icon-link pull-right"><em class="fas fa-file-upload upload-revision btn-primary" title="Upload new revision"><span class="sr-only">Upload new revision</span></em></a>
				<button type="button" [ngClass]="!showUpload ? 'hidden' : ''" class="icon-link btn btn-md btn-danger pull-right" *ngIf="!isBasicViewer" (click)="showUpload = !showUpload">Cancel</button>
				<button type="button" [ngClass]="!showUpload ? 'hidden' : ''" class="icon-link btn btn-md btn-success pull-right" *ngIf="!isBasicViewer" (click)="upload()">Upload</button>
			</span>
		</header>
	</div>
</div>

<div class="doc" [ngClass]="{'loading in': loading}">

	<as-split direction="horizontal" class="is-horizontal is-init">
		<as-split-area size="5" class="timeline-split">
			<div perfectScrollbar class="the-timeline custom-scroll">
				<div class="text-center marg-v-15">
					<em class="fas fa-history icon-link" title="Revisions"></em>
				</div>
				<div class="timeline">
					<div *ngFor="let rev of revisions" class="timeline-item" [ngClass]="{
                'timeline-item-success': rev != 1,
                'zero-item': rev == 1,
                'selected': rev == revision
              }" [popover]="popTemplate" [outsideClick]="true" [popoverContext]="{ data: rev, info: docInfo }" placement="right" [attr.data-label]="'v' + rev" (click)="loadRevision(rev)">
						<span class="trigger"></span>
						<div class="legend"></div>
					</div>
				</div>
			</div>
		</as-split-area>
		<as-split-area size="60" class="as-split-area">
			<div class="the-preview">
				<div class="the-document">
					<div id="prizmviewer" class="full-width viewer pccv" *ngIf="viewerEnabled"></div>
					<div *ngIf="!viewerEnabled" class="full-width viewer pccv disabled-viewer">
						<embed *ngIf="embedable && downloadedUrl" [src]="downloadedUrl" width="100%" height="100%" />
						<em *ngIf="!embedable || !downloadedUrl" class="icon icon-file icon-other preview-icon" [ngClass]="'icon-' + extension"></em>
					</div>
				</div>
			</div>
		</as-split-area>
		<as-split-area size="35" class="as-split-area">

			<tabset type="tabs nav-lines blue">

				<tab heading="Metadata" [active]="activeTab === 'metadata'" *ngIf="showMetadata">
					<div perfectScrollbar class="custom-scroll">
						<div class="metadata-table-outer">
							<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted metadata-table">
								<thead>
									<tr>
										<th id="property">Property</th>
										<th id="value">Value</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let prop of properties">
										<td class="title">{{prop.name}}</td>
										<td>{{prop.value.displayValue}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</tab>

				<tab heading="Comments ({{ commentsCount }})" [active]="activeTab === 'comments'" *ngIf="commentsEnabled && !isBasicViewer" class="scrollable-pane">
					<div [ngClass]="{'loading in': loadingComments}">
						<div perfectScrollbar class="custom-scroll">
							<div class="metadata-table-outer">
								<div *ngIf="comments" class="comments">
									<div class="rootCommentResponse">
										<form (ngSubmit)="onCommentSubmit($event, commentArea)">
											<textarea #commentArea name="comment" placeholder="Enter a comment..." class="form-control"></textarea>
											<a href="" (click)="clearCommentForm(commentArea)"><button type="reset">Cancel</button></a>
											<a><button type="submit">Reply</button></a>
										</form>
									</div>
									<app-comments *ngFor="let comment of comments" (newComment)="onNewComment($event)" [comment]="comment" [item]="item"></app-comments>
								</div>
							</div>
						</div>
					</div>
				</tab>

			</tabset>

		</as-split-area>
	</as-split>
	<cwx-uploader *ngIf="showUpload && !isBasicViewer" [@myAnimation] (queue)="onUploadQueueChange($event)" [max_size]="file_size_limit" [multiple]="false"></cwx-uploader>
</div>

<ng-template #shareDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-share"></em> Share page</h5>
	</div>
	<div class="modal-body share-dialog">
		<p><button class="btn btn-success btn-md" (click)="addShareLink()">Add Link</button></p>
		<p>&nbsp;</p>
		<table>
			<tr *ngIf="shares.length > 0">
				<th id="sharedByCol">Shared By</th>
				<th id="sharedToCol">Shared To</th>
				<th id="sharedOnCol">Shared On Date</th>
				<th id="sharedUntilCol">Shared Until Date</th>
				<th id="permissionsCol">Permissions</th>
				<th id="accessCodeCol">Access Code</th>
				<th id="copyCol"></th>
				<th id="deleteCol"></th>
			</tr>
			<tr *ngFor="let share of shares; let i = index">
				<td>{{share.sharedBy}}</td>
				<td><input type="text" [(ngModel)]="share.sharedTo" class="form-control"></td>
				<td>{{share.sharedOn | date:'mediumDate'}}</td>
				<td><input [(ngModel)]="share.sharedUntil" bsDatepicker class="form-control"></td>
				<td>
					<select [(ngModel)]="share.permissions" class="form-control">
						<option value="view">View</option>
						<option value="download">Download</option>
						<option value="edit">Edit</option>
						<option value="upload">Upload</option>
						<option value="delete">Delete</option>
					</select>
				</td>
				<td><input type="text" [(ngModel)]="share.accessCode" class="form-control"></td>
				<td><button *ngIf="share.token" class="btn-xs btn btn-info form-control" (click)="copyShareLink(share)">Copy</button></td>
				<td><button class="btn-xs btn btn-danger form-control" (click)="removeShareLink(i)">Remove</button></td>
			</tr>
		</table>

	</div>
	<div class="modal-footer no-bg">
		<div class="pull-left">
			<em class="far fa-lightbulb"></em>&nbsp; <strong>TIP:</strong> Multiple links can be created with various permissions.
		</div>

		<button type="button" class="btn btn-primary btn-md" (click)="updateShareLinks()">Save</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Close</button>
	</div>
</ng-template>

<ng-template #lockDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-share"></em>Finalize Document</h5>
	</div>
	<div class="modal-body">
		<p>Are you sure you want to finalize the document?</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="finalize()">Yes</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #unlockDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-share"></em>Unfinalize Document</h5>
	</div>
	<div class="modal-body">
		<p>Are you sure you want to un-finalize the document?</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="unfinalize()">Yes</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #completeDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-check"></em>Set Document Completed Criteria</h5>
	</div>
	<div class="modal-body">
		<button class="btn btn-success btn-md" (click)="addCriteria()">Add Criteria</button>
		<p>&nbsp;</p>
		<table [hidden]="completeCriteria.length === 0" class="complete-criteria-form">
			<thead>
				<tr>
					<th id="field">Field</th>
					<th id="comparison">Comparison</th>
					<th id="value">Value</th>
					<th id="remove"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let criteria of completeCriteria;let i = index">
					<td>
						<ng-select class="typeahead" [items]="vaultProperties" [clearable]="false" bindLabel="name" bindValue="id" placeholder="Select Criteria Property" [(ngModel)]="criteria.id" (change)="criteriaFieldChanged($event, i)">
						</ng-select>
					</td>
					<td>
						<select [(ngModel)]="criteria.comparison" class="form-control comparison">
							<option *ngIf="objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 10" value="eq">Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 10" value="ne">Not</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="lt">Less Than</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="gt">Greater Than</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="lte">Less Than Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="gte">Greater Than Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 2 && objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 9" value="con">Contains</option>
						</select>
					</td>
					<td>
						<input *ngIf="!objectLists[criteria.id] && objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7" [(ngModel)]="criteria.value" class="form-control">
						<input *ngIf="objectTypes[criteria.id] === 5 || objectTypes[criteria.id] === 6 || objectTypes[criteria.id] === 7" type="text" [(ngModel)]="criteria.value" bsDatepicker class="form-control">
						<ng-select *ngIf="objectLists[criteria.id]" class="typeahead" [items]="objectLists[criteria.id]" [clearable]="false" bindLabel="name" bindValue="id" placeholder="Select Criteria Value" [(ngModel)]="criteria.value">
						</ng-select>
					</td>
					<td>
						<button class="btn-xs btn btn-danger form-control" (click)="removeCriteria(i)">Remove</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="setCriteria()">Accept</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #coauthorModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-user-edit"></em>End Coauthoring</h5>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
		<button type="button" class="btn btn-success btn-md" (click)="resumeCoauthor()">Resume Coauthoring</button>
		<button type="button" class="btn btn-warning btn-md" (click)="undoCoauthor()">Cancel Changes</button>
		<button type="button" class="btn btn-primary btn-md" (click)="checkinCoauthor()">Save Changes</button>
	</div>
</ng-template>

<ng-template #uploadProgressModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-upload"></em>Uploading Files</h5>
	</div>
	<div class="modal-body upload-progress-modal-body" [ngClass]="{'loading in': uploadsCommitting}">
		<div *ngFor="let progress of uploadProgressStats | keyvalue" class="upload-progress-section">
			<div>{{ progress.key }}</div>
			<ng-container *ngIf="progress.value === 'ERROR'; else elseTemplate">
				<div class="upload-progress-error">
					<em class="fas fa-exclamation-circle error"></em>
					An error occurred while uploading this file. Please try again later.
				</div>
			</ng-container>
			<ng-template #elseTemplate>
				<div class="upload-progress-container">
					<div class="upload-progress-bar">
						<mat-progress-bar mode="determinate" [value]="progress.value">
						</mat-progress-bar>
					</div>
					<div class="upload-progress-num">{{ progress.value }}%</div>
				</div>
			</ng-template>

		</div>
	</div>
	<div class="modal-footer message-footer">
		<button type="button" class="btn btn-md btn-danger" (click)="cancelUpload()">Cancel</button>
	</div>
</ng-template>