export class InboundBidMessage {
	public message: string;
	public files: File[];
	public commercialConfidence: boolean;

	constructor() {
		this.message = "";
		this.files = [];
		this.commercialConfidence = false;
	}
}