<div [ngClass]="{'loading in': view.loading && !view.viewContents }">
	<div class="panel panel-default" [ngClass]="{ 'tile-view': tiles }">
		<div *ngIf="view.showCheckin" [@myAnimation] (@myAnimation.start)="animationStart()" (@myAnimation.done)="animationDone()">
			<cwx-uploader class="panelUploader" (queue)="onUploadQueueChange(view, $event)" (formObj)="onFormChange($event)" (formProps)="onPropertiesChange($event)" [defaultClass]="defaultClass" [autopopulateName]="autopopulateDocName" [nameProp]="nameProp" [allowUsersToAddProps]="allowUsersToAddProps" [inheritSelected]="inheritSelected" [associatedObjectType]="associatedObjectType" [associatedObject]="associatedObject" [associatedProp]="associatedProp" [autoSubmit]="autoSubmit" [filelessObjectType]="filelessObjectType" [filelessClass]="filelessClass" [max_size]="file_size_limit" [multiple]="true" [isForm]="true" [isRevision]="view.isRevision">
			</cwx-uploader>
			<div class="panelUploaderButtonDiv">
				<button type="button" href="javascript:" (click)="closeCheckin(view)" class="btn btn-danger btn-md cancel">Cancel</button>
				<button type="button" href="javascript:" *ngIf="!view.isRevision" [disabled]="!formValid" (click)="upload(view)" class="btn btn-success btn-md upload">Upload</button>
				<button type="button" href="javascript:" *ngIf="view.isRevision" [disabled]="view.files.length < 1" (click)="uploadRevision(view)" class="btn btn-success btn-md upload">Upload</button>
			</div>
		</div>
		<div class="panel-heading" *ngIf="view && view.viewContents">
			<div class="h3 panel-title flex">
				<!-- <a class="draggable-control text-muted ui-sortable-handle"><em class="fas fa-arrows-alt" title="Move Panel"><span class="sr-only">Move Widget</span></em></a> -->
				<div class="pull-left">
					<a class="panel-title" href="javascript:">
						<ol class="breadcrumb">
							<ng-container *ngFor="let crumb of view.viewContents.breadcrumb">
								<li *ngIf="crumb.path.indexOf(view.startingPath) > -1">
									<span class="very-title" (click)="openView(crumb.path, view)">{{crumb.name}}</span>
								</li>
							</ng-container>
						</ol>
					</a>
				</div>
				<!--
                <div class="pull-right panel-controls">
                    <div class="view-controls">
                        <a href="javascript:" (click)="setTiles(false)" [ngClass]="{'active': !tiles }" class="icon-link active">
                            <em class="fas fa-list" title="List View">
                                <span class="sr-only">List View</span>
                            </em>
                        </a>
                        <a href="javascript:" (click)="setTiles(true)" [ngClass]="{'active': tiles }" class="icon-link">
                            <em class="fas fa-th-large" title="Grid View">
                                <span class="sr-only">Grid View</span>
                            </em>
                        </a>
                    </div>
                </div>
                -->
			</div>
		</div>
		<div class="panel-body" class="panel-body" *ngIf="view && view.viewContents">
			<div [ngClass]="{'loading in': view.loading}">
				<div class="upload-button-div">
					<button type="button" *ngIf="(view.viewContents.views.length > 0 || view.viewContents.folders.length > 0 || view.viewContents.traditionalFolders.length > 0) && !documentDownloadsDisabled" class="btn btn-md btn-primary upload-button" (click)="downloadFolders(view)"><em *ngIf="loadingDownloadFolders" class="fas fa-circle-notch fa-spin"></em> Download Folders</button>

					<ng-container *ngIf="showUploadDocumentButton()">
						<ng-container *ngFor="let upload of filelessUploads">
							<button *ngIf="canCreate(upload)" type="button" class="btn btn-md btn-success upload-button" (click)="openFilelessCheckin(view, upload)" [ngClass]="{'disabled': uploadsDisabled}" [disabled]="uploadsDisabled">Create {{ getClassName(upload) }}</button>
						</ng-container>
						<button type="button" *ngIf="view.viewContents.items.length > 0 && !documentDownloadsDisabled" class="btn btn-md btn-primary upload-button" (click)="downloadAll(view)"><em *ngIf="loadingDownloadAll" class="fas fa-circle-notch fa-spin"></em> Download All</button>
						<button type="button" *ngIf="!uploadsDisabled" class="btn btn-md btn-success upload-button" (click)="openCheckin(view)">Upload Document</button>
					</ng-container>
					<div *ngIf="view.viewContents.views.length < 1 && view.viewContents.traditionalFolders.length < 1 && view.viewContents.folders.length < 1 && view.viewContents.items.length < 1" class="no-document-text">
						<p>There are no documents to display</p>
					</div>
				</div>

				<!-- [MCT-269] Removing this button because it doesn't fit CapAlt's Use Case -->
				<!-- Temporary security for editable grid. Only internal users can see the button to edit metadata. -->
				<div *ngIf="massMetadataUpdates && view.viewContents.items && view.viewContents.items.length > 0 && !this.authService.isExternalUser()" class="edit-grid-div">
					<button *ngIf="!agGridChangesMade" class="btn btn-sm" (click)="toggleEditMode()"><em class="fas fa-edit"></em></button>
					<button *ngIf="agGridChangesMade" class="btn btn-sm" (click)="saveChangedObjs()"><em class="fas fa-save"></em></button>
					<button *ngIf="agGridChangesMade" class="btn btn-sm" (click)="cancelChangedObjs()"><em class="fas fa-window-close"></em></button>
				</div>

				<div *ngIf="view.viewContents.views && view.viewContents.views.length > 0">
					<div [attr.id]="viewPanel">
						<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted">
							<thead>
								<tr>
									<th id="views">Views</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let subview of view.viewContents.views" view-folder-row [ngStyle]="{'backgroundImage': tiles ? ('View' | thumbnailBackgroundUrl) : 'none', 'background-size': 'Folder' !== 'Content' ? '40%' : '' }" [item]="subview" [inGrid]="inGrid" [completedEnabled]="completedEnabled" [vaultProperties]="vaultProperties" [objectTypes]="objectTypes" [objectLists]="objectLists" (openView)="openView($event, view)">
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div *ngIf="view.viewContents.traditionalFolders && view.viewContents.traditionalFolders.length > 0">
					<div [attr.id]="viewPanel">
						<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted">
							<thead>
								<tr>
									<th id="traditionalFolders">Traditional Folders</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let folder of view.viewContents.traditionalFolders" view-folder-row [ngStyle]="{'backgroundImage': tiles ? ('Folder' | thumbnailBackgroundUrl) : 'none', 'background-size': 'Folder' !== 'Content' ? '40%' : '' }" [item]="folder" [inGrid]="inGrid" [completedEnabled]="completedEnabled" [vaultProperties]="vaultProperties" [objectTypes]="objectTypes" [objectLists]="objectLists" (openView)="openView($event, view)">
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div *ngIf="view.viewContents.folders && view.viewContents.folders.length > 0">
					<div [attr.id]="viewPanel">
						<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted">
							<thead>
								<tr>
									<th id="virtualFolders">Virtual Folders</th>
									<th *ngIf="alertsEnabled"></th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let folder of view.viewContents.folders" view-folder-row [ngStyle]="{'backgroundImage': tiles ? ('Folder' | thumbnailBackgroundUrl) : 'none', 'background-size': 'Folder' !== 'Content' ? '40%' : '' }" [item]="folder" [inGrid]="inGrid" [completedEnabled]="completedEnabled" [alertsEnabled]="alertsEnabled" [vaultProperties]="vaultProperties" [objectTypes]="objectTypes" [objectLists]="objectLists" [alertTriggers]="alertTriggers" [alertFrequencies]="alertFrequencies" [alertDays]="alertDays" (openView)="openView($event, view)">
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div *ngIf="view.viewContents.items && view.viewContents.items.length > 0">
					<div [attr.id]="viewPanel">
						<div class="scroll-holder">
							<div class="table-scroll horizontal-scroll custom-scroll top">
								<table *ngIf="!editMode" class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted">
									<thead>
										<tr *ngIf=!tiles>
											<th id="completed" *ngIf="completedEnabled"></th>
											<th id="favorite" *ngIf="favoritesEnabled"></th>
											<th *ngFor="let column of view.viewContents.columns" [id]="column.name ? column.name.replace(' ', '-') : ''"><span [ngStyle]="{'curser': 'pointer'}">{{ column.name }}</span>
											<th id="upload"></th>
											<!--<th id="shareLink"></th>-->
											<th id="download"></th>
											<th id="delete"></th>
											<th id="coauthor" *ngIf="coauthorEnabled"></th>
											<!--<th id="associate" *ngIf="associateSelected"></th>-->
										</tr>
									</thead>
									<tbody>
										<mfiles-row *ngFor="let item of view.viewContents.items" [documentRevisionsDisabled]="documentRevisionsDisabled" [documentDownloadsDisabled]="documentDownloadsDisabled" [documentDeletionsDisabled]="documentDeletionsDisabled" [doNotDownload]="item?.extension && configService.supportedVideoFormats.indexOf(item?.extension.toLowerCase()) > -1" [ngStyle]="{'backgroundImage': tiles ? ('File' | thumbnailBackgroundUrl) : 'none', 'background-size': 'File' !== 'Content' ? '40%' : '' }" [view]="view" [item]="item" [inGrid]="inGrid" [completedCriteriaField]="completedCriteriaField" [completedField]="completedField" [completedEnabled]="completedEnabled" [vaultProperties]="vaultProperties" [objectTypes]="objectTypes" [objectLists]="objectLists" [favoritesEnabled]="favoritesEnabled" [coauthorEnabled]="coauthorEnabled" [associateSelectedEnabled]="associateSelected" [icon]="objectTypeIcons[item.type] ? objectTypeIcons[item.type].changingThisBreaksApplicationSecurity : ''" [page]="page" [path]="view.path" [pageId]="pageId" [widgetId]="widgetId" [objectTypeIcons]="objectTypeIcons" [automaticWorkflowTransitions]="automaticWorkflowTransitions" [startingState]="startingState" [targetState]="targetState" [disableRelatedView]="disableRelatedView" [deleteEnabled]="true" [autoSelectType]="autoSelectType" [autoSelectId]="autoSelectId" (uploaderOpened)="openCheckinForRevision(view, $event.itemId, $event.fileIndex)" (associateObjectType)="associateObjectType($event)" (associateObject)="associateObject($event)" (deleteClicked)="openVerifyDelete($event.name, $event.itemType, $event.itemId)" (loadIcon)="getIcon($event)" (loadView)="loadView($event)">
										</mfiles-row>
									</tbody>
								</table>

								<ag-grid-angular *ngIf="editMode" id="ag-grid" class="ag-theme-alpine" [columnDefs]="agGridColumnDefs" [rowData]="agGridRowData" [frameworkComponents]="frameworkComponents" [context]="context" rowSelection="multiple" enableCellTextSelection="true" autoSizeColumns="true" (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueChanged($event)" (cellClicked)="onCellClicked($event)"></ag-grid-angular>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #deleteDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-trash"></em> Delete</h5>
	</div>
	<div class="modal-body share-dialog">
		Are you sure you want to delete {{ deleteName }}?
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="deleteObject()">Delete</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #downloadDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-download"></em> Download</h5>
	</div>
	<div class="modal-body share-dialog">
		Your download has been prepared. Please click <a [href]="downloadLink" target="_blank">here</a> to download the file.
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Close</button>
	</div>
</ng-template>

<ng-template #uploadProgressModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-upload"></em>Uploading Files</h5>
	</div>
	<div class="modal-body upload-progress-modal-body" [ngClass]="{'loading in': uploadsCommitting}">
		<div *ngFor="let progress of uploadProgressStats | keyvalue" class="upload-progress-section">
			<div>{{ progress.key }}</div>
			<ng-container *ngIf="progress.value === 'ERROR'; else elseTemplate">
				<div class="upload-progress-error">
					<em class="fas fa-exclamation-circle error"></em>
					An error occurred while uploading this file. Please try again later.
				</div>
			</ng-container>
			<ng-template #elseTemplate>
				<ng-container *ngIf="progress.value === 'EMPTY'; else notEmptyTemplate">
					<div class="upload-progress-empty">
						<em class="fas fa-exclamation-triangle"></em>
						This file has no content. It will be skipped.
					</div>
				</ng-container>
				<ng-template #notEmptyTemplate>
					<div class="upload-progress-container">
						<div class="upload-progress-bar">
							<mat-progress-bar mode="determinate" [value]="progress.value">
							</mat-progress-bar>
						</div>
						<div class="upload-progress-num">{{ progress.value }}%</div>
					</div>
				</ng-template>
			</ng-template>

		</div>
	</div>
	<div class="modal-footer message-footer">
		<span id="uploadProgressMessage">{{ uploadProgressMessage }}</span>
		<button type="button" class="btn btn-md btn-danger" (click)="cancelUpload()">Cancel</button>
	</div>
</ng-template>