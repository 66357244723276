import { Location } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, Subject, Subscription, catchError, concatMap, forkJoin, from, map, mergeMap, takeUntil, tap, toArray } from 'rxjs';
import { InboundBidMessage } from '../models/inbound-bid-message.model';
import { Lookup } from '../models/lookup.model';
import { MFilesItem } from '../models/mfiles-item.model';
import { MFilesProperty } from '../models/mfiles-property.model';
import { Property } from '../models/property.model';
import { WorkflowState } from '../models/workflow-state.model';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { MFilesService } from '../services/mfiles.service';
import { PageService } from '../services/page.service';
import { Constants } from './constants';

@Component({
	selector: 'app-bid-request',
	templateUrl: './bid-request.component.html',
	styleUrls: ['./bid-request.component.css']
})
export class BidRequestComponent implements OnInit, OnDestroy {
	@ViewChild('uploadDocsModal') private uploadDocsModal: TemplateRef<any>;
	@ViewChild('stateTransitionCommentModal') private stateTransitionCommentModal: TemplateRef<any>;
	@ViewChild('requestToMakePublicModal') private requestToMakePublicModal: TemplateRef<any>;
	@ViewChild('viewerModal') private viewerModal: TemplateRef<any>;
	@ViewChild('navSelect') navSelect: NgSelectComponent;

	loading = false;
	loadingRelated = false;
	loadingStates = false;
	loadingProperties = false;
	loadingDownloadIndex = -1;
	loadingDownloadSelected = false;
	loadingDownloadAll = false;
	loadingBidDocs = false;
	bidDocsLoaded = false;
	loadingCommunications = false;
	communicationsLoaded = false;
	loadingResponseDocs = false;
	deletingResponseDoc = false;
	responseDocsLoaded = false;
	loadingProject = false;
	responsesUploading = false;
	communicationUploading = false;
	sendingTransitionComment = false;
	cicUpdating: boolean = false;

	currentUserId: number;
	bidRequestId: number;
	bidRequest: MFilesItem;
	bidDocuments: MFilesItem[] = [];
	bidResponseDocs: MFilesItem[] = [];
	states: WorkflowState[] = [];
	bid: MFilesItem;
	bidChanges: MFilesItem[] = [];
	bidCommunications: any[] = [];
	project: MFilesItem;
	workflowIds: any;
	workflowStateIds: any;
	propertyDefIds: any;
	classIds: any;
	vaultProperties: Property[] = [];
	responseClassProps: Property[] = [];
	communicationClassProps: Property[] = [];
	communicationAttachmentClassProps: Property[] = [];
	associatedUploadProperty: number;
	responseClass: any;
	communicationClass: any;
	communicationAttachmentClass: any;
	inboundBidMessage: InboundBidMessage = new InboundBidMessage();
	hasCommercialInConfidence = false;
	transitionComment: string = '';
	selectedState: any;
	bidClosed = false;
	bidRequestComplete = false;
	showCorrespondence = false;
	showResponseDocs = false;
	selectedCommunication: any;
	reasonToMakePublic = '';
	showGoBackButton = false;
	numResponseDocsUploaded = 0;

	currTab: string = 'overview';

	overviewMessage: string = '';
	bidRequestOpenedMessage: string;
	bidRequestInitialReviewMessage: string;
	bidRequestAcceptedMessage: string;
	bidRequestDeclinedMessage: string;
	bidDocsUploadedMessage: string;
	bidRequestCompleteMessage: string;
	bidClosedMessage: string;
	bidRequestAwardedMessage: string;
	correspondenceCreationSuccessMessage: string;
	responseUploadSuccessMessage: string;
	cicUpdatedMessage: string;
	publicMessagesEnabled: boolean;
	bidRequestReopenedMessage: string;

	public selectedIndex;
	public selectedType;

	modalRef: BsModalRef;

	attachments: File[] = [];
	uploadedResponseDocs: File[] = [];
	responseDocsUploaded = false;
	communicationUploaded = false;
	transitionCommentSent = false;
	cicUpdated: boolean = false;

	documentViewed = false;
	properties = [];

	viewerSettings = {
		displayViewer: true,
		displayMetadata: true,
		displayComments: false,
		displayWorkflow: false,
		disableAnnotations: true
	};

	viewerModalSettings = {
		displayViewer: true,
		displayMetadata: false,
		displayComments: false,
		displayWorkflow: false,
		disableAnnotations: true
	};

	uploadProgressStats: any = {};
	microserviceUploadSubscription: Subscription;
	uploadProgressMessage = '';

	constructor(
		private route: ActivatedRoute,
		private mfilesService: MFilesService,
		private location: Location,
		private pageService: PageService,
		private toastr: ToastrService,
		private modalService: BsModalService,
		public configService: ConfigurationService,
		private authService: AuthService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe((queryParams: Params) => {
			if (queryParams['internal']) {
				this.showGoBackButton = true;

				this.router.navigate([],
					{
						queryParams: { internal: null },
						replaceUrl: true,
						queryParamsHandling: 'merge'
					});
			}
		});

		this.route.params.subscribe((params: Params) => {
			this.bidRequestId = parseInt(params['id'], 10);

			this.getWorkflowIds();
			this.getWorkflowStateIds();
			this.getClassIds();
			this.getPropertyDefIds();
			this.getConfigMessages();

			this.getBidRequest();
		});
	}

	ngOnDestroy(): void {
		this.bidDocuments.forEach(doc => {
			doc.cancelDownloadSubject.next(true);
		});

		this.bidResponseDocs.forEach(doc => {
			doc.cancelDownloadSubject.next(true);
		});
	}

	selectDefaultNav() {
		setTimeout(() => {
			const item = this.navSelect.itemsList.findByLabel('Overview');
			this.navSelect.select(item);
		}, 500);
	}

	private getWorkflowIds() {
		this.mfilesService.getWorkflowsByAlias([
			Constants.INBOUND_BID_MESSAGE_WORKFLOW,
			Constants.BID_COMMUNICATION_ATTACHMENT_WORKFLOW,
			Constants.BID_RESPONSE_WORKFLOW
		]).subscribe(response => {
			this.workflowIds = response.body;
		});
	}

	private getWorkflowStateIds() {
		this.mfilesService.getStatesByAlias([
			Constants.BID_REQUEST_OPENED_STATE,
			Constants.BID_REQUEST_INITIAL_REVIEW_STATE,
			Constants.BID_REQUEST_ACCEPTED_STATE,
			Constants.BID_REQUEST_DOCS_UPLOADED_STATE,
			Constants.BID_REQUEST_DECLINED_STATE,
			Constants.BID_REQUEST_COMPLETE_STATE,
			Constants.BID_REQUEST_CLOSED_STATE,
			Constants.BID_REQUEST_AWARDED_STATE,
			Constants.BID_CLOSED_STATE,
			Constants.INBOUND_BID_MESSAGE_STARTING_STATE,
			Constants.BID_COMMUNICATION_ATTACHMENT_STARTING_STATE,
			Constants.BID_RESPONSE_STARTING_STATE,
			Constants.BID_COMMUNICATION_REQUEST_TO_MAKE_PUBLIC_STATE,
			Constants.BID_COMMUNICATION_APPROVE_TO_MAKE_PUBLIC_STATE,
			Constants.BID_COMMUNICATION_DECLINE_TO_MAKE_PUBLIC_STATE,
			Constants.BID_REQUEST_REOPENED_STATE
		]).subscribe(response => {
			this.workflowStateIds = response.body;
		});
	}

	private getClassIds() {
		this.mfilesService.getClassesByAlias([
			Constants.BID_RESPONSE_CLASS,
			Constants.INBOUND_BID_MESSAGE_CLASS,
			Constants.BID_COMMUNICATION_ATTACHMENT_CLASS
		]).subscribe(response => {
			this.classIds = response.body;

			if (this.classIds[Constants.BID_RESPONSE_CLASS] !== -1) {
				this.mfilesService.getClass(this.classIds[Constants.BID_RESPONSE_CLASS]).subscribe(response => {
					this.responseClass = response;
				});

				this.mfilesService.getClassProperties(this.classIds[Constants.BID_RESPONSE_CLASS]).subscribe(responseProps => {
					this.responseClassProps = responseProps;
				});
			}

			if (this.classIds[Constants.INBOUND_BID_MESSAGE_CLASS] !== -1) {
				this.mfilesService.getClass(this.classIds[Constants.INBOUND_BID_MESSAGE_CLASS]).subscribe(response => {
					this.communicationClass = response;
				});

				this.mfilesService.getClassProperties(this.classIds[Constants.INBOUND_BID_MESSAGE_CLASS]).subscribe(communicationProps => {
					this.communicationClassProps = communicationProps;
				});
			}

			if (this.classIds[Constants.BID_COMMUNICATION_ATTACHMENT_CLASS] !== -1) {
				this.mfilesService.getClass(this.classIds[Constants.BID_COMMUNICATION_ATTACHMENT_CLASS]).subscribe(response => {
					this.communicationAttachmentClass = response;
				});

				this.mfilesService.getClassProperties(this.classIds[Constants.BID_COMMUNICATION_ATTACHMENT_CLASS]).subscribe(communicationAttachmentProps => {
					this.communicationAttachmentClassProps = communicationAttachmentProps;
				});
			}
		});
	}

	private getPropertyDefIds() {
		this.mfilesService.getPropertyDefsByAlias([
			Constants.VIEWED_BY_PROPERTY,
			Constants.ASSOCIATED_UPLOAD_PROPERTY,
			Constants.IS_PRIVATE_PROPERTY,
			Constants.REASON_TO_MAKE_PUBLIC,
			Constants.BID_COMMUNICATION_PROPERTY
		]).subscribe(response => {
			this.propertyDefIds = response.body;
			this.hasCommercialInConfidence = this.propertyDefIds[Constants.IS_PRIVATE_PROPERTY] !== -1;
		});

		this.mfilesService.getVaultProperties().subscribe(response => {
			this.vaultProperties = response.body;
		});
	}

	/**
	 * Retrieves all of the configurable messages from the Tender Management configurations.
	 */
	private getConfigMessages() {
		this.configService.list(true).subscribe((result) => {
			let conditionsMet: number = 0;

			for (const config of result) {
				if (config.name === 'bidRequestOpenedMessage') {
					this.bidRequestOpenedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidRequestInitialReviewMessage') {
					this.bidRequestInitialReviewMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidRequestAcceptedMessage') {
					this.bidRequestAcceptedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidRequestDeclinedMessage') {
					this.bidRequestDeclinedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidDocsUploadedMessage') {
					this.bidDocsUploadedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidRequestCompleteMessage') {
					this.bidRequestCompleteMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidClosedMessage') {
					this.bidClosedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidRequestAwardedMessage') {
					this.bidRequestAwardedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'correspondenceCreationSuccessMessage') {
					this.correspondenceCreationSuccessMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'responseUploadSuccessMessage') {
					this.responseUploadSuccessMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'cicUpdatedMessage') {
					this.cicUpdatedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'publicMessagesEnabled') {
					this.publicMessagesEnabled = config.value.toLowerCase() === 'true';
					conditionsMet = conditionsMet + 1;
				} else if (config.name === 'bidRequestReopenedMessage') {
					this.bidRequestReopenedMessage = config.value !== null && config.value !== undefined ? config.value : '';
					conditionsMet = conditionsMet + 1;
				}

				if (conditionsMet === 13) {
					break;
				}
			}
		});
	}

	/**
	 * Retrieves the bid, available workflow states, and current user ID.
	 */
	private getBidRequest() {
		this.loading = true;
		this.mfilesService.getBidRequestInfo(this.bidRequestId).subscribe({
			next: (response: any) => {
				if (response) {
					this.bidRequest = response.bidRequest;
					this.states = response.states;
					this.currentUserId = response.userId;
					this.bid = response.bid;

					// FH-3443 - Get all bid response documents right away, since the OverviewMessage is now dependent on it.
					this.getBidResponseDocs();
					// When the tender is closed OR when the bid package is awarded, the user shouldn't be able to see any more workflow buttons, the Create Message button, or the Response Upload or Delete buttons
					if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_CLOSED_STATE] || this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_AWARDED_STATE]) {
						this.bidClosed = true;
					}

					// When the bid is complete, the user shouldn't be able to upload or delete documents on the Response Documents tab.
					if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_COMPLETE_STATE]) {
						this.bidRequestComplete = true;
					} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_OPENED_STATE]) {	// If the bidRequest is in the Opened state, automatically transition it to the Initial Review state
						const initReviewStateId: number = this.workflowStateIds[Constants.BID_REQUEST_INITIAL_REVIEW_STATE];

						if (initReviewStateId !== -1) {
							// Transition the bid to the next state and reload the next workflow state transitions
							this.loadingStates = true;
							this.mfilesService.updateSingleProperty(39, 9, initReviewStateId, this.bidRequest.objVer.type, this.bidRequest.objVer.id).subscribe({
								next: (result) => {
									this.mfilesService.getWorkflowStateTransitions(this.bidRequest.objVer.type, this.bidRequest.objVer.id, this.bidRequest.currentWorkflow, initReviewStateId).subscribe({
										next: (states) => {
											this.bidRequest.currentState = initReviewStateId;
											this.states = states;
											this.updateOverviewMessage();
											this.showOrHideAcceptedTabs();
											this.loadingStates = false;
										},
										error: (err) => {
											console.error(err);
											this.loadingStates = false;
											this.loading = false;
										},
										complete: () => {
											this.loadingStates = false;
											this.loading = false;
										}
									});
								},
								error: (err) => {
									console.error(err);
									this.loadingStates = false;
									this.loading = false;
								}
							});
						}
					}

					// Only show the Correspondence and Response Docs tabs after the bid has been accepted
					this.showOrHideAcceptedTabs();

					this.getProject();
				} else {
					console.error('An error occurred and the bidRequest could not be loaded.');
					this.router.navigate(['/page/welcome']);
				}
			},
			error: (err) => {
				console.error(err);
				this.loading = false;
			},
			complete: () => {
				this.updateOverviewMessage();

				// If the bidRequest is in the opened state and automatically being transitioned to the Initial Review state, keep the entire page loading. Else, function normally and stop loading.
				if (this.bidRequest.currentState !== this.workflowStateIds[Constants.BID_REQUEST_OPENED_STATE] && !this.loadingStates) {
					this.loading = false;
				}

				this.selectDefaultNav();
			}
		});
	}

	refresh() {
		this.bidDocsLoaded = false;
		this.communicationsLoaded = false;
		this.responseDocsLoaded = false;
		this.getBidRequest();

		if (this.currTab === 'bidDocs') {
			this.getBidDocs();
		} else if (this.currTab === 'correspondence') {
			this.getBidCommunications();
		} else if (this.currTab === 'responseDocs') {
			this.getBidResponseDocs();
		}
	}

	/**
	 * Sets the showCorrespondence and showResponseDocs values to true if the bid has been accepted.
	 * Otherwise, sets them to false.
	 */
	private showOrHideAcceptedTabs() {
		if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_ACCEPTED_STATE]
			|| this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE]
			|| this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_COMPLETE_STATE]
			|| this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_AWARDED_STATE]
			|| this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_REOPENED_STATE]
			|| this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_CLOSED_STATE]) {
			this.showCorrespondence = true;
			this.showResponseDocs = true;
		}
	}

	// /**
	//  * Retrieves the bid documents, bid response documents, bid project and
	//  * any bid communications and bid changes.
	//  */
	// private getRelatedObjs() {
	// 	this.loadingRelated = true;
	// 	this.mfilesService.getBidRequestRelated(this.bidRequest, this.bid).subscribe({
	// 		next: (response: any) => {
	// 			this.bidDocuments = response.bidDocuments;
	// 			this.bidResponseDocs = response.bidResponseDocuments;
	// 			this.bidChanges = response.bidChanges;
	// 			this.bidCommunications = response.bidCommunications;
	// 			this.project = response.project;

	// 			for (let i = 0; i < this.bidCommunications.length; i++) {
	// 				this.bidCommunications[i].isOpen = false;	// Used to toggle the attachments for a communication

	// 				if (this.workflowStateIds[Constants.BID_COMMUNICATION_REQUEST_TO_MAKE_PUBLIC_STATE] !== -1) {
	// 					// For each communication, check to see if it is in the Request To Make Public state.
	// 					if (this.bidCommunications[i].currentState === this.workflowStateIds[Constants.BID_COMMUNICATION_REQUEST_TO_MAKE_PUBLIC_STATE]) {
	// 						this.bidCommunications[i].isRequestToMakePublic = true;
	// 					} else {
	// 						this.bidCommunications[i].isRequestToMakePublic = false;
	// 					}
	// 				}
	// 			}
	// 		},
	// 		error: (err) => {
	// 			console.error(err);
	// 			this.loadingRelated = false;
	// 		},
	// 		complete: () => {
	// 			this.loadingRelated = false;
	// 		}
	// 	});
	// }

	/**
	 * Retrieves the bid documents.
	 */
	getBidDocs() {
		if (!this.bidDocsLoaded && !this.loadingBidDocs) {
			this.loadingBidDocs = true;
			this.mfilesService.getBidDocs(this.bidRequest, this.bid).subscribe({
				next: (response: any) => {
					this.bidDocuments = response.bidDocuments;

					this.bidDocuments.forEach((doc) => {
						doc.downloading = false;
						doc.percentDownloaded = 0;
						doc.cancelDownloadSubject = new Subject<boolean>();
					});
				},
				error: (err) => {
					console.error(err);
					this.loadingBidDocs = false;
					this.bidDocsLoaded = true;
				},
				complete: () => {
					this.loadingBidDocs = false;
					this.bidDocsLoaded = true;
				}
			});
		}
	}

	/**
	 * Retrieves the bid communications and their attachments.
	 */
	getBidCommunications() {
		if (!this.communicationsLoaded && !this.loadingCommunications) {
			this.loadingCommunications = true;
			this.mfilesService.getBidCommunications(this.bidRequest, this.bid).subscribe({
				next: (response: any) => {
					this.bidCommunications = response.bidCommunications;

					for (let i = 0; i < this.bidCommunications.length; i++) {
						this.bidCommunications[i].isOpen = false;	// Used to toggle the attachments for a communication

						if (this.workflowStateIds[Constants.BID_COMMUNICATION_REQUEST_TO_MAKE_PUBLIC_STATE] !== -1) {
							// For each communication, check to see if it is in the Request To Make Public state.
							if (this.bidCommunications[i].currentState === this.workflowStateIds[Constants.BID_COMMUNICATION_REQUEST_TO_MAKE_PUBLIC_STATE]) {
								this.bidCommunications[i].isRequestToMakePublic = true;
							} else {
								this.bidCommunications[i].isRequestToMakePublic = false;
							}
						}
					}
				},
				error: (err) => {
					console.error(err);
					this.loadingCommunications = false;
					this.communicationsLoaded = true;
				},
				complete: () => {
					this.loadingCommunications = false;
					this.communicationsLoaded = true;
				}
			});
		}
	}

	/**
	 * Retrieves the bid response documents.
	 */
	getBidResponseDocs() {
		if (!this.responseDocsLoaded && !this.loadingResponseDocs) {
			this.loadingResponseDocs = true;
			this.mfilesService.getBidResponseDocs(this.bidRequest, this.bid).subscribe({
				next: (response: any) => {
					this.bidResponseDocs = response.bidResponseDocuments;

					this.bidResponseDocs.forEach((doc) => {
						doc.downloading = false;
						doc.percentDownloaded = 0;
						doc.cancelDownloadSubject = new Subject<boolean>();
					});
				},
				error: (err) => {
					console.error(err);
					this.loadingResponseDocs = false;
					this.responseDocsLoaded = true;
				},
				complete: () => {
					this.loadingResponseDocs = false;
					this.responseDocsLoaded = true;
					// FH-3443 - Update OverviewMessage, since it is possible that it may change with the number of response docs.
					this.updateOverviewMessage();
				}
			});
		}
	}

	/**
	 * Retrieves the bid project.
	 */
	private getProject() {
		this.loadingProject = true;
		this.mfilesService.getBidProject(this.bidRequest, this.bid).subscribe({
			next: (response: any) => {
				this.project = response.project;
			},
			error: (err) => {
				console.error(err);
				this.loadingProject = false;
			},
			complete: () => {
				this.loadingProject = false;
			}
		});
	}

	goBack() {
		this.location.back();
	}

	fileClicked(obj: MFilesItem, type: string, index?: number) {
		if (index > -1) {
			this.selectedIndex = index;
		} else {
			this.selectedIndex = -1;
		}
		this.selectedType = type;

		if (type === 'communication' && index > -1) {
			this.toggleCorrespondenceRow(index);

			if (this.bidCommunications[index].isOpen) {
				this.getCommunicationProperties(obj.objVer.type, obj.objVer.id, -1);
			}
		}

		const context = {
			itemId: obj.objVer.id,
			type: obj.objVer.type,
			fileIndex: 0
		};

		let triggerModal = false;
		if (!this.modalRef && this.selectedType !== 'communication') {
			triggerModal = true;
		}

		if (triggerModal) {
			this.openModal(this.viewerModal, 'lg bid-request');
		} else {
			this.pageService.documentSelectionOccurred.emit(context);
		}

		if (type && this.authService.isExternalUser() && (type === 'communication' || type === 'package-doc') && obj.viewed === false) {
			this.markObjectAsViewed(obj);
		}

		setTimeout(() => {
			this.documentViewed = true;
			if (triggerModal) {
				this.pageService.documentSelectionOccurred.emit(context);
			}
		}, 500);
	}

	navChanged(value: string) {
		this.currTab = value;
		if (value !== 'overview') {
			this.documentViewed = false;
		}
	}

	prevFileClicked() {
		if (this.selectedType === 'package-doc') {
			if (this.selectedIndex > 0) {
				const newIndex = this.selectedIndex - 1;
				this.fileClicked(this.bidDocuments[newIndex], 'package-doc', newIndex);
			}
		} else if (this.selectedType === 'response-doc') {
			if (this.selectedIndex > 0) {
				const newIndex = this.selectedIndex - 1;
				this.fileClicked(this.bidResponseDocs[newIndex], 'response-doc', newIndex);
			}
		}
	}

	nextFileClicked() {
		if (this.selectedType === 'package-doc') {
			if (this.selectedIndex <= this.bidDocuments.length) {
				const newIndex = this.selectedIndex + 1;
				this.fileClicked(this.bidDocuments[newIndex], 'package-doc', newIndex);
			}
		} else if (this.selectedType === 'response-doc') {
			if (this.selectedIndex <= this.bidDocuments.length) {
				const newIndex = this.selectedIndex + 1;
				this.fileClicked(this.bidResponseDocs[newIndex], 'response-doc', newIndex);
			}
		}
	}

	private markObjectAsViewed(obj: MFilesItem) {
		obj.viewed = true;

		this.mfilesService.getSingleProperty(this.propertyDefIds[Constants.VIEWED_BY_PROPERTY], obj.objVer.type, obj.objVer.id, -1).subscribe((prop: MFilesProperty) => {
			if (prop === null) {
				const viewedByProp = this.vaultProperties.find(prop => prop.id === this.propertyDefIds[Constants.VIEWED_BY_PROPERTY]);
				prop = new MFilesProperty(viewedByProp.id, viewedByProp.dataType);
			}

			if (prop.typedValue.dataType === 9) {
				const lookup: Lookup = new Lookup(this.currentUserId);
				lookup.displayValue = this.authService.getActiveUsername();
				prop.typedValue.lookup = lookup;
				prop.typedValue.serializedValue = this.currentUserId + '';
			} else if (prop.typedValue.dataType === 10) {
				const lookup: Lookup = new Lookup(this.currentUserId);
				lookup.displayValue = this.authService.getActiveUsername();
				prop.typedValue.lookups.push(lookup);

				prop.typedValue.serializedValue = prop.typedValue.serializedValue + '%2C' + this.currentUserId;
			}

			// Add the current user to the TI.MConnect.Property.ViewedBy property on the object
			this.mfilesService.updateSinglePropertyAdmin({ PropertyDef: prop.propertyDef, TypedValue: prop.typedValue }, obj.objVer.type, obj.objVer.id).subscribe(() => { });
		});
	}

	docChecked(doc: MFilesItem, event: any) {
		doc.checkedForDownload = event.target.checked;
	}

	checkAll(event: any) {
		if (this.currTab === 'bidDocs') {
			for (const doc of this.bidDocuments) {
				doc.checkedForDownload = event.target.checked;
			}
		} else if (this.currTab === 'responseDocs') {
			for (const doc of this.bidResponseDocs) {
				doc.checkedForDownload = event.target.checked;
			}
		}
	}

	download(doc: MFilesItem, index?: number) {
		if (index > -1) {
			this.loadingDownloadIndex = index;
		}

		if (!doc.viewed) {
			this.markObjectAsViewed(doc);
		}

		this.downloadReportProgress(doc);
	}

	downloadSelected() {
		this.loadingDownloadSelected = true;
		const docsToDownload: MFilesItem[] = [];

		if (this.currTab === 'bidDocs') {
			for (const doc of this.bidDocuments) {
				if (doc.checkedForDownload) {
					docsToDownload.push(doc);
				}
			}
		} else if (this.currTab === 'responseDocs') {
			for (const doc of this.bidResponseDocs) {
				if (doc.checkedForDownload) {
					docsToDownload.push(doc);
				}
			}
		}

		if (docsToDownload.length === 0) {
			this.toastr.info('Please select one or more documents to download.');
			this.loadingDownloadSelected = false;
		} else if (docsToDownload.length === 1) {
			this.downloadReportProgress(docsToDownload[0]);
			this.loadingDownloadSelected = false;
		} else if (docsToDownload.length > 1) {
			const docObjs: any[] = [];
			for (const doc of docsToDownload) {
				docObjs.push({ type: doc.objVer.type, id: doc.objVer.id, version: -1 });

				if (!doc.viewed) {
					this.markObjectAsViewed(doc);
				}
			}

			this.downloadMultipleReportProgress(docsToDownload);
			this.loadingDownloadSelected = false;
		}
	}

	downloadAll() {
		this.loadingDownloadAll = true;
		if (this.currTab === 'bidDocs') {
			if (this.bidDocuments.length === 0) {
				this.toastr.info('Please select one or more documents to download.');
				this.loadingDownloadAll = false;
			} else if (this.bidDocuments.length === 1) {
				this.downloadReportProgress(this.bidDocuments[0]);
				this.loadingDownloadAll = false;
			} else if (this.bidDocuments.length > 1) {
				const docObjs: any[] = [];
				for (const doc of this.bidDocuments) {
					docObjs.push({ type: doc.objVer.type, id: doc.objVer.id, version: -1 });

					if (!doc.viewed) {
						this.markObjectAsViewed(doc);
					}
				}

				this.downloadMultipleReportProgress(this.bidDocuments);
				this.loadingDownloadAll = false;
			}
		} else if (this.currTab === 'responseDocs') {
			if (this.bidResponseDocs.length === 0) {
				this.toastr.info('Please select one or more documents to download.');
				this.loadingDownloadAll = false;
			} else if (this.bidResponseDocs.length === 1) {
				this.downloadReportProgress(this.bidResponseDocs[0]);
				this.loadingDownloadAll = false;
			} else if (this.bidResponseDocs.length > 1) {
				const docObjs: any[] = [];
				for (const doc of this.bidResponseDocs) {
					docObjs.push({ type: doc.objVer.type, id: doc.objVer.id, version: -1 });

					if (!doc.viewed) {
						this.markObjectAsViewed(doc);
					}
				}

				this.downloadMultipleReportProgress(this.bidResponseDocs);
				this.loadingDownloadAll = false;
			}
		}
	}

	downloadReportProgress(doc: MFilesItem) {
		doc.downloading = true;

		this.mfilesService.downloadReportProgress(doc.objVer.type, doc.objVer.id, doc.objVer.version, 0).pipe(
			takeUntil(doc.cancelDownloadSubject)
		).subscribe({
			next: (event) => {
				if (event.type === HttpEventType.DownloadProgress) {
					const percentDownloaded: number = (event.loaded / event.total) * 100;
					doc.percentDownloaded = percentDownloaded;
				}
				if (event.type === HttpEventType.Response) {
					saveAs(event.body, event.headers.get('filename'));
				}
			},
			error: (err) => {
				this.toastr.error('An error occurred while downloading ' + doc.title + '. Please try again later.');
				console.error(err);
			},
			complete: () => {
				doc.downloading = false;
				doc.percentDownloaded = 0;
			}
		});
	}

	downloadMultipleReportProgress(docs: MFilesItem[]) {
		const docsCopy = [...docs];
		this.sortByFileSize(docsCopy);

		const observables: Observable<HttpEvent<Blob>>[] = [];
		docsCopy.forEach((doc) => {
			docs.find(item => item.objVer.id === doc.objVer.id).downloading = true;
			observables.push(this.mfilesService.downloadReportProgress(doc.objVer.type, doc.objVer.id, doc.objVer.version, 0));
		});

		const subscriptions = from(observables).pipe(
			mergeMap((observable, i) => {
				return observable.pipe(
					takeUntil(docs.find(item => item.objVer.id === docsCopy[i].objVer.id).cancelDownloadSubject),
					map((event) => [event, docsCopy[i].objVer.id]),
					catchError((err) => {
						console.error(err);
						this.toastr.error('An error occurred while downloading ' + docsCopy[i].title + '. Please try again later.');
						return err;
					})
				);
			}, 3)
		).subscribe({
			next: ([event, id]: [HttpEvent<Blob>, number]) => {
				const doc: MFilesItem = docs.find(doc => doc.objVer.id === id);

				if (event.type === HttpEventType.DownloadProgress) {
					const percentDownloaded: number = (event.loaded / event.total) * 100;
					doc.percentDownloaded = percentDownloaded;
				}
				if (event.type === HttpEventType.Response) {
					saveAs(event.body, event.headers.get('filename'));
					doc.downloading = false;
					doc.percentDownloaded = 0;
				}
			},
			error: (err) => {
				console.error(err);
			},
			complete: () => {
				console.log(subscriptions);
			}
		});
	}

	sortByFileSize(docs: MFilesItem[]) {
		docs.sort((a, b) => {
			if (a !== null && a !== undefined && b !== null && b !== undefined && a.files?.length === 1 && b.files?.length === 1) {
				return a.files[0].size - b.files[0].size;
			}

			return 0;
		});
	}

	cancelDownload(doc: MFilesItem) {
		doc.cancelDownloadSubject.next(true);
		doc.downloading = false;
		doc.percentDownloaded = 0;
	}

	stateButtonClicked(state: any) {
		if (state.commentRequired && state.placeholder) {
			this.selectedState = state;
			this.openModal(this.stateTransitionCommentModal, 'lg');
		} else {
			this.bidRequest.currentState = state.id;

			// When the bid is complete, the user shouldn't be able to upload or delete documents on the Response Documents tab.
			if (state.id === this.workflowStateIds[Constants.BID_REQUEST_COMPLETE_STATE]) {
				this.bidRequestComplete = true;
			}

			// Transition the bid to the next state and reload the next workflow state transitions
			this.loadingStates = true;
			this.mfilesService.updateSingleProperty(39, 9, state.id, this.bidRequest.objVer.type, this.bidRequest.objVer.id).subscribe((result) => {
				this.mfilesService.getWorkflowStateTransitions(this.bidRequest.objVer.type, this.bidRequest.objVer.id, this.bidRequest.currentWorkflow, state.id).subscribe((states) => {
					this.states = states;
					this.updateOverviewMessage();
					this.showOrHideAcceptedTabs();
					this.loadingStates = false;
				});
			});
		}
	}

	updateOverviewMessage() {
		const initialReviewState: WorkflowState = this.states.find((state: any) => state.id === this.workflowStateIds[Constants.BID_REQUEST_INITIAL_REVIEW_STATE]);
		const acceptedState: WorkflowState = this.states.find((state: any) => state.id === this.workflowStateIds[Constants.BID_REQUEST_ACCEPTED_STATE]);
		const declinedState: WorkflowState = this.states.find((state: any) => state.id === this.workflowStateIds[Constants.BID_REQUEST_DECLINED_STATE]);
		const responseCompleteState: WorkflowState = this.states.find((state: any) => state.id === this.workflowStateIds[Constants.BID_REQUEST_COMPLETE_STATE]);

		// If the bid is closed, display the Bid Closed message automatically
		if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_CLOSED_STATE]) {
			this.overviewMessage = this.bidClosedMessage;

		} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_AWARDED_STATE]) {
			// If the bidRequest has been awarded, display the Bid Request Awarded message automatically
			this.overviewMessage = this.bidRequestAwardedMessage;

		} else {
			if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_OPENED_STATE]) {
				if (initialReviewState) {
					if (initialReviewState.name) {
						this.overviewMessage = this.bidRequestOpenedMessage;
					} else {
						console.error('The initialReviewState does not have a name property.');
						this.overviewMessage = this.bidRequestOpenedMessage;
					}
				} else {
					console.error('The initialReviewState is null.');
					this.overviewMessage = this.bidRequestOpenedMessage;
				}
			} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_INITIAL_REVIEW_STATE]) {
				if (acceptedState && declinedState) {
					if (acceptedState.name && declinedState.name) {
						this.overviewMessage = this.bidRequestInitialReviewMessage;
					} else {
						console.error('The acceptedState or declinedState does not have a name property.');
						this.overviewMessage = this.bidRequestInitialReviewMessage;
					}
				} else {
					console.error('The acceptedState or declinedState is null.');
					this.overviewMessage = this.bidRequestInitialReviewMessage;
				}
			} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_ACCEPTED_STATE]) {
				this.overviewMessage = this.bidRequestAcceptedMessage;

			} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_DECLINED_STATE]) {
				this.overviewMessage = this.bidRequestDeclinedMessage;

			}
			// FH-3443 display previous state message even if in BID_REQUEST_DOCS_UPLOADED_STATE when the bidResponseDocs.Length == 0
			// (Since removal of docs does not revert back to previous state). Changes made elsewhere in bid-request.components.ts ensure that this.bidResponseDocs is not empty.
			else if (this.bidRequest.currentState == this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE] && this.bidResponseDocs.length === 0) {
				this.overviewMessage = this.bidRequestAcceptedMessage;
			}
			else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE]) {
				this.overviewMessage = this.bidDocsUploadedMessage;

			} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_COMPLETE_STATE]) {
				this.overviewMessage = this.bidRequestCompleteMessage;

			} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_AWARDED_STATE]) {
				this.overviewMessage = this.bidRequestAwardedMessage;

			} else if (this.bidRequest.currentState === this.workflowStateIds[Constants.BID_REQUEST_REOPENED_STATE]) {
				this.overviewMessage = this.bidRequestReopenedMessage;
			}
		}

		if (this.overviewMessage !== null && this.overviewMessage !== undefined) {
			if (this.overviewMessage.length > 0) {
				this.overviewMessage = this.overviewMessage
					.split('{{bidRequest.className}}').join(this.bidRequest.className)
					.split('{{initialReviewState.name}}').join(initialReviewState ? initialReviewState.name : 'INVALID STATE')
					.split('{{acceptedState.name}}').join(acceptedState ? acceptedState.name : 'INVALID STATE')
					.split('{{declinedState.name}}').join(declinedState ? declinedState.name : 'INVALID STATE')
					.split('{{responseCompleteState.name}}').join(responseCompleteState ? responseCompleteState.name : 'INVALID STATE')
					.split('{{bid.className}}').join(this.bid.className);
			} else {
				this.overviewMessage = 'A message has not been set. Please notify an Administrator.';
				console.error('An overview message for this state has not been configured in the Global Settings.');
			}
		} else {
			this.overviewMessage = 'A message has not been set. Please notify an Administrator.';
			console.error('An overview message for this state has not been configured in the Global Settings.');
		}
	}

	saveResponseDocs(files: FileList) {
		for (let i = 0; i < files.length; i++) {
			this.uploadedResponseDocs.push(files[i]);
		}

		this.openModal(this.uploadDocsModal, 'lg');
	}

	removeResponseDocFile(index: number) {
		this.uploadedResponseDocs.splice(index, 1);
	}

	uploadDocs() {
		this.mfilesService.cancelMicroserviceUpload.subscribe(() => {
			this.microserviceUploadSubscription.unsubscribe();
		});

		this.mfilesService.isMicroserviceAvailable().subscribe(available => {
			if (available) {
				const uploadProps = [];
				let maxConcurrentUploads = 3;
				let filesUploading = 0;
				let totalFiles = this.uploadedResponseDocs.length;

				for (let i = 0; i < this.uploadedResponseDocs.length; i++) {
					const file = this.uploadedResponseDocs[i];

					if (file.size > 0) {
						this.uploadProgressStats[file.name] = 0;	// Init the progress stats for this file while we're iterating through the files
					} else {
						this.uploadProgressStats[file.name] = 'EMPTY';
						this.uploadedResponseDocs.splice(i, 1);		// If the file is empty, remove it and don't try to upload it. A message will be displayed to the user for this file.
						totalFiles--;
						i--;
						continue;
					}

					if (file.size >= (1024 * 1024 * 200)) { // 200MB
						maxConcurrentUploads = 1;
					}

					const responseClassPropsCopy: Property[] = this.clonePropertyArray(this.responseClassProps);
					const properties: Property[] = this.associateBidRequestToDoc(file, this.responseClass, responseClassPropsCopy, this.workflowIds[Constants.BID_RESPONSE_WORKFLOW], this.workflowStateIds[Constants.BID_RESPONSE_STARTING_STATE]);
					uploadProps.push(properties);
				}

				if (uploadProps.length > 0) {
					this.responsesUploading = true;

					this.mfilesService.microserviceUploadProgress = this.uploadProgressStats;

					this.microserviceUploadSubscription = from(uploadProps)
						.pipe(
							tap(() => this.authService.isUploading = true),
							concatMap((props: Property[]) => this.mfilesService.prepareBidResponseCheckin(this.bidRequestId, props)),
							mergeMap((result, index) => {
								filesUploading += 1;
								this.uploadProgressMessage = `Uploading ${filesUploading}/${totalFiles}`;

								return this.mfilesService.microserviceUpload(result, this.uploadedResponseDocs[index], false, false, -1, -1, true)
									.catch(err => {
										if (err.file) {
											this.uploadProgressStats[err.file.name] = 'ERROR';
										}
										return EMPTY;
									});
							}, maxConcurrentUploads),
							mergeMap((result) => result, 1),
							toArray(),
							tap(() => this.uploadProgressMessage = 'Finalizing'),
							mergeMap((uploadResults) => this.mfilesService.postBidResponsesCheckin(uploadResults).pipe(
								catchError(err => {
									this.toastr.error('An error occurred while attempting to upload your files into M-Files. Please try again later.');
									return EMPTY;
								})
							), 1),
							tap(() => {
								this.authService.isUploading = false;
								this.mfilesService.emitUploadProgress('all', 100);
								this.uploadProgressMessage = 'Complete';
							})
						)
						.subscribe({
							next: (res: MFilesItem[]) => {
								if (this.bidRequest.currentState !== this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE]) {
									this.mfilesService.updateSingleProperty(39, 9, this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE], this.bidRequest.objVer.type, this.bidRequest.objVer.id).subscribe(() => {
										this.bidRequest.currentState = this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE];

										this.mfilesService.getWorkflowStateTransitions(this.bidRequest.objVer.type, this.bidRequest.objVer.id, this.bidRequest.currentWorkflow, this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE]).subscribe(states => {
											this.states = states;
											this.updateOverviewMessage();
										});
									});
								}

								this.bidResponseDocs.push(...res);
								// FH-3443 - Update overview message, since message may depend on number of bid response docs.
								this.updateOverviewMessage();
							},
							error: (err) => {
								console.error(err);
								this.responsesUploading = false;
								this.responseDocsUploaded = false;
								this.toastr.error('An error occurred internally and your responses could not be uploaded.');
							},
							complete: () => {
								this.uploadProgressStats = {};
								this.mfilesService.microserviceUploadProgress = {};

								this.responsesUploading = false;
								this.responseDocsUploaded = true;
								this.numResponseDocsUploaded = 0;
								setTimeout(() => {
									this.closeUploadResponseDocsModal();
								}, 2000);
							}
						});
				}
			} else {
				const uploadObservables: Observable<Object>[] = [];

				for (let i = 0; i < this.uploadedResponseDocs.length; i++) {
					const file = this.uploadedResponseDocs[i];
					const responseClassPropsCopy: Property[] = this.clonePropertyArray(this.responseClassProps);
					const properties: Property[] = this.associateBidRequestToDoc(file, this.responseClass, responseClassPropsCopy, this.workflowIds[Constants.BID_RESPONSE_WORKFLOW], this.workflowStateIds[Constants.BID_RESPONSE_STARTING_STATE]);
					uploadObservables.push(this.mfilesService.uploadBidResponseDoc(properties, file, this.bidRequestId));
				}

				if (uploadObservables.length > 0) {
					this.responsesUploading = true;
					forkJoin(uploadObservables).subscribe({
						next: (result: MFilesItem[]) => {
							if (this.bidRequest.currentState !== this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE]) {
								this.mfilesService.updateSingleProperty(39, 9, this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE], this.bidRequest.objVer.type, this.bidRequest.objVer.id).subscribe(() => {
									this.bidRequest.currentState = this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE];

									this.mfilesService.getWorkflowStateTransitions(this.bidRequest.objVer.type, this.bidRequest.objVer.id, this.bidRequest.currentWorkflow, this.workflowStateIds[Constants.BID_REQUEST_DOCS_UPLOADED_STATE]).subscribe(states => {
										this.states = states;
										this.updateOverviewMessage();
									});
								});
							}

							this.bidResponseDocs.push(...result);
							// FH-3443 - Update overview message, since message may depend on number of bid response docs.
							this.updateOverviewMessage();
							this.responsesUploading = false;
							this.responseDocsUploaded = true;

							setTimeout(() => {
								this.closeUploadResponseDocsModal();
							}, 2000);
						},
						error: (err) => {
							console.error(err);
							this.responsesUploading = false;
							this.responseDocsUploaded = false;
							this.toastr.error('An error occurred internally and your responses could not be uploaded.');
						}
					});
				}
			}
		});
	}

	clonePropertyArray(responseClassProps: Property[]): Property[] {
		const copy: Property[] = [];

		for (const prop of responseClassProps) {
			copy.push({ ...prop });
		}

		return copy;
	}

	associateBidRequestToDoc(file: File, objClass: any, objClassProps: Property[], workflowId?: number, stateId?: number): Property[] {
		let associatedUploadPropertyAdded: boolean = false;

		for (const classProp of objClassProps) {
			if (classProp.id === objClass.namePropertyDef) {
				classProp.value = this.removeExtension(file.name);
			} else if (classProp.id > 999) {
				if (classProp.id === this.propertyDefIds[Constants.ASSOCIATED_UPLOAD_PROPERTY]) {
					classProp.value = this.bidRequestId + '';
					associatedUploadPropertyAdded = true;
				} else {
					const bidRequestPropIndex: number = this.bidRequest.properties.findIndex(prop => prop.propertyDef === classProp.id);
					if (bidRequestPropIndex > -1) {
						const bidRequestProp: MFilesProperty = this.bidRequest.properties[bidRequestPropIndex];

						if (classProp.dataType === 9 && bidRequestProp.typedValue.hasValue) {
							classProp.value = bidRequestProp.typedValue.serializedValue;
						} else if (classProp.dataType === 10 && bidRequestProp.typedValue.hasValue) {
							classProp.value = bidRequestProp.typedValue.serializedValue.replace('%2C', ',');
						} else {
							classProp.value = bidRequestProp.typedValue.value;
						}
					}
				}
			}
		}

		if (!associatedUploadPropertyAdded) {
			this.addAssociatedUploadProperty(objClassProps);
		}
		if (workflowId !== -1 && stateId !== -1) {
			this.addWorkflowProperty(objClassProps, workflowId);
			this.addStateProperty(objClassProps, stateId);
		}

		return objClassProps;
	}

	removeExtension(filename: string): string {
		const extStart = filename.lastIndexOf('.');
		return filename.slice(0, extStart);
	}

	deleteBidResponseDoc(doc: MFilesItem, index: number) {
		this.deletingResponseDoc = true;
		this.mfilesService.deleteObject(doc.objVer.type, doc.objVer.id).subscribe(response => {
			this.bidResponseDocs.splice(index, 1);

			if (doc.files[0]) {
				this.toastr.success(`${doc.files[0].name}.${doc.files[0].extension} has been deleted.`);
				// FH-3443 - Update overview message, since message may depend on number of bid response docs.
				this.updateOverviewMessage();
			}
			this.deletingResponseDoc = false;
		});
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ backdrop: 'static', class: 'modal-' + size, animated: false }
		);
	}

	closeUploadResponseDocsModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}

		setTimeout(() => {
			this.uploadedResponseDocs = [];
			this.responseDocsUploaded = false;
		}, 1000);
	}

	closeMessageCreationModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}

		setTimeout(() => {
			this.inboundBidMessage = new InboundBidMessage();
			this.communicationUploaded = false;
		}, 1000);
	}

	closeTransitionCommentModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}

		setTimeout(() => {
			this.selectedState = null;
			this.transitionComment = '';
			this.transitionCommentSent = false;
		}, 1000);
	}

	closeViewerModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}
	}

	addFilesToMessage(files: File[]) {
		const fileBuffer = [];
		Array.prototype.push.apply(fileBuffer, this.inboundBidMessage.files);
		for (let file of files) {
			fileBuffer.push(file);
		}
		this.inboundBidMessage.files = fileBuffer;
	}

	getCommunicationProperties(type: number, itemId: number, revision: number) {
		this.loadingProperties = true;
		this.properties = [];

		this.mfilesService.getProperties(type, itemId, revision).subscribe((response) => {
			this.properties = response.body.properties;
			this.loadingProperties = false;
		});
	}

	clearMessageAttachments() {
		const fileBuffer = [];
		this.inboundBidMessage.files = fileBuffer;
	}

	clearMessageAttachmentsArrayHandler(event) {
		event.srcElement.value = null;
	}

	removeMessageAttachment(index: number): void {
		const fileBuffer = [];
		Array.prototype.push.apply(fileBuffer, this.inboundBidMessage.files);
		fileBuffer.splice(index, 1);

		this.inboundBidMessage.files = fileBuffer;
	}

	setCommercialConfidence(checked: boolean) {
		this.inboundBidMessage.commercialConfidence = checked;
	}

	isValidMessage(): boolean {
		if (this.inboundBidMessage.message !== null && this.inboundBidMessage.message !== undefined) {
			if (this.inboundBidMessage.message.length > 0) {
				if (this.inboundBidMessage.message.match(/^\s*$/) === null) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	isValidTransitionComment(): boolean {
		if (this.transitionComment !== null && this.transitionComment !== undefined) {
			if (this.transitionComment.length > 0) {
				if (this.transitionComment.match(/^\s*$/) === null) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	sendMessage() {
		this.mfilesService.cancelMicroserviceUpload.subscribe(() => {
			this.microserviceUploadSubscription.unsubscribe();
		});

		this.mfilesService.isMicroserviceAvailable().subscribe(available => {
			if (available) {
				const communicationClassPropsCopy: Property[] = this.clonePropertyArray(this.communicationClassProps);
				const attachmentClassPropsCopy: Property[] = this.clonePropertyArray(this.communicationAttachmentClassProps);

				const messageProperties: Property[] = this.associateBidRequestToMessage(this.communicationClass, communicationClassPropsCopy);

				if (this.inboundBidMessage.files.length > 0 && this.isValidMessage()) {
					let maxConcurrentUploads = 3;
					let filesUploading = 0;
					let totalFiles = this.inboundBidMessage.files.length;

					const attachmentProperties: Property[][] = [];
					for (let i = 0; i < this.inboundBidMessage.files.length; i++) {
						const file = this.inboundBidMessage.files[i];

						if (file.size > 0) {
							this.uploadProgressStats[file.name] = 0;	// Init the progress stats for this file while we're iterating through the files
						} else {
							this.uploadProgressStats[file.name] = 'EMPTY';
							this.inboundBidMessage.files.splice(i, 1);		// If the file is empty, remove it and don't try to upload it. A message will be displayed to the user for this file.
							totalFiles--;
							i--;
							continue;
						}

						if (file.size >= (1024 * 1024 * 200)) { // 200MB
							maxConcurrentUploads = 1;
						}

						const props: Property[] = this.associateBidRequestToDoc(file, this.communicationAttachmentClass, this.clonePropertyArray(attachmentClassPropsCopy), this.workflowIds[Constants.BID_COMMUNICATION_ATTACHMENT_WORKFLOW], this.workflowStateIds[Constants.BID_COMMUNICATION_ATTACHMENT_STARTING_STATE]);
						attachmentProperties.push(props);
					}

					this.communicationUploading = true;
					this.communicationUploaded = false;
					this.mfilesService.microserviceUploadProgress = this.uploadProgressStats;

					this.mfilesService.createBidCommunication(messageProperties, this.bidRequestId).subscribe({
						next: (result: MFilesItem) => {
							if (result.objVer === null) {
								this.toastr.error('An error occurred while submitting your message. Please try again later.');
								this.communicationUploading = false;
								this.communicationUploaded = false;

								setTimeout(() => {
									this.closeMessageCreationModal();
								}, 2000);
							} else {
								for (let i = 0; i < attachmentProperties.length; i++) {
									const props = attachmentProperties[i];
									const communicationProp = new Property();
									communicationProp.id = this.propertyDefIds[Constants.BID_COMMUNICATION_PROPERTY];
									communicationProp.dataType = 10;
									communicationProp.value = result.objVer.id + '';
									props.push(communicationProp);
								}

								this.microserviceUploadSubscription = from(attachmentProperties)
									.pipe(
										tap(() => this.authService.isUploading = true),
										concatMap((props: Property[]) => this.mfilesService.prepareBidCommunicationAttachmentCheckin(this.bidRequestId, props)),
										mergeMap((result, index) => {
											filesUploading += 1;
											this.uploadProgressMessage = `Uploading ${filesUploading}/${totalFiles}`;

											return this.mfilesService.microserviceUpload(result, this.inboundBidMessage.files[index], false, false, -1, -1, true)
												.catch(err => {
													if (err.file) {
														this.uploadProgressStats[err.file.name] = 'ERROR';
													}
													return EMPTY;
												})
										}, maxConcurrentUploads),
										mergeMap((result) => result, 1),
										toArray(),
										tap(() => this.uploadProgressMessage = 'Finalizing'),
										mergeMap((uploadResults) => this.mfilesService.postBidCommunicationAttachmentCheckin(uploadResults).pipe(
											catchError(err => {
												this.toastr.error('An error occurred while attempting to upload your attachments for this message into M-Files.');
												return EMPTY;
											})
										), 1),
										tap(() => {
											this.authService.isUploading = false;
											this.mfilesService.emitUploadProgress('all', 100);
											this.uploadProgressMessage = 'Complete';
										})
									)
									.subscribe({
										next: (res: MFilesItem[]) => {
											result.attachments.push(...res);
										},
										error: (err) => {
											console.error(err);
											this.communicationUploading = false;
											this.communicationUploaded = false;
											this.toastr.error('An error occurred internally and your message could not be submitted.');
										},
										complete: () => {
											this.uploadProgressStats = {};
											this.mfilesService.microserviceUploadProgress = {};

											this.communicationUploading = false;
											this.communicationUploaded = true;
											this.bidCommunications.push(result);

											setTimeout(() => {
												this.closeMessageCreationModal();
											}, 2000);
										}
									});
							}
						},
						error: (err) => {
							console.error(err);
							this.communicationUploading = false;
							this.communicationUploaded = false;
							this.toastr.error('An error occurred internally and your message was not received.');
						}
					});
				} else {
					if (this.isValidMessage) {
						this.communicationUploading = true;
						this.communicationUploaded = false;
						this.mfilesService.createBidCommunication(messageProperties, this.bidRequestId).subscribe({
							next: (result: MFilesItem) => {
								if (result.objVer === null) {
									this.toastr.error('An error occurred while submitting your message. Please try again later.');
									this.communicationUploading = false;
									this.communicationUploaded = false;
								} else {
									this.bidCommunications.push(result);
									this.communicationUploading = false;
									this.communicationUploaded = true;
								}

								setTimeout(() => {
									this.closeMessageCreationModal();
								}, 2000);
							},
							error: (err) => {
								console.error(err);
								this.communicationUploading = false;
								this.communicationUploaded = false;
								this.toastr.error('An error occurred internally and your message was not received.');
							}
						});
					}
				}
			} else {
				const communicationClassPropsCopy: Property[] = this.clonePropertyArray(this.communicationClassProps);
				const attachmentClassPropsCopy: Property[] = this.clonePropertyArray(this.communicationAttachmentClassProps);

				const messageProperties: Property[] = this.associateBidRequestToMessage(this.communicationClass, communicationClassPropsCopy);

				if (this.inboundBidMessage.files.length > 0) {
					const attachmentProperties: Property[][] = [];
					for (const file of this.inboundBidMessage.files) {
						const props: Property[] = this.associateBidRequestToDoc(file, this.communicationAttachmentClass, this.clonePropertyArray(attachmentClassPropsCopy), this.workflowIds[Constants.BID_COMMUNICATION_ATTACHMENT_WORKFLOW], this.workflowStateIds[Constants.BID_COMMUNICATION_ATTACHMENT_STARTING_STATE]);
						attachmentProperties.push(props);
					}

					this.communicationUploading = true;
					this.communicationUploaded = false;
					this.mfilesService.createBidCommunicationWithAttachments(messageProperties, this.inboundBidMessage.files, attachmentProperties, this.bidRequestId).subscribe({
						next: (result: MFilesItem) => {
							if (result.objVer === null) {
								this.toastr.error('An error occurred while submitting your message. Please try again later.');
								this.communicationUploading = false;
								this.communicationUploaded = false;
							} else {
								this.bidCommunications.push(result);
								this.communicationUploading = false;
								this.communicationUploaded = true;
							}

							setTimeout(() => {
								this.closeMessageCreationModal();
							}, 2000);
						},
						error: (err) => {
							console.error(err);
							this.communicationUploading = false;
							this.communicationUploaded = false;
							this.toastr.error('An error occurred internally and your message was not received.');
						}
					});
				} else {
					if (this.isValidMessage) {
						this.communicationUploading = true;
						this.communicationUploaded = false;
						this.mfilesService.createBidCommunication(messageProperties, this.bidRequestId).subscribe({
							next: (result: MFilesItem) => {
								if (result.objVer === null) {
									this.toastr.error('An error occurred while submitting your message. Please try again later.');
									this.communicationUploading = false;
									this.communicationUploaded = false;
								} else {
									this.bidCommunications.push(result);
									this.communicationUploading = false;
									this.communicationUploaded = true;
								}

								setTimeout(() => {
									this.closeMessageCreationModal();
								}, 2000);
							},
							error: (err) => {
								console.error(err);
								this.communicationUploading = false;
								this.communicationUploaded = false;
								this.toastr.error('An error occurred internally and your message was not received.');
							}
						});
					}
				}
			}
		});
	}

	private addAssociatedUploadProperty(propsToUpload: Property[]) {
		if (this.propertyDefIds[Constants.ASSOCIATED_UPLOAD_PROPERTY] !== -1) {
			const associatedUploadProp: Property = new Property();
			associatedUploadProp.id = this.propertyDefIds[Constants.ASSOCIATED_UPLOAD_PROPERTY];
			associatedUploadProp.dataType = 9;
			associatedUploadProp.value = this.bidRequest.objVer.id + '';
			propsToUpload.push(associatedUploadProp);
		}
	}

	associateBidRequestToMessage(objClass: any, objClassProps: Property[]): Property[] {
		let associatedUploadPropertyAdded = false;
		let commercialInConfidencePropertyAdded = false;

		for (const classProp of objClassProps) {
			if (classProp.id === objClass.namePropertyDef) {
				classProp.value = this.inboundBidMessage.message;
			} else if (classProp.id > 999) {
				if (classProp.id === this.propertyDefIds[Constants.ASSOCIATED_UPLOAD_PROPERTY]) {
					classProp.value = this.bidRequestId + '';
					associatedUploadPropertyAdded = true;
				} else if (this.publicMessagesEnabled && this.hasCommercialInConfidence && classProp.id === this.propertyDefIds[Constants.IS_PRIVATE_PROPERTY]) {
					classProp.value = this.inboundBidMessage.commercialConfidence + '';
					commercialInConfidencePropertyAdded = true;
				} else {
					const bidRequestPropIndex: number = this.bidRequest.properties.findIndex(prop => prop.propertyDef === classProp.id);
					if (bidRequestPropIndex > -1) {
						const bidpackageProp: MFilesProperty = this.bidRequest.properties[bidRequestPropIndex];

						if (classProp.dataType === 9 && bidpackageProp.typedValue.hasValue) {
							classProp.value = bidpackageProp.typedValue.serializedValue;
						} else if (classProp.dataType === 10 && bidpackageProp.typedValue.hasValue) {
							classProp.value = bidpackageProp.typedValue.serializedValue.replace('%2C', ',');
						} else {
							classProp.value = bidpackageProp.typedValue.value;
						}
					}
				}
			}
		}

		if (!associatedUploadPropertyAdded) {
			this.addAssociatedUploadProperty(objClassProps);
		}
		if (this.publicMessagesEnabled && !commercialInConfidencePropertyAdded && this.hasCommercialInConfidence && this.inboundBidMessage.commercialConfidence) {
			this.addCICProperty(objClassProps);
		}
		if (this.workflowIds[Constants.INBOUND_BID_MESSAGE_WORKFLOW] !== -1 && this.workflowStateIds[Constants.INBOUND_BID_MESSAGE_STARTING_STATE] !== -1) {
			this.addWorkflowProperty(objClassProps, this.workflowIds[Constants.INBOUND_BID_MESSAGE_WORKFLOW]);
			this.addStateProperty(objClassProps, this.workflowStateIds[Constants.INBOUND_BID_MESSAGE_STARTING_STATE]);
		}

		return objClassProps;
	}

	private addWorkflowProperty(propsToUpload: Property[], workflowId: number) {
		const workflow: Property = new Property();
		workflow.id = 38;
		workflow.dataType = 9;
		workflow.value = workflowId + '';
		propsToUpload.push(workflow);
	}

	private addStateProperty(propsToUpload: Property[], stateId: number) {
		const state: Property = new Property();
		state.id = 39;
		state.dataType = 9;
		state.value = stateId + '';
		propsToUpload.push(state);
	}

	private addCICProperty(propsToUpload: Property[]) {
		if (this.propertyDefIds[Constants.IS_PRIVATE_PROPERTY] !== -1) {
			const cic: Property = new Property();
			cic.id = this.propertyDefIds[Constants.IS_PRIVATE_PROPERTY];
			cic.dataType = 8;
			cic.value = this.inboundBidMessage.commercialConfidence + '';
			propsToUpload.push(cic);
		}
	}

	finishStateTransition() {
		const props: Property[] = [];

		const versionComment: Property = new Property();
		versionComment.id = 33;
		versionComment.dataType = 13;
		versionComment.value = this.transitionComment;
		props.push(versionComment);

		const state: Property = new Property();
		state.id = 39;
		state.dataType = 9;
		state.value = this.selectedState.id;
		props.push(state);

		this.sendingTransitionComment = true;
		this.mfilesService.updateObject(this.bidRequest.objVer.type, this.bidRequest.objVer.id, props).subscribe({
			next: () => {
				this.bidRequest.currentState = this.selectedState.id;
				this.mfilesService.getWorkflowStateTransitions(this.bidRequest.objVer.type, this.bidRequest.objVer.id, this.bidRequest.currentWorkflow, this.selectedState.id).subscribe((states) => {
					this.states = states;
					this.updateOverviewMessage();
					this.sendingTransitionComment = false;
					this.transitionCommentSent = true;

					this.closeTransitionCommentModal();
				});
			},
			error: (err) => {
				console.error(err);
				this.sendingTransitionComment = false;
				this.transitionCommentSent = false;
				this.toastr.error(`An error occurred internally and this action could not be completed at this time.`);
			}
		});
	}

	toggleCorrespondenceRow(index: number): void {
		this.bidCommunications[index].isOpen = !this.bidCommunications[index].isOpen;

		// I hate this
		for (let i = 0; i < this.bidCommunications.length; i++) {
			if (i !== index) {
				this.bidCommunications[i].isOpen = false;
			}
		}
	}

	openRequestToMakePublicModal(index: number) {
		this.selectedCommunication = this.bidCommunications[index];
		const reasonToMakePublicProp: MFilesProperty = this.selectedCommunication.properties.find(prop => prop.propertyDef === this.propertyDefIds[Constants.REASON_TO_MAKE_PUBLIC]);
		this.reasonToMakePublic = reasonToMakePublicProp.typedValue.value;

		this.openModal(this.requestToMakePublicModal, 'lg');
	}

	approveCICCommunication() {
		this.selectedCommunication.currentState = this.workflowStateIds[Constants.BID_COMMUNICATION_APPROVE_TO_MAKE_PUBLIC_STATE];

		this.cicUpdating = true;
		this.mfilesService.updateSingleProperty(39, 9, this.workflowStateIds[Constants.BID_COMMUNICATION_APPROVE_TO_MAKE_PUBLIC_STATE], this.selectedCommunication.objVer.type, this.selectedCommunication.objVer.id).subscribe({
			next: () => {
				this.selectedCommunication.isRequestToMakePublic = false;
				this.cicUpdating = false;
				this.cicUpdated = true;

				setTimeout(() => {
					this.closeRequestToMakePublicModal();
				}, 2000);
			},
			error: (err) => {
				console.error(err);
				this.cicUpdating = false;
			}
		});
	}

	declineCICCommunication() {
		this.selectedCommunication.currentState = this.workflowStateIds[Constants.BID_COMMUNICATION_DECLINE_TO_MAKE_PUBLIC_STATE];

		this.cicUpdating = true;
		this.mfilesService.updateSingleProperty(39, 9, this.workflowStateIds[Constants.BID_COMMUNICATION_DECLINE_TO_MAKE_PUBLIC_STATE], this.selectedCommunication.objVer.type, this.selectedCommunication.objVer.id).subscribe({
			next: () => {
				this.selectedCommunication.isRequestToMakePublic = false;
				this.cicUpdating = false;
				this.cicUpdated = true;

				setTimeout(() => {
					this.closeRequestToMakePublicModal();
				}, 2000);
			},
			error: (err) => {
				console.error(err);
				this.cicUpdating = false;
			}
		});
	}

	closeRequestToMakePublicModal() {
		if (this.modalRef) {
			this.modalRef.hide();
		}

		setTimeout(() => {
			this.selectedCommunication = null;
			this.reasonToMakePublic = '';
			this.cicUpdated = false;
			this.cicUpdating = false;
		}, 1000);
	}
}

