import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	public navigationPanelExpandedChanged = new Subject();
	public deviceType: string;
	public screenWidth: number;
	public screenWidthChanged = new Subject<number>();
	public screenHeightChanged = new Subject();
	public deviceTypeChanged = new Subject<string>();

	constructor() { }

	determineScreenSize() {
		this.screenWidth = document.getElementById('navbar-container').getBoundingClientRect().width;
		this.setDeviceType();

		// Listening to window:onresize event in Header component
		this.screenWidthChanged.subscribe(size => {
			this.screenWidth = size;
			this.setDeviceType();
		});
	}

	setDeviceType() {
		if (this.screenWidth >= 1024) {    // Desktop
			this.deviceType = 'desktop';
		} else if (this.screenWidth < 1024 && this.screenWidth >= 480) {   // Tablet
			this.deviceType = 'tablet';
		} else if (this.screenWidth < 480) {   // Mobile
			this.deviceType = 'mobile';
		}

		this.deviceTypeChanged.next(this.deviceType);
	}

	setNavigationPanelExpanded(value: boolean) {
		this.navigationPanelExpandedChanged.next(value);
	}
}
