import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PageReorder } from '../models/page-reorder.model';
import { Page } from '../models/page.model';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { LayoutService } from '../services/layout.service';
import { MFilesService } from '../services/mfiles.service';
import { PageService } from '../services/page.service';
import { ShareLinkService } from '../services/share-link.service';
import { UserConfigurationService } from '../services/user-configuration.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('headerItems', { read: ElementRef }) public headerItems: ElementRef<any>;
	@ViewChildren('headerItems') public headerChildren: QueryList<any>;
	@ViewChild('collapsePagelistButton') collapsePagelistButton: ElementRef<any>;
	@ViewChild('settingsDropdown') public settingsDropdown: ElementRef<any>;
	@ViewChild('credentialsDialog', { static: true }) credentialsDialog: TemplateRef<any>;
	@ViewChild('debugDialog', { static: true }) debugDialog: TemplateRef<any>;

	modalRef: BsModalRef;

	pagesSubscription: Subscription;
	pages: Page[];
	showItemIndicator = false;

	navScrollPosition = 0;
	navScrollLeft = false;
	navScrollRight = false;

	recentEnabledSubscription: Subscription;
	recentEnabled = false;

	favoritesEnabledSubscription: Subscription;
	favoritesEnabled = false;

	fileShareEnabledSubscription: Subscription;
	fileShareEnabled = false;

	avatarSubscription: Subscription;
	avatarsEnabledSubscription: Subscription;
	avatarsEnabled = false;

	primaryColor = '';
	secondaryColor = '';
	titleColor = '';
	linksColor = '';

	siteLogoChangedSubscription: Subscription;
	logoUrl: SafeUrl;

	siteNameChangedSubscription: Subscription;
	siteName = '';

	displaySiteNameInHeaderChangedSubscription: Subscription;
	displaySiteNameInHeader: boolean;

	siteFaviconChangedSubscription: Subscription;
	favicon: HTMLElement;

	pageEditModeSubscription: Subscription;
	pageEdit: boolean;
	pageEditId: any;

	externalUserRightsChangedSubscription: Subscription;

	routerSubscription: Subscription;

	previousIndex: number;
	currentIndex: number;

	timerRunning = false;
	idleTimer = 900;
	idleGracePeriod = 120;
	autoSaveFrequency = 300;
	timeoutTime: number;
	pingSubscription: Subscription;

	pagelistCollapsed = true;
	adminDropdownCollapsed = true;

	activeWorkspace = '';
	initialWorkspace = {
		id: 1,
		name: '',
		endpoint: '',
		vaultGuid: '',
		username: '',
		password: ''
	}
	intialCreateMessage = '';
	workspaces = [];

	avatarUrl: SafeUrl;
	avatarUpload: File;

	navigationPanelExpanded = true;

	loadingDebug = false;
	debugTime: Date;
	userId: number;

	constructor(
		public authService: AuthService,
		private pageService: PageService,
		private router: Router,
		private configService: ConfigurationService,
		private userConfigService: UserConfigurationService,
		private domSanitizer: DomSanitizer,
		private userIdle: UserIdleService,
		private mfilesService: MFilesService,
		private titleService: Title,
		private modalService: BsModalService,
		private shareLinkService: ShareLinkService,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private layoutService: LayoutService,
		private toastr: ToastrService
	) { }

	/**
	 * 1. Check if vault exists.
	 * 2. Check if the user is authenticated.
	 * 3. Subscribe to the idle timer.
	 * 4. ...
	 */
	ngOnInit() {
		this.mfilesService.vaultExists().subscribe((exists) => {
			if (!exists) {
				this.modalRef = this.modalService.show(
					this.credentialsDialog,
					{ class: 'modal-lg', backdrop: 'static' }
				);
			}
		});

		if (this.authService.isAuthenticated()) {
			this.timerRunning = true;
			this.userIdle.startWatching();
			this.checkIfAdmin();
		}

		this.userIdle.onTimerStart().subscribe((count) => {
			this.timeoutTime = count;
		});

		this.userIdle.onTimeout().subscribe(() => {
			if (this.authService.isAuthenticated()) {
				this.timerRunning = false;
				this.authService.logout();
				this.router.navigate(['/login']);
				location.reload();
			}
		});

		this.initializeHeader();

		this.authService.activeUserChanged.subscribe((result) => {
			this.initializeHeader();

			this.timerRunning = true;
			this.userIdle.startWatching();
		});

		this.authService.logoutOccurred.subscribe((result) => {
			this.pageEdit = false;
			this.pageEditId = '';
		});

		this.externalUserRightsChangedSubscription = this.pageService.changeExternalUserRightsOccurred.subscribe(rights => {
			this.pages.forEach(page => {
				if (page.id === this.pageEditId) {
					page.settings.externalUserRights = rights;
				}
			});
		});

		this.routerSubscription = this.route.queryParams.subscribe((params: Params) => {
			const workspace = params['workspace'];
			if (workspace && workspace !== this.authService.getToken('workspace')) {
				this.openWorkspace(workspace);
			}
		});

		this.activeWorkspace = this.authService.getToken('workspace');
		if (!this.activeWorkspace) {
			this.activeWorkspace = '1';
		}
	}

	ngOnDestroy() {
		this.pagesSubscription.unsubscribe();
		this.recentEnabledSubscription.unsubscribe();
		this.favoritesEnabledSubscription.unsubscribe();
		this.fileShareEnabledSubscription.unsubscribe();
		this.siteLogoChangedSubscription.unsubscribe();
		this.siteNameChangedSubscription.unsubscribe();
		this.siteFaviconChangedSubscription.unsubscribe();
		this.pageEditModeSubscription.unsubscribe();
		this.externalUserRightsChangedSubscription.unsubscribe();
		this.displaySiteNameInHeaderChangedSubscription.unsubscribe();
		this.routerSubscription.unsubscribe();
		this.avatarSubscription.unsubscribe();
		this.avatarsEnabledSubscription.unsubscribe();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.calcNavScrollArrows();
		}, 250);
	}

	initializeHeader() {
		this.pages = [];
		this.avatarUrl = undefined;

		if (this.authService.isAuthenticated()) {
			this.mfilesService.getWorkspaces().subscribe((result: any) => {
				this.workspaces = result.body;
			});
		}

		this.loadAvatar();

		// reset configs that might not be in the config service list
		this.displaySiteNameInHeader = false;
		this.avatarsEnabled = false;
		this.idleTimer = 900;
		this.idleGracePeriod = 120;
		this.autoSaveFrequency = 300;
		this.logoUrl = undefined;

		this.configService.getImage('siteLogo', parseInt(this.activeWorkspace)).subscribe((imageResult) => {
			if (imageResult.body && imageResult.body.size !== 0) {
				const reader = new FileReader();
				reader.onloadend = (e) => {
					this.logoUrl = this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
				};
				reader.readAsDataURL(imageResult.body);
			}
		});

		this.configService.getImage('siteFavicon', parseInt(this.activeWorkspace)).subscribe((imageResult) => {
			if (imageResult.body && imageResult.body.size !== 0) {
				const reader = new FileReader();
				reader.onloadend = (e) => {
					this.setFavicon(reader.result.toString());
				};
				reader.readAsDataURL(imageResult.body);
			} else {
				this.setFavicon(null);
			}
		});

		this.configService.list(false).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'siteName') {
					this.siteName = config.value !== null ? config.value : '';
					if (this.siteName !== '') {
						this.titleService.setTitle(this.siteName);
					} else {
						this.titleService.setTitle('Page not found');
					}
				} else if (config.name === 'displaySiteNameInHeader') {
					this.displaySiteNameInHeader = config.value.toLowerCase() === 'true';
				} else if (config.name === 'avatarsEnabled') {
					this.avatarsEnabled = config.value.toLowerCase() === 'true';
					this.loadAvatar();
				} else if (config.name === 'primaryColor') {
					this.primaryColor = config.value;
					document.documentElement.style.setProperty('--primary-color', this.primaryColor);
				} else if (config.name === 'secondaryColor') {
					this.secondaryColor = config.value;
					document.documentElement.style.setProperty('--secondary-color', this.secondaryColor);
				} else if (config.name === 'titleColor') {
					this.titleColor = config.value;
					document.documentElement.style.setProperty('--title-color', this.titleColor);
				} else if (config.name === 'linksColor') {
					this.linksColor = config.value;
					document.documentElement.style.setProperty('--links-color', this.linksColor);
				} else if (config.name === 'idleTimer') {
					this.idleTimer = parseInt(config.value, 10) * 60;
				} else if (config.name === 'idleGracePeriod') {
					this.idleGracePeriod = parseInt(config.value, 10) * 60;
				} else if (config.name === 'autoSaveFrequency') {
					this.autoSaveFrequency = parseInt(config.value, 10) * 60;
				} else if (config.name === 'showItemIndicator') {
					this.showItemIndicator = config.value.toLowerCase() === 'true';
				}
			}

			if (this.pingSubscription) {
				this.pingSubscription.unsubscribe();
			}
			if (this.timerRunning) {
				this.userIdle.stopWatching();
				this.userIdle.setConfigValues({
					idle: this.idleTimer,
					timeout: this.idleGracePeriod,
					ping: this.autoSaveFrequency
				});
				this.userIdle.startWatching();
			} else {
				this.userIdle.setConfigValues({
					idle: this.idleTimer,
					timeout: this.idleGracePeriod,
					ping: this.autoSaveFrequency
				});
			}
			this.createPingSubscription();
		});

		if (this.authService.isAuthenticated()) {
			this.pageService.fetchPages().subscribe((pages) => {
				this.pageService.setPagesCache(pages);
			});
		}

		this.configService.get('recentEnabled', parseInt(this.activeWorkspace)).subscribe(config => {
			let recentValue = false;
			if (config.value) {
				if (typeof config.value === 'string') {
					recentValue = JSON.parse(config.value.toLowerCase());
				} else {
					recentValue = config.value;
				}
			}
			this.recentEnabled = recentValue && !this.authService.isExternalUser();
			this.configService.recent = this.recentEnabled;
		});

		this.configService.get('favoriteEnabled', parseInt(this.activeWorkspace)).subscribe(config => {
			let favoriteValue = false;
			if (config.value) {
				if (typeof config.value === 'string') {
					favoriteValue = JSON.parse(config.value.toLowerCase());
				} else {
					favoriteValue = config.value;
				}
			}
			this.favoritesEnabled = favoriteValue && !this.authService.isExternalUser();
			this.configService.favorites = this.favoritesEnabled;
		});

		this.configService.get('fileShareEnabled', parseInt(this.activeWorkspace)).subscribe(config => {
			let fileShareValue = false;
			if (config.value) {
				if (typeof config.value === 'string') {
					fileShareValue = JSON.parse(config.value.toLowerCase());
				} else {
					fileShareValue = config.value;
				}
			}
			this.fileShareEnabled = fileShareValue && !this.authService.isExternalUser();
			this.configService.fileShare = this.fileShareEnabled;
		});

		this.subscribeToPages();
		this.subscribeToRecentEnabledConfig();
		this.subscribeToFavoritesEnabledConfig();
		this.subscribeToFileShareEnabledConfig();
		this.subscribeToAvatarsEnabledConfig();
		this.subscribeToAvatarChanged();
		this.subscribeToSiteLogoChanged();
		this.subscribeToSiteNameChanged();
		this.subscribeToDisplaySiteNameInHeaderChanged();
		this.subscribeToSiteFaviconChanged();
		this.subscribeToPageEditMode();
		this.subscribeToRouteQueryParams();
	}

	createPingSubscription() {
		if (this.userIdle.ping$) {
			this.pingSubscription = this.userIdle.ping$.subscribe(() => {
				if (this.authService.isAuthenticated()) {
					this.mfilesService.ping().subscribe((result) => {
					});
					this.authService.ping();
				}
			});
		}
	}

	renewSession() {
		this.userIdle.resetTimer();
		this.timeoutTime = undefined;
	}

	subscribeToPages() {
		if (!this.pagesSubscription) {
			this.pagesSubscription = this.pageService.pagesChanged.subscribe((pages: Page[]) => {
				this.pages = pages;
				for (const page of this.pages) {
					if (typeof page.settings === 'string') {
						page.settings = JSON.parse(page.settings);
					}
					if (this.showItemIndicator && page.settings.showItemIndicator) {
						this.pageService.fetchPage(page.id, false).subscribe((pageDetails) => {
							if (typeof pageDetails.widgets === 'string') {
								pageDetails.widgets = JSON.parse(pageDetails.widgets);
							}
							for (const widget of pageDetails.widgets) {
								if (widget.type === 'dataview') {
									this.mfilesService.getPageView(widget.data, page.id, widget.id).subscribe((viewData: any) => {
										if (viewData.items.length > 0) {
											page.hasItems = true;
										}
									});
								}
							}
						});
					}
				}
				setTimeout(() => {
					this.calcNavScrollArrows();
				}, 250);
			});
		}
	}

	subscribeToRecentEnabledConfig() {
		if (!this.recentEnabledSubscription) {
			this.recentEnabledSubscription = this.configService.recentEnabledChanged.subscribe((response) => {
				this.recentEnabled = response;
			});
		}
	}

	subscribeToFavoritesEnabledConfig() {
		if (!this.favoritesEnabledSubscription) {
			this.favoritesEnabledSubscription = this.configService.favoritesEnabledChanged.subscribe((response) => {
				this.favoritesEnabled = response;
			});
		}
	}

	subscribeToFileShareEnabledConfig() {
		if (!this.fileShareEnabledSubscription) {
			this.fileShareEnabledSubscription = this.configService.fileShareEnabledChanged.subscribe((response) => {
				this.fileShareEnabled = response;
			});
		}
	}

	subscribeToAvatarsEnabledConfig() {
		if (!this.avatarSubscription) {
			this.avatarSubscription = this.userConfigService.avatarChanged.subscribe((response) => {
				this.loadAvatar();
			});
		}
	}

	subscribeToAvatarChanged() {
		if (!this.avatarsEnabledSubscription) {
			this.avatarsEnabledSubscription = this.configService.avatarsEnabledChanged.subscribe((response) => {
				this.avatarsEnabled = response;
				this.loadAvatar();
			});
		}
	}

	subscribeToSiteNameChanged() {
		if (!this.siteNameChangedSubscription) {
			this.siteNameChangedSubscription = this.configService.siteNameChanged.subscribe((response) => {
				this.siteName = response;
			});
		}
	}

	subscribeToDisplaySiteNameInHeaderChanged() {
		if (!this.displaySiteNameInHeaderChangedSubscription) {
			this.displaySiteNameInHeaderChangedSubscription = this.configService.displaySiteNameInHeaderChanged.subscribe((response) => {
				this.displaySiteNameInHeader = response;
			});
		}
	}

	subscribeToPageEditMode() {
		if (!this.pageEditModeSubscription) {
			this.pageEditModeSubscription = this.pageService.pageEditMode.subscribe((response) => {
				this.pageEdit = response.edit;
				this.pageEditId = response.pageId;
			});
		}
	}

	subscribeToSiteLogoChanged() {
		if (!this.siteLogoChangedSubscription) {
			this.siteLogoChangedSubscription = this.configService.siteLogoChanged.subscribe((response) => {
				this.configService.getImage('siteLogo', parseInt(this.activeWorkspace)).subscribe((imageResult) => {
					if (imageResult.body && imageResult.body.size !== 0) {
						const reader = new FileReader();
						reader.onloadend = (e) => {
							this.logoUrl = this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
						};
						reader.readAsDataURL(imageResult.body);
					} else {
						this.logoUrl = undefined;
					}
				});
			});
		}
	}

	subscribeToSiteFaviconChanged() {
		if (!this.siteFaviconChangedSubscription) {
			this.siteFaviconChangedSubscription = this.configService.siteFaviconChanged.subscribe((response) => {
				this.configService.getImage('siteFavicon', parseInt(this.activeWorkspace)).subscribe((imageResult) => {
					if (imageResult.body && imageResult.body.size !== 0) {
						const reader = new FileReader();
						reader.onloadend = (e) => {
							this.setFavicon(reader.result.toString());
						};
						reader.readAsDataURL(imageResult.body);
					} else {
						this.setFavicon(null);
					}
				});
			});
		}
	}

	subscribeToRouteQueryParams() {
		this.route.queryParamMap.subscribe(paramMap => {
			// If the current user attempts to hardcode query params, they will be stopped here if a rule for the query
			// param is specified in the canAccessParams method.
			if (paramMap.keys.length > 0 && !this.authService.canAccessParams(paramMap)) {
				const queryParamsToRemove = {};
				paramMap.keys.forEach(key => {
					queryParamsToRemove[key] = null;
				});
				this.router.navigate([], {
					queryParams: queryParamsToRemove,
					queryParamsHandling: 'merge'
				});
			}
		});
	}

	navigate(route: string) {
		this.pageEdit = false;
		this.pageEditId = '-1';

		this.router.navigate(['/' + route]);
	}

	logout() {
		this.pageEdit = false;
		this.pageEditId = '-1';

		this.mfilesService.clearCache();
		this.pageService.clearCache();
		this.shareLinkService.clearCache();

		this.authService.loginConfigs = undefined;
		this.authService.logout();

		if (this.router.url.indexOf('/login') > -1) {
			location.reload();
		} else {
			this.router.navigate(['/login']);
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		const pageReorder = new PageReorder();
		pageReorder.nextIndex = event.currentIndex;
		pageReorder.previousIndex = event.previousIndex;

		this.pageService.setPageReorderEvent(pageReorder);

		moveItemInArray(this.pages, event.previousIndex, event.currentIndex);
	}

	setFavicon(path: string) {
		const head = document.querySelector('head');
		if (this.favicon) {
			head.removeChild(this.favicon);
		}
		this.favicon = document.createElement('link');

		this.favicon.setAttribute('rel', 'icon');
		this.favicon.setAttribute('href', path);

		head.appendChild(this.favicon);
	}

	scrollRight(): void {
		this.navScrollPosition = this.headerItems.nativeElement.scrollLeft + 150;
		this.headerItems.nativeElement.scrollTo({ left: (this.navScrollPosition), behavior: 'smooth' });

		this.calcNavScrollArrows();
	}

	scrollLeft(): void {
		this.navScrollPosition = this.headerItems.nativeElement.scrollLeft - 150;
		this.headerItems.nativeElement.scrollTo({ left: (this.navScrollPosition), behavior: 'smooth' });

		this.calcNavScrollArrows();
	}

	calcNavScrollArrows() {
		if (this.headerItems) {
			// Determine if scrolling
			if (this.headerItems.nativeElement.scrollWidth > this.headerItems.nativeElement.clientWidth) {
				// Calculate Left Arrow
				if (this.navScrollPosition < 1) {
					this.navScrollLeft = false;
				} else {
					this.navScrollLeft = true;
				}

				// Calculate Right Arrow
				const maxLeftPosition = (this.headerItems.nativeElement.scrollWidth - this.headerItems.nativeElement.clientWidth + 150);
				if ((this.navScrollPosition + 150) >= maxLeftPosition) {
					this.navScrollRight = false;
				} else {
					this.navScrollRight = true;
				}
			} else {
				// No scroll
				this.navScrollLeft = false;
				this.navScrollRight = false;
			}
		}
	}

	onPageSelected() {
		this.pagelistCollapsed = false;
	}

	assertOneDropdown(dropdown: string) {
		if (dropdown === 'admin' && !this.pagelistCollapsed) {
			this.pagelistCollapsed = true;
		} else if (dropdown === 'pagelist' && !this.adminDropdownCollapsed) {
			this.adminDropdownCollapsed = true;
		}
	}


	checkIfAdmin() {
		this.mfilesService.checkPermissions().subscribe((userPermissions) => {
			if (userPermissions.body['admin'] !== null) {
				this.authService.isAdministrator = userPermissions.body['admin'];
			}
		});
	}

	openWorkspace(workspace: number) {
		this.authService.setWorkspace(workspace);

		this.logout();
	}

	switchWorkspace(workspace: number) {
		this.authService.setWorkspace(workspace);

		this.pageEdit = false;
		this.pageEditId = '-1';

		this.mfilesService.clearCache();
		this.pageService.clearCache();
		this.shareLinkService.clearCache();

		this.authService.loginConfigs = undefined;
		this.authService.logout();

		this.router.navigate(['/login'], { queryParams: { workspace: workspace } });
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	loadAvatar() {
		this.avatarUrl = undefined;
		if (this.authService.isAuthenticated() && this.avatarsEnabled) {
			this.userConfigService.getImage('avatar').subscribe((imageResult) => {
				if (imageResult.body && imageResult.body.size !== 0) {
					const reader = new FileReader();
					reader.onloadend = (e) => {
						this.avatarUrl = this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
					};
					reader.readAsDataURL(imageResult.body);
				} else {
					this.avatarUrl = undefined;
				}
			});
		}
	}

	setNavigationPanelExpanded() {
		this.navigationPanelExpanded = !this.navigationPanelExpanded;
		this.layoutService.setNavigationPanelExpanded(this.navigationPanelExpanded);
	}

	intialWorkspaceValid() {
		return this.initialWorkspace.name && this.initialWorkspace.endpoint && this.initialWorkspace.vaultGuid && this.initialWorkspace.username && this.initialWorkspace.password;
	}

	setInitialWorkspace() {
		this.mfilesService.setInitialVaultWorkspace(this.initialWorkspace).subscribe((result) => {
			if (result === 'invalid url') {
				this.intialCreateMessage = 'The entered Endpoint is not a valid URL';
			} else if (result === 'not an admin') {
				this.intialCreateMessage = 'The entered Username/Password are not an admin user';
			} else if (result === 'updated') {
				this.modalRef.hide();
				window.location.reload();
			}
		});
	}

	openDebugModal() {
		this.loadingDebug = true;
		this.debugTime = new Date();
		this.userId = undefined;
		this.openModal(this.debugDialog, 'md');
		this.mfilesService.getExternalUser().subscribe((result: any) => {
			this.userId = result?.body?.objVer?.id ? result?.body?.objVer?.id : -1;
			this.loadingDebug = false;
		});
	}

	copyDebug() {
		const debugInfo = {
			time: this.debugTime,
			isAdmin: this.authService.isAdmin(),
			isExternal: this.authService.isExternalUser(),
			username: this.authService.getActiveUsername(),
			userId: this.userId
		};

		navigator.clipboard.writeText(JSON.stringify(debugInfo));
		this.closeModal();
		this.toastr.success('Copied Troubleshooting Information');
	}

	closeModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}
	}
}
