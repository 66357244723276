import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-store';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminGuard {

	@SessionStorage() user_configuration: any;

	constructor(
		private authService: AuthService
	) { }

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {

		return this.authService.isAdmin();

	}
}
