<div *ngIf="loading" [ngClass]="{'loading in': loading }"></div>

<div *ngIf="!loading">
	<div class="main-content-wrapper">
		<div class="main-content">
			<header id="main-content-header" class="main-content-header">
				<span class="breadcrumb-container">
					<span class="breadcrumb"><span *ngIf="showGoBackButton"><a href="javascript:" class="back-button" (click)="goBack()">Go Back</a> <em class="fas fa-angle-right"></em> </span>{{ bidRequest.title }} &nbsp;&nbsp;&nbsp;</span><a href="javascript:" (click)="refresh()"><em class="fa fa-redo-alt"></em></a>
				</span>
			</header>
		</div>
	</div>

	<div class="main-content-area">
		<div class="main-content-body">
			<!-- Title Container -->
			<div class="title-container">
				<div class="title">{{ bidRequest.title }}</div>
				<div class="state-buttons" *ngIf="!bidClosed" [ngClass]="{'loading in': loadingStates}">
					<ng-container *ngFor="let state of states">
						<!--FH-3443 - Do not show "Response Complete" button if there are no bid response documents, even if in Response Complete workflow state. Also, button is hidden when deletion of a doc begins.--> 
						<button class="btn btn-md" *ngIf="state.selectable && !loadingStates && ((state.id === workflowStateIds['TI.MConnect.State.BidRequest.ResponseComplete'] ? bidResponseDocs.length > 0 && !deletingResponseDoc : true))" (click)="stateButtonClicked(state)" [ngClass]="{ 'btn-danger': state.id === workflowStateIds['TI.MConnect.State.BidRequest.BidRequestDeclined'], 'btn-success': state.id === workflowStateIds['TI.MConnect.State.BidRequest.ResponseComplete'] || state.id === workflowStateIds['TI.MConnect.State.BidRequest.AcceptedPreparingResponse'] }">
							{{ state.name }} 
						</button>
					</ng-container>
				</div>
			</div>
			<!-- Overview Message -->
			<div class="overview-message">
				<div class="icon"><em class="fas fa-exclamation-triangle"></em></div>
				<div class="text">{{ overviewMessage }}</div>
			</div>
			<!-- Navigation Tabs - Desktop -->
			<div class="tabs nav-tabs-container">
				<ul class="nav">
					<li class="nav-link">
						<button class="nav-button" [ngClass]="{'tab-active': currTab == 'overview'}" (click)="currTab = 'overview'">Overview</button>
						<div class="under-bar"></div>
					</li>
					<li class="nav-link">
						<button class="nav-button" [ngClass]="{'tab-active': currTab == 'bidDocs'}" (click)="currTab = 'bidDocs'; documentViewed = false; getBidDocs();">{{ bidRequest.className }} Documents</button>
						<div class="under-bar"></div>
					</li>
					<li *ngIf="showCorrespondence" class="nav-link">
						<button class="nav-button" [ngClass]="{'tab-active': currTab == 'correspondence'}" (click)="currTab = 'correspondence'; documentViewed = false; getBidCommunications();">Correspondence</button>
						<div class="under-bar"></div>
					</li>
					<li *ngIf="showResponseDocs" class="nav-link">
						<button class="nav-button" [ngClass]="{'tab-active': currTab == 'responseDocs'}" (click)="currTab = 'responseDocs'; documentViewed = false; getBidResponseDocs();">Response Documents</button>
						<div class="under-bar"></div>
					</li>
				</ul>
			</div>

			<!-- Navigation Tabs - Mobile -->
			<div class="nav-dropdown-container">
				<ng-select #navSelect class="typeahead form-typeahead" [multiple]="false" [clearable]="false" (change)="navChanged($event)">
					<ng-option value="overview">Overview</ng-option>
					<ng-option value="bidDocs">{{ bidRequest.className }} Documents</ng-option>
					<ng-option value="correspondence">Correspondence</ng-option>
					<ng-option value="responseDocs">Response Documents</ng-option>
				</ng-select>
			</div>

			<!-- Overview Tab -->
			<div class="overview row" *ngIf="currTab == 'overview'">
				<div class="info-group col-xs-12 col-md-6">
					<div class="inner">
						<h2>{{ bidRequest.className }} Information</h2>
						<div class="info-group-body">
							<ng-container *ngFor="let prop of bidRequest.properties">
								<label class="info-group-label">{{ prop.name }}</label>
								<p>{{ prop.value.displayValue }}</p>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="info-group col-xs-12 col-md-6">
					<div class="inner">
						<h2>Project Information</h2>
						<div class="info-group-body project-info" [ngClass]="{'loading in': loadingProject}">
							<ng-container *ngIf="!loadingProject; else elseProjectLoadingTemplate">
								<ng-container *ngIf="project; else elseProjectTemplate">
									<ng-container *ngIf="project.properties.length > 0; else elseProjectPropsTemplate">
										<ng-container *ngFor="let prop of project.properties">
											<label class="info-group-label">{{ prop.name }}</label>
											<p>{{ prop.value.displayValue }}</p>
										</ng-container>
									</ng-container>
									<ng-template #elseProjectPropsTemplate>
										<p>No Project Information available.</p>
									</ng-template>
								</ng-container>
								<ng-template #elseProjectTemplate>
									<p>No Project Information available.</p>
								</ng-template>
							</ng-container>
							<ng-template #elseProjectLoadingTemplate>
								<div></div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>

			<!-- Bid Documents Tab -->
			<div class="bid-docs row" *ngIf="currTab == 'bidDocs'">
				<div class="info-group col-md-12">
					<div class="inner">
						<h2>{{ bidRequest.className }} Documents</h2>
						<div [ngClass]="{'loading in': loadingBidDocs}">
							<div class="table-buttons">
								<span class="download-buttons">
									<button *ngIf="bidDocuments.length > 0 && !loadingBidDocs" (click)="downloadSelected()"><em *ngIf="loadingDownloadSelected" class="fas fa-circle-notch fa-spin"></em> Download Selected</button>
									<button *ngIf="bidDocuments.length > 0 && !loadingBidDocs" (click)="downloadAll()"><em *ngIf="loadingDownloadAll" class="fas fa-circle-notch fa-spin"></em> Download All</button>
								</span>
							</div>
							<table class="table-striped full-width table" *ngIf="bidDocuments.length > 0 && !loadingBidDocs">
								<thead>
									<tr>
										<th><span class="check-all"><input *ngIf="bidDocuments.length > 0 && !loadingBidDocs" type="checkbox" id="checkAllInput" (click)="checkAll($event)"></span></th>
										<th>Name</th>
										<th>Size</th>
										<th class="download-btn-column"></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let doc of bidDocuments; let i = index;">
										<td class="checkbox-container"><input type="checkbox" [(ngModel)]="doc.checkedForDownload" (click)="docChecked(doc, $event)" />
										</td>
										<td>
											<div class="new" *ngIf="!doc.viewed" title="Unviewed document">New</div>
											<a href="javascript:" (click)="fileClicked(doc, 'package-doc', i)"><span [ngStyle]="{'font-weight': !doc.viewed ? 'bold' : 'normal' }">{{ doc.title }}.{{ doc.files?.length == 1 && doc.files[0] ? doc.files[0]?.extension : '' }}</span></a>
										</td>
										<td>
											<span>{{ doc.files?.length == 1 && doc.files[0] ? (doc.files[0].size | mfObjectSize) : ''}}</span>
										</td>
										<td style="text-align: right;">
											<span *ngIf="doc.downloading && doc.percentDownloaded === 0">
												<span>Initializing...</span>
												<a href="javascript:" class="download-button"><em class="fas fa-circle-notch fa-spin"></em></a>
											</span>

											<span *ngIf="doc.downloading && doc.percentDownloaded > 0">
												<div class="download-progress-container">
													<div class="download-progress-bar">
														<mat-progress-bar mode="determinate" [value]="doc.percentDownloaded">
														</mat-progress-bar>
													</div>
													<div class="download-progress-num">{{ doc.percentDownloaded.toFixed(2) }}%</div>
													<div class="cancel-download"><a href="javascript:" (click)="cancelDownload(doc)"><em class="far fa-stop-circle"></em></a></div>
												</div>
											</span>

											<a href="javascript:" *ngIf="doc.download && !doc.downloading" (click)="download(doc, i)"><em class="fas fa-download"></em></a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<!-- Correspondence Tab -->
			<div class="correspondence row" *ngIf="currTab == 'correspondence'">
				<div class="info-group col-md-12">
					<div class="inner">
						<h2>Correspondence</h2>
						<div [ngClass]="{'loading in': loadingCommunications}">
							<div class="table-buttons">
								<span class="correspondence-buttons">
									<button *ngIf="!loadingCommunications && !bidClosed" (click)="openModal(messageCreateModal, 'lg')">Create Message</button>
								</span>
							</div>
							<ng-container *ngIf="bidCommunications.length > 0 && !loadingCommunications; else elseTemplate">
								<table class="table-striped full-width table" *ngIf="bidCommunications.length > 0 && !loadingCommunications">
									<tbody>
										<ng-container *ngFor="let communication of bidCommunications; let i = index">
											<tr>
												<td class="communication">
													<!--
													<span>
														<a href="javascript:" (click)="toggleCorrespondenceRow(i)"><em [ngClass]="communication.isOpen ? 'fas fa-minus-circle' : 'fas fa-plus-circle'"></em></a>
													</span>
													-->
													<span><em class="fas fa-comment"></em></span>
													<span class="new" *ngIf="!communication.viewed">New</span>
													<a href="javascript:" (click)="fileClicked(communication, 'communication', i)"><span [ngStyle]="{'font-weight': !communication.viewed ? 'bold' : 'normal' }">{{ communication.title }}</span></a>

													<ng-container *ngIf="communication.isOpen">
														<table [ngClass]="{'loading in': loadingProperties }" class="properties">
															<ng-container *ngIf="!loadingProperties">
																<tr *ngFor="let attachment of communication.attachments">
																	<td class="communication-attachment" colspan="2">
																		<em class="fas fa-paperclip"></em> <a href="javascript:" (click)="fileClicked(attachment, '')">{{ attachment.title }}</a>
																	</td>
																</tr>
																<tr *ngFor="let prop of properties">
																	<td class="title"><span>{{prop.name}}</span></td>
																	<td><span>{{prop.value.displayValue}}</span></td>
																</tr>
																<tr *ngIf="communication.addedDocuments != null && communication.addedDocuments.length > 0">
																
																	<td class="title" style="vertical-align: top;"><span>Added Document(s)</span></td>
																	<ul>
																		<ng-container *ngFor = "let addedDoc of communication.addedDocuments">
																			<li *ngFor="let docFile of addedDoc.files">
																				<a style="cursor: pointer" (click)="currTab = 'bidDocs'; documentViewed = false; getBidDocs();">{{docFile.name}}.{{docFile.extension}}</a>
																			</li>
																		</ng-container>
																		
																	</ul>
																</tr>
																<tr *ngIf="communication.updatedDocuments != null && communication.updatedDocuments.length > 0">
																
																	<td class="title" style="vertical-align: top;"><span>Updated Document(s)</span></td>
																	<ul>
																		<ng-container *ngFor = "let updatedDoc of communication.updatedDocuments">
																			<li *ngFor="let docFile of updatedDoc.files">
																				<a style="cursor: pointer" (click)="currTab = 'bidDocs'; documentViewed = false; getBidDocs();">{{docFile.name}}.{{docFile.extension}}</a>
																			</li>
																		</ng-container>
																		
																	</ul>
																</tr>
																
															</ng-container>
														</table>
													</ng-container>
												</td>
												<td style="text-align: right;">
													<a *ngIf="communication.isRequestToMakePublic && publicMessagesEnabled" class="request-to-make-public-button" href="javascript:" (click)="openRequestToMakePublicModal(i)" title="Approve or Decline Request To Make Public">
														<em class="fas fa-exclamation-circle red"></em>
													</a>
												</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
							</ng-container>
							<ng-template #elseTemplate>
								<div class="no-communications-message" *ngIf="!loadingCommunications">
									You have not created any messages for this {{ bidRequest.className }}. Click the <strong>Create Message</strong> button to create a new message.
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>

			<!-- Response Documents Tab -->
			<div class="response-docs row" *ngIf="currTab == 'responseDocs'">
				<div class="info-group col-md-12">
					<div class="inner">
						<h2>Response Documents</h2>
						<div [ngClass]="{'loading in': loadingResponseDocs}">
							<div class="table-buttons">
								<span class="download-buttons">
									<button *ngIf="!bidClosed && !loadingResponseDocs && !bidRequestComplete" (click)="uploadResponseDocs.click()">
										<span>Upload</span>
										<input #uploadResponseDocs type="file" (change)="saveResponseDocs($event.target.files)" multiple />
									</button>
									<button *ngIf="bidResponseDocs.length > 0 && !loadingResponseDocs" (click)="downloadSelected()"><em *ngIf="loadingDownloadSelected" class="fas fa-circle-notch fa-spin"></em> Download Selected</button>
									<button *ngIf="bidResponseDocs.length > 0 && !loadingResponseDocs" (click)="downloadAll()"><em *ngIf="loadingDownloadAll" class="fas fa-circle-notch fa-spin"></em> Download All</button>
								</span>
							</div>
							<ng-container *ngIf="bidResponseDocs.length > 0 && !loadingResponseDocs; else elseTemplate">
								<table class="table-striped full-width table">
									<thead>
										<tr>
											<th><span class="check-all"><input *ngIf="bidResponseDocs.length > 0 && !loadingResponseDocs" type="checkbox" id="checkAllInput" (click)="checkAll($event)"></span></th>
											<th>Name</th>
											<th>Size</th>
											<th class="download-btn-column"></th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let doc of bidResponseDocs; let i = index">
											<td class="checkbox-container"><input type="checkbox" [(ngModel)]="doc.checkedForDownload" (click)="docChecked(doc, $event)" /></td>
											<td><a href="javascript:" (click)="fileClicked(doc, 'response-doc', i)">{{ doc.title }}.{{ doc.files?.length == 1 && doc.files[0] ? doc.files[0]?.extension : '' }}</a></td>
											<td>
												<span>{{ doc.files?.length == 1 && doc.files[0] ? (doc.files[0].size | mfObjectSize) : ''}}</span>
											</td>
											<td style="text-align: right;">
												<span *ngIf="doc.downloading && doc.percentDownloaded === 0">
													<span>Initializing...</span>
													<a href="javascript:" class="download-button"><em class="fas fa-circle-notch fa-spin"></em></a>
												</span>

												<span *ngIf="doc.downloading && doc.percentDownloaded > 0">
													<div class="download-progress-container">
														<div class="download-progress-bar">
															<mat-progress-bar mode="determinate" [value]="doc.percentDownloaded">
															</mat-progress-bar>
														</div>
														<div class="download-progress-num">{{ doc.percentDownloaded.toFixed(2) }}%</div>
														<div class="cancel-download"><a href="javascript:" (click)="cancelDownload(doc)"><em class="far fa-stop-circle"></em></a></div>
													</div>
												</span>

												<a href="javascript:" *ngIf="doc.download && !doc.downloading" class="download-button" (click)="download(doc, i)"><em class="fas fa-download"></em></a>
												<a href="javascript:" *ngIf="doc.delete && !doc.downloading && !bidClosed && !bidRequestComplete" class="delete-button" (click)="deleteBidResponseDoc(doc, i)"><em class="fas fa-trash"></em></a>
											</td>
										</tr>
									</tbody>
								</table>
							</ng-container>
							<ng-template #elseTemplate>
								<div class="no-response-docs-message" *ngIf="!loadingResponseDocs">You have not uploaded any documents yet. Click the
									<strong>Upload</strong> button to begin uploading documents for this {{
									bidRequest.className }}.
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<ng-template #stateTransitionCommentModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-info-circle"></em> Reason</h5>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="!transitionCommentSent; else transitionCommentSentTemplate">
			<div class="transition-comment-message-body" [ngClass]="{'loading in': sendingTransitionComment}">
				<textarea class="transition-comment-message-text-area" [placeholder]="selectedState.placeholder" [(ngModel)]="transitionComment"></textarea>
			</div>
		</ng-container>
		<ng-template #transitionCommentSentTemplate>
		</ng-template>
	</div>
	<div class="modal-footer message-footer" *ngIf="!transitionCommentSent">
		<div class="transition-comment-buttons">
			<button type="button" class="btn btn-primary btn-md" (click)="finishStateTransition()" [disabled]="isValidTransitionComment()">Finish</button>
			<button type="button" class="btn btn-light btn-md" (click)="closeTransitionCommentModal()">Cancel</button>
		</div>
	</div>
</ng-template>

<ng-template #messageCreateModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-envelope"></em> Create Message</h5>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="!communicationUploaded; else communicationReceivedTemplate">
			<div class="message-creation-body" [ngClass]="{'loading in': communicationUploading && inboundBidMessage.files.length === 0}">
				<div class="text-area-container" [ngClass]="{'loading in': communicationUploading && inboundBidMessage.files.length > 0}">
					<textarea class="message-footer-text-area" placeholder="Type your message here..." [(ngModel)]="inboundBidMessage.message"></textarea>
				</div>

				<table class="message-attachment-table full-width table" *ngIf="inboundBidMessage.files.length > 0">
					<thead>
						<tr class="message-attachment-table-file-rows">
							<th>Name</th>
							<th>{{ communicationUploading ? 'Progress' : 'Size' }}</th>
							<th *ngIf="communicationUploading !== true" id="removeMessageAttachment"></th>
						</tr>
					</thead>
					<tbody class="message-attachment-table-body">
						<tr class="message-attachment-table-file-rows" *ngFor="let file of inboundBidMessage.files; let i = index">
							<td>{{ file.name }}</td>
							<ng-container *ngIf="communicationUploading !== true; else communicationUploadProgressTemplate">
								<ng-container *ngIf="file.size > 0; else elseEmptyTemplate">
									<td>{{ file.size | mfObjectSize }}</td>
								</ng-container>
								<ng-template #elseEmptyTemplate>
									<td>
										<div class="upload-progress-empty">
											<em class="fas fa-exclamation-triangle"></em>
											This file has no content. It will be skipped.
										</div>
									</td>
								</ng-template>
							</ng-container>
							<ng-template #communicationUploadProgressTemplate>
								<td>
									<ng-container *ngIf="uploadProgressStats[file.name] === 'ERROR'; else communicationProgressTemplate">
										<div class="upload-progress-error">
											<em class="fas fa-exclamation-circle error"></em>
											An error occurred while uploading this file. Please try again later.
										</div>
									</ng-container>
									<ng-template #communicationProgressTemplate>
										<ng-container *ngIf="uploadProgressStats[file.name] === 'EMPTY'; else notEmptyTemplate">
											<div class="upload-progress-empty">
												<em class="fas fa-exclamation-triangle"></em>
												This file has no content. It will be skipped.
											</div>
										</ng-container>
										<ng-template #notEmptyTemplate>
											<div class="upload-progress-container">
												<div class="upload-progress-bar">
													<mat-progress-bar mode="determinate" [value]="uploadProgressStats[file.name]">
													</mat-progress-bar>
												</div>
												<div class="upload-progress-num">{{ uploadProgressStats[file.name] }}%</div>
											</div>
										</ng-template>
									</ng-template>
								</td>
							</ng-template>
							<td *ngIf="communicationUploading !== true" class="remove-message-attachment-file-container">
								<a href="javascript:" (click)="removeMessageAttachment(i)"><em class="fas fa-trash"></em></a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</ng-container>
		<ng-template #communicationReceivedTemplate>
			<div class="message-creation-success-message">{{ correspondenceCreationSuccessMessage }}</div>
		</ng-template>
	</div>
	<div class="modal-footer message-footer" *ngIf="!communicationUploaded">
		<div class="message-creation-buttons">
			<div>
				<span id="uploadProgressMessage">{{ uploadProgressMessage }}</span>

				<!-- <div *ngIf="hasCommercialInConfidence && publicMessagesEnabled">
					<input type="checkbox" [checked]="inboundBidMessage.commercialConfidence" (change)="setCommercialConfidence($event.target.checked)" class="cic-checkbox">
					<span>Commercial in Confidence</span>
				</div> -->
			</div>
			<div>
				<button type="button" class="btn btn-primary btn-md" (click)="sendMessage()" [disabled]="!isValidMessage()">Send</button>
				<button class="btn btn-light btn-md" (click)="file.click()" title="Add Attachments">
					<em class="fas fa-paperclip"></em>
					Add attachments
					<input #file type="file" (click)="clearMessageAttachmentsArrayHandler($event)" (change)="addFilesToMessage($event.target.files)" multiple />
				</button>
				<button *ngIf="inboundBidMessage.files.length > 0" class="btn btn-light btn-md" (click)="clearMessageAttachments()" title="Clear Attachments">
					<em class="fas fa-trash"></em>
					Clear attachments
				</button>
				<button class="btn btn-light btn-md" (click)="closeMessageCreationModal()">Cancel</button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #uploadDocsModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-file-upload"></em> Documents to Upload</h5>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="!responseDocsUploaded; else uploadSuccessTemplate">
			<div class="response-doc-upload-review-message">You are about to upload the following documents for:<span class="response-doc-upload-bid-name"><strong>{{ bidRequest.title }}</strong></span></div>
			<!-- <table class="response-doc-upload-table full-width table" [ngClass]="{'loading in': responsesUploading}"> -->
			<table class="response-doc-upload-table full-width table">
				<thead>
					<tr class="response-doc-table-file-rows">
						<th>Name</th>
						<th>{{ responsesUploading ? 'Progress' : 'Size' }}</th>
						<th *ngIf="responsesUploading !== true" id="removeFileUpload"></th>
					</tr>
				</thead>
				<tbody class="response-doc-table-body">
					<tr class="response-doc-table-file-rows" *ngFor="let file of uploadedResponseDocs; let i = index">
						<td>{{ file.name }}</td>
						<ng-container *ngIf="responsesUploading !== true; else responseUploadProgressTemplate">
							<ng-container *ngIf="file.size > 0; else elseEmptyTemplate">
								<td>{{ file.size | mfObjectSize }}</td>
							</ng-container>
							<ng-template #elseEmptyTemplate>
								<td>
									<div class="upload-progress-empty">
										<em class="fas fa-exclamation-triangle"></em>
										This file has no content. It will be skipped.
									</div>
								</td>
							</ng-template>
						</ng-container>
						<ng-template #responseUploadProgressTemplate>
							<td>
								<ng-container *ngIf="uploadProgressStats[file.name] === 'ERROR'; else responseProgressTemplate">
									<div class="upload-progress-error">
										<em class="fas fa-exclamation-circle error"></em>
										An error occurred while uploading this file. Please try again later.
									</div>
								</ng-container>
								<ng-template #responseProgressTemplate>
									<ng-container *ngIf="uploadProgressStats[file.name] === 'EMPTY'; else notEmptyTemplate">
										<div class="upload-progress-empty">
											<em class="fas fa-exclamation-triangle"></em>
											This file has no content. It will be skipped.
										</div>
									</ng-container>
									<ng-template #notEmptyTemplate>
										<div class="upload-progress-container">
											<div class="upload-progress-bar">
												<mat-progress-bar mode="determinate" [value]="uploadProgressStats[file.name]">
												</mat-progress-bar>
											</div>
											<div class="upload-progress-num">{{ uploadProgressStats[file.name] }}%</div>
										</div>
									</ng-template>
								</ng-template>
							</td>
						</ng-template>
						<td *ngIf="responsesUploading !== true" class="remove-response-doc-file-container">
							<a href="javascript:" (click)="removeResponseDocFile(i)"><em class="fas fa-trash"></em></a>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- <div class="num-response-docs-uploaded" *ngIf="responsesUploading">Uploading:  {{ numResponseDocsUploaded }}</div> -->
		</ng-container>
		<ng-template #uploadSuccessTemplate>
			<div class="response-doc-upload-success-message">{{ responseUploadSuccessMessage }}</div>
		</ng-template>
	</div>
	<div class="modal-footer message-footer" *ngIf="!responseDocsUploaded">
		<span id="uploadProgressMessage">{{ uploadProgressMessage }}</span>

		<div class="response-doc-upload-footer-buttons">
			<button class="btn btn-primary btn-md" (click)="uploadDocs()" [disabled]="responsesUploading">Upload</button>
			<button class="btn btn-light btn-md" (click)="closeUploadResponseDocsModal()">Cancel</button>
		</div>
	</div>
</ng-template>

<ng-template #requestToMakePublicModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-question-circle"></em> Request To Make Public</h5>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="!cicUpdated; else cicUpdatedTemplate">
			<p class="reason-to-make-public" [ngClass]="{'loading in': cicUpdating}">{{ reasonToMakePublic }}</p>
		</ng-container>
		<ng-template #cicUpdatedTemplate>
			<div class="cic-updated-message">{{ cicUpdatedMessage }}</div>
		</ng-template>
	</div>
	<div class="modal-footer message-footer" *ngIf="!cicUpdated">
		<div class="request-to-make-public-footer-buttons">
			<button class="btn btn-success btn-md" (click)="approveCICCommunication()">Approve</button>
			<button class="btn btn-danger btn-md" (click)="declineCICCommunication()">Decline</button>
			<button class="btn btn-light btn-md" (click)="closeRequestToMakePublicModal()">Cancel</button>
		</div>
	</div>
</ng-template>

<ng-template #viewerModal>
	<div class="modal-body">
		<div class="viewer-container">
			<app-widget-viewer [widgetSettings]="viewerModalSettings"></app-widget-viewer>
		</div>
	</div>
	<div class="modal-footer message-footer">
		<div class="col-md-6 left">
			<ng-container *ngIf="selectedType == 'package-doc'">
				<button class="btn btn-light btn-md" (click)="prevFileClicked()" *ngIf="bidDocuments.length > 0" [disabled]="selectedIndex == 0">Prev</button>
				<button class="btn btn-light btn-md" (click)="nextFileClicked()" *ngIf="bidDocuments.length > 0" [disabled]="selectedIndex == (bidDocuments.length-1)">Next</button>
			</ng-container>
			<ng-container *ngIf="selectedType == 'response-doc'">
				<button class="btn btn-light btn-md" (click)="prevFileClicked()" *ngIf="bidResponseDocs.length > 0" [disabled]="selectedIndex == 0">Prev</button>
				<button class="btn btn-light btn-md" (click)="nextFileClicked()" *ngIf="bidResponseDocs.length > 0" [disabled]="selectedIndex == (bidResponseDocs.length-1)">Next</button>
			</ng-container>
		</div>
		<div class="col-md-6">
			<button class="btn btn-light btn-md" (click)="closeViewerModal()">Close</button>
		</div>
	</div>
</ng-template>