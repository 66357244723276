export class SharesLink {
	public name: string;
	public view: boolean;
	public download: boolean;
	public updateDocument: boolean;
	public delete: boolean;
	public updateMetadata: boolean;
	public create: boolean;
	public comment: boolean;
	public coauthor: boolean;

	constructor() {
		this.name = '';
		this.view = false;
		this.download = false;
		this.updateDocument = false;
		this.delete = false;
		this.updateMetadata = false;
		this.create = false;
		this.comment = false;
		this.coauthor = false;
	}
}