<div class="commentItem" [id]="'comment-' + comment.id">
	<div class="commentTitle">
		{{comment.user}} on {{comment.date | date:'shortDate'}} at {{comment.date | date:'shortTime'}}
		<div class="right"><a href="#{{comment.id}}" data-toggle="collapse" [attr.aria-controls]="comment.id" aria-expanded="false"><em class="fas fa-reply" title="Reply"></em></a></div>
	</div>
	<div class="comment">{{comment.comment}}</div>
	<div class="commentResponse" [id]="comment.id">
		<form (ngSubmit)="onCommentSubmit($event)">
			<textarea #commentArea name="comment" class="form-control" placeholder="Enter a comment..."></textarea>
			<input type="hidden" name="commentId" [value]="comment.id">
			<a href="#{{comment.id}}" data-toggle="collapse" [attr.aria-controls]="comment.id" aria-expanded="false"><button type="reset">Cancel</button></a>
			<a><button type="submit">Reply</button></a>
		</form>
	</div>
	<div class="childComment" *ngFor="let subComment of comment.childComments">
		<app-comments (newComment)="onNewComment($event)" [comment]="subComment" [item]="item"></app-comments>
	</div>
</div>