import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ShareLink } from '../models/share-link.model';
import { environment } from '@environments/environment';
import { shareReplay, catchError } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ShareLinkService {

	// Used for caching of data
	private shareLinksByTypeAndId = {};

	constructor(
		private http: HttpClient
	) { }

	clearCache() {
		this.shareLinksByTypeAndId = {};
	}

	fetchShareLink(id: number) {
		let params = new HttpParams();
		params = params.set('id', id.toString());

		const requestUrl = `${environment.serviceUrl}/share`;

		return this.http.get<any>(requestUrl, { params });
	}

	fetchShareLinksByTypeAndId(type: string, resourceId: number) {
		if (this.containsLinkByTypeAndId(type, resourceId)) {
			return this.getCachedLinkByTypeAndId(type, resourceId);
		} else {
			let params = new HttpParams();
			params = params.set('type', type);
			params = params.set('resourceId', resourceId.toString());

			const requestUrl = `${environment.serviceUrl}/sharesByTypeAndId`;

			const response = this.http.get<any>(requestUrl, { params })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.removeLinkByTypeAndId(type, resourceId);
						return EMPTY;
					})
				);
			this.addResponseToLinksByTypeAndId(response, type, resourceId);
			return response;
		}
	}

	fetchShareLinksByToken(token: string) {
		let params = new HttpParams();
		params = params.set('token', token);

		const requestUrl = `${environment.serviceUrl}/shareByToken`;

		return this.http.get<any>(requestUrl, { params });
	}

	checkAccess(id: number, accessCode: string) {
		let params = new HttpParams();
		params = params.set('id', id.toString());
		params = params.set('accessCode', accessCode);

		const requestUrl = `${environment.serviceUrl}/shareCheckAccess`;

		return this.http.get<any>(requestUrl, { params });
	}

	deleteShareLink(id: number) {
		let params = new HttpParams();
		params = params.set('id', id.toString());

		const requestUrl = `${environment.serviceUrl}/share`;

		return this.http.delete(requestUrl, { params });
	}

	updateShareLinks(shareLinks: ShareLink[]) {
		const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/share`;

		return this.http.put<any>(requestUrl, JSON.stringify(shareLinks), { params, headers });
	}

	/* ShareLinksByTypeAndId - Helper Methods */
	containsLinkByTypeAndId(type: string, resourceId: number) {
		return this.shareLinksByTypeAndId[type] && this.shareLinksByTypeAndId[type][resourceId];
	}

	getCachedLinkByTypeAndId(type: string, resourceId: number) {
		return this.shareLinksByTypeAndId[type][resourceId];
	}

	removeLinkByTypeAndId(type: string, resourceId: number) {
		delete this.shareLinksByTypeAndId[type][resourceId];
	}

	addResponseToLinksByTypeAndId(response: Observable<any>, type: string, resourceId: number) {
		if (this.shareLinksByTypeAndId[type]) {
			this.shareLinksByTypeAndId[type][resourceId] = response;
		} else {
			this.shareLinksByTypeAndId[type] = {};
			this.shareLinksByTypeAndId[type][resourceId] = response;
		}
	}
}
