<tr class="object-row" [ngClass]="(item.id === selectedItemId && item.type === selectedItemType) || autoSelect ? 'object-selected' : 'object-unselected'">
	<!--
    <td *ngIf="completedEnabled" class="icon-cell">
        <a class="icon-link share" href="javascript:" *ngIf="!item.loaded"><em class="fas fa-spinner fa-spin" title="Loading completed status..."><span class="sr-only">Loading completed status...</span></em></a>
        <a class="icon-link share" href="javascript:" (click)="loadCompleted()" *ngIf="item.loaded && item.error"><em class="fas fa-check red" title="Error loading completed status. Click to re-load."><span class="sr-only">Error loading completed status. Click to re-load.</span></em></a>
        <a class="icon-link share" href="javascript:" (click)="openModal(completeDialog, 'lg')" *ngIf="item.loaded && !item.error && !item.completed && item.completeCriteria === '[]'"><em class="fas fa-check" title="No Completed criteria set. Click to edit the completed criteria."><span class="sr-only">No Completed criteria set. Click to edit the completed criteria.</span></em></a>
        <a class="icon-link share" href="javascript:" (click)="openModal(completeDialog, 'lg')" *ngIf="item.loaded && !item.error && !item.completed && item.completeCriteria !== '[]'"><em class="fas fa-check orange" title="Not completed. Click to view or edit the completed criteria."><span class="sr-only">Not completed. Click to view or edit the completed criteria.</span></em></a>
        <a class="icon-link share" href="javascript:" (click)="openModal(completeDialog, 'lg')" *ngIf="item.loaded && !item.error && item.completed"><em class="fas fa-check green" title="Completed. Click to view or edit the completed criteria."><span class="sr-only">Completed. Click to view or edit the completed criteria.</span></em></a>
    </td>
    -->
	<td *ngIf="favoritesEnabled" class="icon-cell">
		<label class="checkbox inline-block favorite">
			<input type="checkbox" [(ngModel)]="item.favorite" (ngModelChange)="favoriteChanged(item)">
			<span class="label" title="Favorite"><span></span></span>
		</label>
	</td>
	<td *ngFor="let column of view.viewContents.columns">
		<!-- The following columns are built in and have negative IDs. When we retrieve the properties for objects in M-Files, the property definition IDs
				for Name, Type, Size, etc. do not match up with the column IDs, hence the manual setting of column values below. -->
		<ng-container [ngSwitch]="column.id">
			<!-- Name == -1, otherwise show property that corresponds with column ID -->
			<ng-container *ngSwitchCase="-1">
				<div class="sub-name">
					<!-- Display the related objects arrows if the user is an internal user or if the user is external and the item is a document collection -->
					<div *ngIf="!disableRelatedView">
						<span class="expand-icon">
							<ng-container *ngIf="!external || item.type === 9">
								<a href="javascript:" (click)="getRelated()" *ngIf="!loadingRelated && (!item.expanded && !relatedLoaded || (relatedLoaded && (files.length > 1 || related.length > 0) && !showRelated))">
									<em class="fas fa-chevron-circle-right"></em>
								</a>
								<a href="javascript:" (click)="hideRelated()" *ngIf="!loadingRelated && (!item.expanded && (files.length > 1 || related.length > 0) && showRelated)">
									<em class="fas fa-chevron-circle-down"></em>
								</a>
								<em class="fas fa-circle-notch fa-spin" *ngIf="loadingRelated"></em>
							</ng-container>
						</span>
					</div>
					<ng-container *ngIf="item.name">
						<div>
							<em *ngIf="!icon" class="icon icon-file icon-other" [ngClass]="'icon-'+item.extension"></em>
							<em *ngIf="icon" class="icon icon-file icon-other" [ngStyle]="{'background': 'url(' + icon + ') no-repeat', 'background-size': 'contain'}"></em>
						</div>
						<ng-container *ngIf="!item.isBidRequest; else elseTemplate">
							<a class="icon-pad" href="javascript:" *ngIf="item.type === 0 || isMetadataOrViewerPresent()" (click)="fileClicked(item.id, item.type, 0, $event); associate(item);">{{item.name}}</a>
							<span class="icon-pad" *ngIf="item.type !== 0 && !isMetadataOrViewerPresent()">{{item.name}}</span>
						</ng-container>
						<ng-template #elseTemplate>
							<a class="icon-pad" href="javascript:" (click)="bidRequestClicked()">{{item.name}}</a>
						</ng-template>
					</ng-container>

				</div>
			</ng-container>

			<!-- Type == -2 -->
			<ng-container *ngSwitchCase="-2">
				<a href="javascript:" *ngIf="column.link && (item.type === 0 || isMetadataOrViewerPresent())" (click)="fileClicked(item.id, item.type, 0, $event); associate(item);">{{ objectTypes[item.type] ? objectTypes[item.type].name : '' }}</a>
				<ng-container *ngIf="!column.link || !(item.type === 0 || isMetadataOrViewerPresent())">{{ objectTypes[item.type] ? objectTypes[item.type].name : '' }}</ng-container>
			</ng-container>

			<!-- Size == -3 -->
			<ng-container *ngSwitchCase="-3">
				<a href="javascript:" *ngIf="column.link && (item.type === 0 || isMetadataOrViewerPresent())" (click)="fileClicked(item.id, item.type, 0, $event); associate(item);">{{ getItemSize() | mfObjectSize }}</a>
				<ng-container *ngIf="!column.link || !(item.type === 0 || isMetadataOrViewerPresent())">{{ getItemSize() | mfObjectSize }}</ng-container>
			</ng-container>

			<!-- Version == -8 -->
			<ng-container *ngSwitchCase="-8">
				<a href="javascript:" *ngIf="column.link && (item.type === 0 || isMetadataOrViewerPresent())" (click)="fileClicked(item.id, item.type, 0, $event); associate(item);">{{ item.version }}</a>
				<ng-container *ngIf="!column.link || !(item.type === 0 || isMetadataOrViewerPresent())">{{ item.version }}</ng-container>
			</ng-container>

			<!-- Date Created == -13 -->
			<ng-container *ngSwitchCase="-13">
				<a href="javascript:" *ngIf="column.link && (item.type === 0 || isMetadataOrViewerPresent())" (click)="fileClicked(item.id, item.type, 0, $event); associate(item);">{{ item.dateCreated | date:'short' }}</a>
				<ng-container *ngIf="!column.link || !(item.type === 0 || isMetadataOrViewerPresent())">{{ item.dateCreated | date:'short' }}</ng-container>
			</ng-container>

			<!-- Date Modified == -14 -->
			<ng-container *ngSwitchCase="-14">
				<a href="javascript:" *ngIf="column.link && (item.type === 0 || isMetadataOrViewerPresent())" (click)="fileClicked(item.id, item.type, 0, $event); associate(item);">{{ item.dateModified | date:'short' }}</a>
				<ng-container *ngIf="!column.link || !(item.type === 0 || isMetadataOrViewerPresent())">{{ item.dateModified | date:'short' }}</ng-container>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<span>
					<a href="javascript:" *ngIf="column.link && (item.type === 0 || isMetadataOrViewerPresent())" (click)="fileClicked(item.id, item.type, 0, $event); associate(item);">{{ getDisplayValueForColumn(column) }}</a>
					<ng-container *ngIf="!column.link || !(item.type === 0 || isMetadataOrViewerPresent())">{{ getDisplayValueForColumn(column) }}</ng-container>
				</span>
			</ng-container>
		</ng-container>
	</td>

	<!-- UPLOAD REVISION BUTTON -->
	<ng-container *ngIf="revisionsEnabled; else elseReviseTemplate">
		<td class="icon-cell">
			<a [class.disabled]="item.checkedOut" class="icon-link" href="javascript:" (click)="fileClicked(item.id, 0, i, $event); !item.checkedOut && uploadRevision(item.id, 0)"><em class="fas fa-file-upload" [title]="item.checkedOut ? 'Item Checked Out' : 'Upload Revision'" *ngIf="item.extension"><span class="sr-only">Upload Revision</span></em></a>
		</td>
	</ng-container>
	<ng-template #elseReviseTemplate>
		<td class="icon-cell no-width"></td>
	</ng-template>

	<!-- SHARE LINK BUTTON (EXTERNAL COMPONENT ONLY) -->
	<td class="icon-cell" *ngIf="external">
		<a class="icon-link" href="javascript:" (click)="copyToClipboard(item.id)">
			<em class="fas fa-share" title="Share Link to Document"><span class="sr-only">Share</span></em>
		</a>
	</td>

	<!-- DOWNLOAD BUTTON -->
	<ng-container *ngIf="downloadEnabled; else elseDownloadTemplate">
		<td class="icon-cell">
			<em *ngIf="loadingDownload" class="fas fa-circle-notch fa-spin"></em>
			<a *ngIf="!loadingDownload && item.type === 0 && !item.streamingVideoURL && !doNotDownload" class="icon-link" href="javascript:" (click)="download(item.id, 0)">
				<em class="fas fa-download" title="Download" *ngIf="(item.download || (view.hasAccessProperties && downloadEnabled)) && (!external || permissions.indexOf('Download') > -1)"><span class="sr-only">Download</span></em>
			</a>
			<a *ngIf="!loadingDownload && item.type === 9 && !item.streamingVideoURL && !doNotDownload" class="icon-link" href="javascript:" (click)="downloadDocumentCollection(item)">
				<em class="fas fa-download" title="Download All" *ngIf="(item.download || (view.hasAccessProperties && downloadEnabled)) && (!external || permissions.indexOf('Download') > -1)"><span class="sr-only">Download All</span></em>
			</a>
		</td>
	</ng-container>
	<ng-template #elseDownloadTemplate>
		<td class="icon-cell no-width"></td>
	</ng-template>

	<!--<td class="icon-cell" *ngIf="associateSelectedEnabled"><input type="radio" name="associatedObject" (click)="associate(item)"></td>-->

	<!-- DELETE BUTTON -->
	<ng-container *ngIf="deleteEnabled; else elseDeleteTemplate">
		<td class="icon-cell">
			<a class="icon-link" href="javascript:" (click)="fileClicked(item.id, 0, i, $event); delete(item.name, item.type, item.id)"><em class="fas fa-trash" [title]="'Delete'"><span class="sr-only">Delete</span></em></a>
		</td>
	</ng-container>
	<ng-template #elseDeleteTemplate>
		<td class="icon-cell no-width"></td>
	</ng-template>

	<!-- COAUTHOR BUTTON -->
	<td class="icon-cell" *ngIf="coauthorEnabled">
		<a *ngIf="(authService.getToken('azure') === 'true' || permissions.indexOf('Coauthor') > -1) && item.type === 0 && isCoauthorItem(item)" class="icon-link" [ngClass]="item.coauthored ? 'orange' : 'green'" href="javascript:" (click)="coauthor(item)"><em class="fas fa-user-edit" title="Co-Author"><span class="sr-only">Co-Author</span></em></a>
	</td>
</tr>
<tr *ngIf="showRelated">
	<td [attr.colspan]="getColCount()" class="related-container">
		<div [attr.id]="viewPanel">
			<div class="scroll-holder">
				<div class="table-scroll horizontal-scroll custom-scroll">
					<ng-container *ngIf="files.length > 1">
						<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted multipart-file-table">
							<thead>
								<tr>
									<th id="favorite" *ngIf="favoritesEnabled"></th>
									<th id="Name"></th>
									<th id="Size"></th>
									<!--<th id="version">Version</th>-->
									<th id="Date-Modified"></th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let file of files; let i = index">
									<td *ngIf="favoritesEnabled" class="icon-cell">&nbsp;</td>
									<td>
										<div class="ellipsis sub-name">
											<em class="icon icon-file icon-other" [ngClass]="'icon-'+file.extension"></em>
											<a class="icon-pad" href="javascript:" (click)="fileClicked(item.id, 0, i, $event)">{{ file.name + (file.extension ? '.' + file.extension : '') }}</a>
										</div>
									</td>
									<td>{{ file.size | mfObjectSize }}</td>
									<!--<td>{{ file.version }}</td>-->
									<td>{{ file.lastModified | date:'short' }}</td>
									<td class="icon-cell" *ngIf="revisionsEnabled">
										<a [class.disabled]="item.checkedOut" class="icon-link" href="javascript:" (click)="!item.checkedOut && uploadRevision(item.id, i)"><em class="fas fa-file-upload" [title]="item.checkedOut ? 'Item Checked Out' : 'Upload Revision'" *ngIf="file.extension"><span class="sr-only">Upload Revision</span></em></a>
									</td>
									<td class="icon-cell"><a class="icon-link" href="javascript:" (click)="download(item.id, i)" *ngIf="!item.streamingVideoURL && !doNotDownload"><em class="fas fa-download" title="Download" *ngIf="item.type === 0 && item.download"><span class="sr-only">Download</span></em></a></td>
								</tr>
							</tbody>
						</table>
					</ng-container>
					<ng-container *ngIf="!disableRelatedView">
						<div *ngFor="let relatedType of related" class="related-type-row">
							<div *ngIf="favoritesEnabled" class="favorite-cell">&nbsp;</div>
							<ng-container *ngIf="!external">
								<span class="expand-icon">
									<a href="javascript:" (click)="hideShowRelType(relatedType.typeName)" *ngIf="!relatedTypeShown[relatedType.typeName]">
										<em class="fas fa-chevron-circle-right"></em>
									</a>
									<a href="javascript:" (click)="hideShowRelType(relatedType.typeName)" *ngIf="relatedTypeShown[relatedType.typeName]">
										<em class="fas fa-chevron-circle-down"></em>
									</a>
								</span>
								<a href="javascript:" (click)="hideShowRelType(relatedType.typeName)">{{ relatedType.typeName }} ({{ relatedType.objects.length }})</a>
							</ng-container>
							<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted" *ngIf="external || relatedTypeShown[relatedType.typeName]">
								<thead>
									<tr *ngIf="!tiles">
										<!--<th id="completed" *ngIf="completedEnabled"></th>-->
										<th id="favorite" *ngIf="favoritesEnabled"></th>
										<!--<th id="related" *ngIf="!authService.isExternalUser()"></th>-->
										<th *ngFor="let column of view.viewContents.columns" [id]="column.name ? column.name.replace(' ', '-') : ''">
											<!--<span [ngStyle]="{'curser': 'pointer'}">{{ column.name }}</span>-->
										<th id="upload"></th>
										<th id="shareLink"></th>
										<th id="download"></th>
										<th id="delete"></th>
										<th id="coauthor" *ngIf="coauthorEnabled"></th>
									</tr>
								</thead>
								<tbody>
									<mfiles-row *ngFor="let item of relatedType.objects" [doNotDownload]="item?.extension && configService.supportedVideoFormats.indexOf(item?.extension.toLowerCase()) > -1" [ngStyle]="{'backgroundImage': tiles ? ('File' | thumbnailBackgroundUrl) : 'none', 'background-size': 'File' !== 'Content' ? '40%' : '' }" [view]="view" [item]="item" [inGrid]="inGrid" [completedCriteriaField]="completedCriteriaField" [completedField]="completedField" [completedEnabled]="completedEnabled" [vaultProperties]="vaultProperties" [objectTypes]="objectTypes" [objectLists]="objectLists" [favoritesEnabled]="favoritesEnabled" [coauthorEnabled]="coauthorEnabled" [icon]="objectTypeIcons[item.type] ? objectTypeIcons[item.type].changingThisBreaksApplicationSecurity : ''" [page]="page" [path]="view.path" [pageId]="pageId" [widgetId]="widgetId" [objectTypeIcons]="objectTypeIcons" [relatedParents]="relatedParents" (uploaderOpened)="uploadRevision($event.itemId, $event.fileIndex)" [external]="external" [token]="token" (externalObjectSelected)="subExternalObjectSelected($event)" (deleteClicked)="openVerifyDelete($event.name, $event.itemType, $event.itemId)" (loadIcon)="getIcon($event)">
									</mfiles-row>
								</tbody>
							</table>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</td>
</tr>

<ng-template #completeDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-check"></em>Set Document Completed Criteria</h5>
	</div>
	<div class="modal-body">
		<button class="btn btn-success btn-md" (click)="addCriteria()">Add Criteria</button>
		<p>&nbsp;</p>
		<table [hidden]="completeCriteria.length === 0" class="complete-criteria-form">
			<thead>
				<tr>
					<th id="field">Field</th>
					<th id="comparison">Comparison</th>
					<th id="value">Value</th>
					<th id="remove"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let criteria of completeCriteria;let i = index">
					<td>
						<ng-select class="typeahead" [items]="vaultProperties" [clearable]="false" bindLabel="name" bindValue="id" placeholder="Select Criteria Property" [(ngModel)]="criteria.id" (change)="criteriaFieldChanged($event, i)">
						</ng-select>
					</td>
					<td>
						<select [(ngModel)]="criteria.comparison" class="form-control comparison">
							<option *ngIf="objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 10" value="eq">Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 10" value="ne">Not</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="lt">Less Than</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="gt">Greater Than</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="lte">Less Than Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 1 && objectTypes[criteria.id] !== 13 && objectTypes[criteria.id] !== 9 && objectTypes[criteria.id] !== 10" value="gte">Greater Than Equals</option>
							<option *ngIf="objectTypes[criteria.id] !== 2 && objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7 && objectTypes[criteria.id] !== 9" value="con">Contains</option>
						</select>
					</td>
					<td>
						<input *ngIf="!objectLists[criteria.id] && objectTypes[criteria.id] !== 5 && objectTypes[criteria.id] !== 6 && objectTypes[criteria.id] !== 7" [(ngModel)]="criteria.value" class="form-control">
						<input *ngIf="objectTypes[criteria.id] === 5 || objectTypes[criteria.id] === 6 || objectTypes[criteria.id] === 7" type="text" [(ngModel)]="criteria.value" bsDatepicker class="form-control">
						<ng-select *ngIf="objectLists[criteria.id]" class="typeahead" [items]="objectLists[criteria.id]" [clearable]="false" bindLabel="name" bindValue="id" placeholder="Select Criteria Value" [(ngModel)]="criteria.value">
						</ng-select>
					</td>
					<td>
						<button class="btn-xs btn btn-danger form-control" (click)="removeCriteria(i)">Remove</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="setCriteria()">Accept</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #coauthorModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-user-edit"></em>End Coauthoring</h5>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
		<button type="button" class="btn btn-success btn-md" (click)="resumeCoauthor()">Resume Coauthoring</button>
		<button type="button" class="btn btn-warning btn-md" (click)="undoCoauthor()">Cancel Changes</button>
		<button type="button" class="btn btn-primary btn-md" (click)="checkinCoauthor()">Save Changes</button>
	</div>
</ng-template>

<ng-template #coauthorRequestModal>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-user-edit"></em>Request CoAuthor</h5>
	</div>
	<div class="modal-body share-dialog">
		Email:
		<input type="text" class="form-control" [(ngModel)]="coauthorRequestEmail" />
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
		<button type="button" class="btn btn-success btn-md" [disabled]="!coauthorRequestEmail" (click)="requestCoauthor()">Request</button>
	</div>
</ng-template>

<ng-template #deleteDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-trash"></em> Delete</h5>
	</div>
	<div class="modal-body share-dialog">
		Are you sure you want to delete {{ deleteName }}?
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="deleteObject()">Delete</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #downloadDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-download"></em> Download</h5>
	</div>
	<div class="modal-body share-dialog">
		Your download has been prepared. Please click <a [href]="downloadLink" target="_blank">here</a> to download the file.
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Close</button>
	</div>
</ng-template>