import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Page } from './../models/page.model';
import { Subject } from 'rxjs';
import { PageReorder } from '../models/page-reorder.model';
import { PageEdit } from '../models/page-edit.model';
import { environment } from '@environments/environment';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PageService {
	pagesChanged = new Subject<Page[]>();
	pageEditMode = new Subject<PageEdit>();
	pageReorder = new Subject<PageReorder>();
	pageList: Page[];

	// Used for caching of data
	pageCache = {};

	activePageWidgets: any;

	@Output() documentSelectionOccurred = new EventEmitter<any>();
	@Output() changeExternalUserRightsOccurred = new EventEmitter<string>();
	@Output() draftSaveOccurred = new EventEmitter<boolean>();
	@Output() pageReloadOccurred = new EventEmitter<number>();

	public itemSelectedChanged = new Subject<any>();

	constructor(
		private http: HttpClient
	) { }

	/* Cache */
	addPageCache(page: Page) {
		const pages = this.pageList;
		pages.push(page);

		this.setPagesCache(pages);
	}

	updatePageCache(page: Page) {
		const pages = this.pageList;
		const pos = pages.map(function (e) { return e.id.toString(); }).indexOf(page.id.toString());
		pages[pos] = page;

		this.setPagesCache(pages);
	}

	removePageCache(id: number) {
		const pages = this.pageList;
		const pos = pages.map(function (e) { return e.id.toString(); }).indexOf(id.toString());
		pages.splice(pos, 1);

		this.setPagesCache(pages);
	}

	setPagesCache(pages: Page[]) {
		this.pageList = pages;
		this.pagesChanged.next(this.pageList.slice());
	}

	clearCache() {
		this.pageCache = {};
	}

	/* Requests */
	fetchPages() {
		const params = new HttpParams();
		const requestUrl = `${environment.serviceUrl}/pages`;

		return this.http.get<any>(requestUrl, { params });
	}

	fetchPage(id: number, forceFetch: boolean) {
		if (this.pageCache[id] && !forceFetch) {
			return this.pageCache[id];
		} else {
			let params = new HttpParams();
			params = params.set('id', id.toString());
			const requestUrl = `${environment.serviceUrl}/page`;

			this.pageCache[id] = this.http.get<any>(requestUrl, { params })
				.pipe(
					shareReplay(1)
				);
			return this.pageCache[id];
		}
	}

	deletePage(id: number) {
		let params = new HttpParams();
		params = params.set('id', id.toString());
		const requestUrl = `${environment.serviceUrl}/page`;

		return this.http.delete(requestUrl, { params })
			.pipe(
				tap(() => {
					delete this.pageCache[id];
				})
			);
	}

	deleteDraft(id: number) {
		let params = new HttpParams();
		params = params.set('id', id.toString());
		const requestUrl = `${environment.serviceUrl}/draft`;

		this.pageCache[id] = undefined;
		return this.http.delete(requestUrl, { params });
	}

	createPage(page: Page) {
		let params = new HttpParams();
		params = params.set('title', page.title);

		const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
		const requestUrl = `${environment.serviceUrl}/page`;

		this.pageCache[page.id] = this.http.post<any>(requestUrl, JSON.stringify(page), { params, headers });
		return this.pageCache[page.id];
	}

	updatePage(page: Page) {
		const params = new HttpParams();
		const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
		const requestUrl = `${environment.serviceUrl}/page`;

		// TODO: This code takes the page.widgets GristerItem[] and converts it into a string before
		//       sending to server. I know there is a better way to handle this.
		const tempPage = JSON.parse(JSON.stringify(page));
		tempPage.settings = JSON.stringify(page.settings);
		tempPage.widgets = JSON.stringify(page.widgets);

		this.pageCache[page.id] = this.http.put<any>(requestUrl, JSON.stringify(tempPage), { params, headers });
		return this.pageCache[page.id];
	}

	saveDraft(page: Page) {
		const params = new HttpParams();
		const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
		const requestUrl = `${environment.serviceUrl}/draft`;

		// TODO: This code takes the page.widgets GristerItem[] and converts it into a string before
		//       sending to server. I know there is a better way to handle this.
		const tempPage = JSON.parse(JSON.stringify(page));
		tempPage.settings = JSON.stringify(page.settings);
		tempPage.widgets = JSON.stringify(page.widgets);

		return this.http.put<any>(requestUrl, JSON.stringify(tempPage), { params, headers });
	}

	updatePageOrder(pageId: number, pageReorder: PageReorder) {
		let params = new HttpParams();
		params = params.set('id', pageId + '');
		params = params.set('newIndex', pageReorder.nextIndex + '');

		const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
		const requestUrl = `${environment.serviceUrl}/page/order`;

		return this.http.post<any>(requestUrl, '', { headers, params });
	}

	setPageEditModeChanged(value: PageEdit) {
		this.pageEditMode.next(value);
	}

	setPageReorderEvent(reorder: PageReorder) {
		this.pageReorder.next(reorder);
	}

	setActivePageWidgets(widgets: any) {
		this.activePageWidgets = widgets;
	}

	getActivePageWidgets() {
		return this.activePageWidgets;
	}

	triggerDocumentSelectionEvent(context) {
		this.documentSelectionOccurred.emit(context);
	}

	triggerChangeExternalUserRightsOccurredEvent(documentId) {
		this.changeExternalUserRightsOccurred.emit(documentId);
	}

	triggerDraftSaveOccurredEvent() {
		this.draftSaveOccurred.emit(true);
	}

	setItemSelectedChanged(value: any) {
		this.itemSelectedChanged.next(value);
	}

	reloadPage(pageId: number) {
		this.pageReloadOccurred.emit(pageId);
	}
}
