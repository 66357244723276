import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
	selector: 'app-azure',
	templateUrl: './azure.component.html'
})
export class AzureComponent implements OnInit {

	constructor(
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private loginService: LoginService
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			if (params.auth) {
				sessionStorage.setItem('activeSession', '1');
				this.authService.setToken('jwt', params.auth);

				this.loginService.azureUserInfo().subscribe((result) => {
					const userType: number = result['userType'];
					const isAdminUser: boolean = result['adminUser'];
					const accountName: string = result['accountName'];
					const fullName: string = result['fullName'];

					this.authService.setUserPermissions({ userType: userType }, accountName, isAdminUser, fullName);
					const savedPath = this.authService.getToken('savedPath');
					if (savedPath) {
						let route = savedPath;
						const queryParams = {};
						if (savedPath.indexOf('?') > -1) {
							route = savedPath.substring(0, savedPath.indexOf('?'));
							for (const param of savedPath.substring(savedPath.indexOf('?') + 1).split('&')) {
								queryParams[param.split('=')[0]] = param.split('=')[1];
							}
						}

						this.router.navigate([route], { queryParams });
						this.authService.setToken('savedPath', '');
					} else {
						this.router.navigate(['/page/welcome']);
					}
				});
			}
		});
	}

}
