export class Constants {
	public static readonly BID_REQUEST_OPENED_STATE = 'TI.MConnect.State.BidRequest.BidRequestOpened';
	public static readonly BID_REQUEST_INITIAL_REVIEW_STATE = 'TI.MConnect.State.BidRequest.InitialReview';
	public static readonly BID_REQUEST_ACCEPTED_STATE = 'TI.MConnect.State.BidRequest.AcceptedPreparingResponse';
	public static readonly BID_REQUEST_DOCS_UPLOADED_STATE = 'TI.MConnect.State.BidRequest.DocumentsUploaded';
	public static readonly BID_REQUEST_DECLINED_STATE = 'TI.MConnect.State.BidRequest.BidRequestDeclined';
	public static readonly BID_REQUEST_COMPLETE_STATE = 'TI.MConnect.State.BidRequest.ResponseComplete';
	public static readonly BID_REQUEST_CLOSED_STATE = 'TI.MConnect.State.BidRequest.BidClosed';
	public static readonly BID_REQUEST_AWARDED_STATE = 'TI.MConnect.State.BidRequest.BidAwarded';
	public static readonly BID_CLOSED_STATE = 'TI.MConnect.State.Bid.Closed';
	public static readonly BID_RESPONSE_CLASS = 'TI.MConnect.Class.BidResponse';
	public static readonly INBOUND_BID_MESSAGE_CLASS = 'TI.MConnect.Class.InboundBidMessage';
	public static readonly BID_COMMUNICATION_ATTACHMENT_CLASS = 'TI.MConnect.Class.BidCommunicationAttachment';
	public static readonly VIEWED_BY_PROPERTY = 'TI.MConnect.Property.ViewedBy';
	public static readonly ASSOCIATED_UPLOAD_PROPERTY = 'TI.MConnect.Property.BidAssociatedUploadProperty';
	public static readonly IS_PRIVATE_PROPERTY = 'TI.MConnect.Property.IsPrivate';
	public static readonly INBOUND_BID_MESSAGE_WORKFLOW = 'TI.MConnect.Workflow.InboundBidMessage';
	public static readonly BID_COMMUNICATION_ATTACHMENT_WORKFLOW = 'TI.MConnect.Workflow.BidCommunicationAttachment';
	public static readonly BID_RESPONSE_WORKFLOW = 'TI.MConnect.Workflow.BidResponse';
	public static readonly INBOUND_BID_MESSAGE_STARTING_STATE = 'TI.MConnect.State.InboundBidMessage.StartingState';
	public static readonly BID_COMMUNICATION_ATTACHMENT_STARTING_STATE = 'TI.MConnect.State.BidCommunicationAttachment.StartingState';
	public static readonly BID_RESPONSE_STARTING_STATE = 'TI.MConnect.State.BidResponse.StartingState';
	public static readonly BID_COMMUNICATION_REQUEST_TO_MAKE_PUBLIC_STATE = 'TI.MConnect.State.InboundBidMessage.RequestToMakePublic';
	public static readonly BID_COMMUNICATION_APPROVE_TO_MAKE_PUBLIC_STATE = 'TI.MConnect.State.InboundBidMessage.ApproveToMakePublic';
	public static readonly BID_COMMUNICATION_DECLINE_TO_MAKE_PUBLIC_STATE = 'TI.MConnect.State.InboundBidMessage.DeclineToMakePublic';
	public static readonly REASON_TO_MAKE_PUBLIC = 'TI.MConnect.Property.ReasonToMakePublic';
	public static readonly BID_COMMUNICATION_PROPERTY = 'TI.MConnect.Property.BidCommunication';
	public static readonly BID_REQUEST_REOPENED_STATE = 'TI.MConnect.State.BidRequest.ReopenBidRequest'; // FH-2948
}