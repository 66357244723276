<ng-container [formGroup]="form">
	<div *ngIf="field.dataType === 1">
		<input type="text" [formControlName]="field.id" class="form-control">
	</div>
	<div *ngIf="field.dataType === 2 || field.dataType === 3">
		<input type="number" [formControlName]="field.id" class="form-control">
	</div>
	<div *ngIf="field.dataType === 5 || field.dataType === 6 || field.dataType === 7">
		<input type="text" [formControlName]="field.id" [bsConfig]="bsConfig" bsDatepicker class="form-control">
	</div>
	<div *ngIf="field.dataType === 8">
		<input type="checkbox" [formControlName]="field.id" class="form-control">
	</div>
	<div *ngIf="field.dataType === 9">
		<ng-select class="typeahead form-typeahead" [items]="optionLists[field.property]" [clearable]="false" bindLabel="name" bindValue="id" [id]="field.property" [formControlName]="field.id">
		</ng-select>
	</div>
	<div *ngIf="field.dataType === 10">
		<ng-select class="typeahead form-typeahead" [items]="optionLists[field.property]" [clearable]="false" bindLabel="name" bindValue="id" [id]="field.property" [formControlName]="field.id" (change)="multiselectChanged($event)">
		</ng-select>
		<div class="multiselect-value-row">
			<span *ngFor="let value of multiselectValues; let i = index">
				<span class="multiselect-value">{{value.name}} <em class="fas fa-times" (click)="removeMultiselect(field, i)"></em></span>
			</span>
		</div>
	</div>
	<div *ngIf="field.dataType === 13">
		<textarea [formControlName]="field.id" class="form-control"></textarea>
	</div>
</ng-container>