import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'thumbnailBackgroundUrl'
})
export class ThumbnailPipe implements PipeTransform {
	transform(type: any): any {
		if (type === 'Folder') {
			return './assets/images/icons/folder.svg';
		}
	}
}
