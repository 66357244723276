import { Injectable } from '@angular/core';
import { Event } from '../models/event.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable()
export class EventLogService {
	events: Event[] = [];

	constructor(
		private http: HttpClient
	) { }

	getEvents() {
		const params = new HttpParams();
		const headers = new HttpHeaders()
		const requestUrl = `${environment.serviceUrl}/errors/all`;

		return this.http.get(requestUrl, { params, headers });
	}
}
