import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommentObject } from '../models/comment.model';
import { Property } from '../models/property.model';
import { ConfigurationService } from '../services/configuration.service';
import { ExternalShareService } from '../services/external-share.service';
import { PageService } from '../services/page.service';

declare var $: any;

@Component({
	selector: 'app-external-share',
	templateUrl: './external-share.component.html',
	styleUrls: ['./external-share.component.css']
})
export class ExternalShareComponent implements OnInit {
	modalRef: BsModalRef;
	@ViewChild('deleteDialog') private deleteDialog: TemplateRef<any>;
	@ViewChild('viewerModal') private viewerModal: TemplateRef<any>;

	token = '';
	sharedItem: any;
	sharedItems = [];
	sharedItemsLoaded = false;
	permissions = [];
	title = 'M-Connect Share';
	isAuthed = false;
	loadingTitle = true;

	loading = true;

	error = false;
	errorMessage = '';

	loadedTypes = [];
	objectTypes = [];
	objectTypeIcons = {};
	useMfilesIcons = true;

	dataView = {
		viewContents: {
			columns: [
				{ 'id': -1, 'name': 'Name', 'position': 0, 'width': 500 }
			]
		}
	};

	displayTab = 'viewer';

	uploadClass = -1;
	uploadFiles = [];
	uploadItem: number;
	uploadIndex: number;
	showUpload = false;
	showRevision = false;

	deleteName = '';
	deleteType: number;
	deleteItem: number;

	autoSelect = false;
	autoSelectId;
	firstSelectOccurred = false;

	uploadFormValid = false;
	uploadFormProperties: Property[][];

	vaultProperties: any[];
	formData = [];
	editingProperties = false;
	editProperties: any[];
	optionLists = [];
	optionListTotal = 0;
	optionListLoaded = 0;

	comments: CommentObject[];
	commentsCount = 0;
	loadingComments = false;

	//streamingVideoURL = '';

	coauthorEnabled = false;

	downloadedUrl: SafeUrl;

	public selectedDocumentIndex;
	viewerModalSettings = {
		displayViewer: true,
		displayMetadata: false,
		displayComments: false,
		displayWorkflow: false,
		disableAnnotations: true
	};

	constructor(
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private externalShareService: ExternalShareService,
		public configService: ConfigurationService,
		private pageService: PageService,
		private domSanitizer: DomSanitizer
	) { }

	ngOnInit(): void {
		this.configService.list(false).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'useMfilesIcons') {
					this.useMfilesIcons = config.value.toLowerCase() === 'true';
				} else if (config.name === 'coauthorEnabled') {
					this.coauthorEnabled = config.value.toLowerCase() === 'true';
				}
			}
		});

		this.route.queryParams.subscribe(params => {
			if (params.token) {
				this.sharedItemsLoaded = false;
				this.token = params.token;

				this.loadShare();
			}
			if (params.id) {
				this.autoSelectId = params.id;
			}
		});

		this.externalShareService.getVaultProperties(this.token).subscribe((result) => {
			this.vaultProperties = result;
		});

		this.externalShareService.getObjectTypes(this.token).subscribe((result) => {
			result.forEach((objectType) => {
				this.objectTypes[objectType.id] = objectType;
			});
		});


	}

	loadShare() {
		if (this.autoSelectId) {
			this.firstSelectOccurred = false;
			this.autoSelect = true;
		}

		this.loading = true;
		this.error = false;

		this.sharedItemsLoaded = false;
		this.sharedItems = [];
		this.sharedItem = null;
		this.editingProperties = false;
		this.showRevision = false;
		this.showUpload = false;

		this.externalShareService.getInfo(this.token).subscribe((result) => {
			this.permissions = result.permissions;
			this.uploadClass = result.uploadClass;
			this.title = result.title;
			this.isAuthed = result.isAuthed;
			this.loadingTitle = false;
		});

		this.externalShareService.getItems(this.token).subscribe((result) => {
			this.sharedItems = result;

			for (let i = 0; i < this.sharedItems.length; i++) {
				this.getIcon(this.sharedItems[i].objVer.type);
			}

			this.sharedItems.sort((a, b) => (a.name > b.name) ? 1 : -1);

			this.sharedItemsLoaded = true;
			this.loading = false;

			if (this.autoSelectId) {
				this.autoSelect = true;

				for (let i = 0; i < this.sharedItems.length; i++) {
					if (this.autoSelectId == this.sharedItems[i].id) {
						const defaultItem = this.sharedItems[i];
						this.externalObjectSelected(defaultItem, i);
						break;
					}
				}

			} else if (this.sharedItems.length === 1) {
				this.autoSelect = true;
				this.autoSelectId = this.sharedItems[0].id;
				const defaultItem = this.sharedItems[0];
				this.externalObjectSelected(defaultItem, 0);

				// 	if (this.permissions.indexOf('Comment') > -1) {
				// 		this.loadingComments = true;
				// 		this.externalShareService.getComments(this.token, this.sharedItem.id).subscribe((commentsResult) => {
				// 			this.comments = commentsResult;
				// 			this.setCommentsCount(this.comments);
				// 			this.loadingComments = false;
				// 		});
				// 	}
				// }
			} else {
				this.autoSelect = false;
			}
		});
	}

	setCommentsCount(comments: CommentObject[]) {
		this.commentsCount = 0;
		if (comments.length > 0) {
			this.commentsCount = comments[0].commentCount;
		}
	}

	getIcon(type: number) {
		if (this.useMfilesIcons && !this.loadedTypes.includes(type)) {
			this.loadedTypes.push(type);
			this.externalShareService.getObjectTypeIcon(this.token, type).subscribe((imageResult) => {
				if (imageResult.body && imageResult.body.size !== 0) {
					const reader = new FileReader();
					reader.onloadend = (e) => {
						this.objectTypeIcons[type] = this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
					};
					reader.readAsDataURL(imageResult.body);
				} else {
					this.objectTypeIcons[type] = undefined;
				}
			});
		}
	}

	externalObjectSelected(selectedItem: any, index: number) {
		if (this.sharedItem && this.sharedItem.type === selectedItem.type && this.sharedItem.id === selectedItem.itemId) {
			// Do nothing, the item is already loaded
		} else {
			if (!this.firstSelectOccurred) {
				this.firstSelectOccurred = true;
			} else {
				this.autoSelect = false;
			}

			if (index > -1) {
				this.selectedDocumentIndex = index;
			} else {
				this.selectedDocumentIndex = -1;
			}

			const context = {
				itemId: selectedItem.id,
				type: 0,
				fileIndex: 0
			};

			let triggerModal = false;
			if (!this.modalRef) {
				triggerModal = true;
			}

			if (triggerModal) {
				this.openModal(this.viewerModal, 'lg bid-request');
			} else {
				this.pageService.documentSelectionOccurred.emit(context);
			}

			setTimeout(() => {
				if (triggerModal) {
					this.pageService.documentSelectionOccurred.emit(context);
				}
			}, 500);
		}
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	cancelUpload() {
		this.uploadItem = null;
		this.uploadIndex = null;
		this.showUpload = false;
		this.showRevision = false;
		this.uploadFiles = [];
	}

	openCheckinForRevision(itemId: number, fileIndex: number) {
		this.uploadItem = itemId;
		this.uploadIndex = fileIndex;
		this.showRevision = true;
	}

	onUploadQueueChange(queue: any) {
		this.uploadFiles = queue;
	}

	uploadRevision() {
		this.loading = true;
		this.externalShareService.uploadRevision(this.token, this.uploadItem, this.uploadIndex, this.uploadFiles[0].file).subscribe((result) => {
			this.uploadItem = -1;
			this.uploadIndex = -1;
			this.uploadFiles = [];
			this.loadShare();
		});
	}

	openVerifyDelete(name: string, itemType: number, itemId: number) {
		this.deleteName = name;
		this.deleteType = itemType;
		this.deleteItem = itemId;
		this.openModal(this.deleteDialog, 'md');
	}

	deleteObject() {
		this.modalRef.hide();
		this.loading = true;
		this.externalShareService.deleteObject(this.token, this.deleteType, this.deleteItem, true).subscribe((result) => {
			this.deleteType = -1;
			this.deleteItem = -1;
			this.loadShare();
		});
	}

	onFormChange(forms: UntypedFormGroup[]) {
		let valid = true;
		if (forms.length === 0) {
			this.uploadFormValid = false;
		}
		for (const form of forms) {
			if (!form.valid) {
				valid = false;
				break;
			}
		}
		this.uploadFormValid = valid;
	}

	onPropertiesChange(formProperties: Property[][]) {
		this.uploadFormProperties = formProperties;
	}

	create() {
		this.loading = true;
		this.doCreateUpload(0);
	}

	doCreateUpload(i: number) {
		this.externalShareService.checkinNew(this.token, this.uploadFormProperties[i], this.uploadFiles[i].file).subscribe((result) => {
			if (i + 1 === this.uploadFormProperties.length) {
				this.loadShare();
			} else {
				this.doCreateUpload(i + 1);
			}
		});
	}

	startUpdate() {
		this.loading = true;
		this.formData = [];
		for (const prop of this.sharedItem.properties) {
			if (prop.editable) {
				if (prop.value.dataType === 9) {
					this.formData[prop.propertyDef] = prop.value.lookup.item;
					this.getOptionList(prop.propertyDef);
				} else if (prop.value.dataType === 10) {
					this.formData[prop.propertyDef] = JSON.parse(JSON.stringify(prop.value.lookups));
					this.getOptionList(prop.propertyDef);
				} else if (prop.value.dataType === 5 || prop.value.dataType === 6 || prop.value.dataType === 7) {
					this.formData[prop.propertyDef] = new Date(prop.value.value);
				} else {
					this.formData[prop.propertyDef] = prop.value.value;
				}
			}
		}

		this.editingProperties = true;
		this.editProperties = this.sharedItem.properties;
		if (this.optionListTotal === this.optionListLoaded) {
			this.loading = false;
		}
	}

	getOptionList(propertyDef: number) {
		if (!this.optionLists[propertyDef]) {
			this.optionLists[propertyDef] = [];
			this.optionListTotal = this.optionListTotal + 1;
			for (const prop of this.vaultProperties) {
				if (prop.id === propertyDef) {
					this.externalShareService.getValueListOptions(prop.valueList, this.token).subscribe((result) => {
						this.optionLists[propertyDef] = result.body;
						this.optionListLoaded = this.optionListLoaded + 1;
						if (this.optionListTotal === this.optionListLoaded) {
							this.loading = false;
						}
					});
					break;
				}
			}
		}
	}

	cancelUpdate() {
		this.formData = [];
		this.editingProperties = false;
		this.editProperties = [];
	}

	saveUpdate() {
		this.loading = true;
		const submitProps = [];

		for (const prop of this.editProperties) {
			if (this.formData[prop.propertyDef]) {
				let value = this.formData[prop.propertyDef];
				if (prop.value.dataType === 10) {
					value = '';
					for (const option of this.formData[prop.propertyDef]) {
						value = value + option.id + ',';
					}
					if (value.length > 0) {
						value = value.substring(0, value.length - 1);
					}
				} else if (prop.value.dataType === 5 || prop.value.dataType === 6 || prop.value.dataType === 7) {
					value = new Date(value);
				}

				submitProps.push({ id: prop.propertyDef, dataType: prop.value.dataType, value: value });
			}
		}

		this.externalShareService.updateObject(this.token, this.sharedItem.type, this.sharedItem.id, submitProps).subscribe((result) => {
			this.loadShare();
		});
	}

	multiselectChanged(field: any, event: any) {
		let containsValue = false;

		if (!this.formData[field.propertyDef]) {
			this.formData[field.propertyDef] = [];
		} else {
			for (const value of this.formData[field.propertyDef]) {
				if (value.id === event.id) {
					containsValue = true;
				}
			}
		}

		if (!containsValue) {
			this.formData[field.propertyDef].push({ displayValue: event.name, id: event.id });
		}
	}

	removeMultiselect(field: any, i: number) {
		this.formData[field.propertyDef].splice(i, 1);
	}

	clearCommentForm(addCommentInputField: HTMLTextAreaElement) {
		addCommentInputField.value = '';
	}

	onCommentSubmit(event: any, addCommentInputField: HTMLTextAreaElement) {
		this.loadingComments = true;

		this.externalShareService.postComment(this.token, this.sharedItem.id, null, event.target.comment.value).subscribe((result) => {
			this.comments = result;
			this.setCommentsCount(this.comments);
			this.clearCommentForm(addCommentInputField);
			this.loadingComments = false;
		});
	}

	onNewComment(event: any) {
		this.loadingComments = true;

		this.externalShareService.postComment(this.token, this.sharedItem.id, event.parentComment, event.comment).subscribe((result) => {
			this.comments = result;
			this.setCommentsCount(this.comments);
			this.loadingComments = false;
		});
	}

	downloadFileUrl(itemId: number, fileIndex: number) {
		this.externalShareService.download(this.token, 0, itemId, fileIndex).subscribe((result) => {
			if (result.body && result.body.size !== 0) {
				const reader = new FileReader();
				reader.onloadend = (e) => {
					this.downloadedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
				};
				reader.readAsDataURL(result.body);
			} else {
				this.downloadedUrl = undefined;
			}
		});
	}

	prevFileClicked() {
		if (this.selectedDocumentIndex > 0) {
			const newDocumentIndex = this.selectedDocumentIndex - 1;
			this.externalObjectSelected(this.sharedItems[newDocumentIndex], newDocumentIndex);
			this.pageService.setItemSelectedChanged({ id: this.sharedItems[newDocumentIndex].id, type: this.sharedItems[newDocumentIndex].type });
		}
	}

	nextFileClicked() {
		if (this.selectedDocumentIndex <= this.sharedItems.length) {
			const newDocumentIndex = this.selectedDocumentIndex + 1;
			this.externalObjectSelected(this.sharedItems[newDocumentIndex], newDocumentIndex);
			this.pageService.setItemSelectedChanged({ id: this.sharedItems[newDocumentIndex].id, type: this.sharedItems[newDocumentIndex].type });
		}
	}

	closeViewerModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}
	}
}
