<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<h1 class="pull-left">{{ title }}</h1>
		</header>
		<div class="main-content-header-holder"></div>
	</div>
</div>
<div class="main-content-area system-messages">
	<div class="container-alerts"></div>
	<div class="col-md-12">
		<div class="widget-holder">
			<div class="panel panel-default">
				<div class="panel-body">
					<div>
						<div class="scroll-holder">
							<div class="message" *ngIf="!this.entries">There are no recent system messages.</div>
							<ng-container *ngFor="let entry of entries">
								<div [class]="'message ' + entry.type">
									<span>{{ entry.date }}</span>
									<span>{{ entry.message }}</span>
									<br>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="widget-holder" *ngIf="errorLogs.length > 0">
			<table class="error-log-table">
				<thead>
					<tr>
						<th>Error</th>
						<th>Date</th>
						<th>User</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let errorLog of errorLogs">
						<td>{{ errorLog.error }}</td>
						<td>{{ errorLog.errorDate | date:'medium'}}</td>
						<td>{{ errorLog.username }}</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
</div>