import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { ConfigurationService } from './services/configuration.service';
import { LayoutService } from './services/layout.service';

declare let ga: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
	showHeader = true;

	navigationLocationSubscription: Subscription;
	navigationLocation = 'top';

	navigationPanelExpanded = true;
	navigationPanelExpandedChangedSubscription: Subscription;

	gaInitialized = false;

	routerSubscription: Subscription;

	isAuthenticated = false;

	privacyMessage: string;
	privacyPolicyURL: string;
	termsOfServiceURL: string;
	enableCookieDisclaimer = false;

	cookiesAccepted = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private configService: ConfigurationService,
		private layoutService: LayoutService
	) { }

	ngOnInit() {
		this.subscribeToNavigationLocationChanged();
		this.subscribeToNavigationPanelExpandedChanged();

		this.getAppConfigs();

		this.routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this.gaInitialized) {
					ga('set', 'page', event.urlAfterRedirects);
					ga('send', 'pageview');
				}
				if (this.router.url.indexOf('/login') > -1 || this.router.url.indexOf('/landing') > -1) {
					this.showHeader = false;
				} else {
					this.showHeader = true;
				}
			}
		});

		this.isAuthenticated = this.authService.isAuthenticated();

		if (localStorage.getItem('cookiesAccepted') === '1') {
			this.cookiesAccepted = true;
		}

		if (this.authService.loginConfigs) {
			this.setConfigs();
		} else {
			this.configService.getLoginConfigs().subscribe((configs) => {
				this.authService.loginConfigs = configs;
				this.setConfigs();
			});
		}

		this.authService.logoutOccurred.subscribe((result) => {
			this.getAppConfigs();
		});
	}

	getAppConfigs() {
		this.gaInitialized = false;
		this.navigationLocation = 'top';

		this.configService.getList(['googleAnalyticsEnabled', 'googleAnalyticsId', 'navigationLocation']).subscribe((result) => {
			if (result[0].value === 'true') {
				ga('create', result[1].value, 'auto');
				ga('set', 'page', this.router.url);
				ga('send', 'pageview');

				this.gaInitialized = true;
			}

			this.navigationLocation = result[2].value;
		});
	}

	setConfigs() {
		for (const config of this.authService.loginConfigs) {
			if (config.name === 'privacyMessage') {
				this.privacyMessage = config.value !== null ? config.value : '';
			} else if (config.name === 'privacyPolicyURL') {
				this.privacyPolicyURL = config.value !== null ? config.value : '';
			} else if (config.name === 'termsOfServiceURL') {
				this.termsOfServiceURL = config.value !== null ? config.value : '';
			} else if (config.name === 'enableCookieDisclaimer') {
				this.enableCookieDisclaimer = config.value.toLowerCase() === 'true';
			}
		}
	}

	ngOnDestroy() {
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe();
		}

		if (this.navigationLocationSubscription) {
			this.navigationLocationSubscription.unsubscribe();
		}
	}

	subscribeToNavigationLocationChanged() {
		this.navigationLocationSubscription = this.configService.navigationLocationChanged.subscribe((response) => {
			this.navigationLocation = response;
		});
	}

	subscribeToNavigationPanelExpandedChanged() {
		this.navigationPanelExpandedChangedSubscription = this.layoutService.navigationPanelExpandedChanged.subscribe((value: boolean) => {
			this.navigationPanelExpanded = value;
		});
	}

	acceptCookies() {
		localStorage.setItem('cookiesAccepted', '1');
		this.cookiesAccepted = true;
		this.authService.cookiesAccepted.next(true);
	}
}
