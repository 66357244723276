import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ShareLink } from '../models/share-link.model';
import { ShareLinkService } from '../services/share-link.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'app-share',
	templateUrl: './share.component.html'
})
export class ShareComponent implements OnInit {

	shareLink: ShareLink;
	expired = false;
	accessCode = '';
	isBasicViewer = false;

	constructor(
		private route: ActivatedRoute,
		private shareLinkService: ShareLinkService,
		private toastr: ToastrService,
		private router: Router,
		private authService: AuthService
	) { }

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			const token = params['token'];

			if (params['viewerType']) {
				this.isBasicViewer = params['viewerType'] === 'basic' ? true : false;
			}

			this.shareLinkService.fetchShareLinksByToken(token).subscribe((result) => {
				this.shareLink = result;

				if (new Date(this.shareLink.sharedUntil).getTime() < new Date().getTime()) {
					this.expired = true;
				} else {
					this.expired = false;
				}

				// Used to bypass auth
				if (!this.expired && !this.shareLink.hasAccessCode) {
					this.openShare();
				}
			});
		});
	}

	checkCode() {
		this.shareLinkService.checkAccess(this.shareLink.id, this.accessCode).subscribe((result) => {
			if (result) {
				this.openShare();
			} else {
				this.toastr.error(`Invalid access code.`);
			}
		});
	}

	openShare() {
		this.authService.setActiveShareLink(this.shareLink);
		if (this.shareLink.type === 'document') {
			if (this.isBasicViewer) {
				this.router.navigate(['/file/', this.shareLink.resourceId, 'basic']);
			} else {
				this.router.navigate(['/file/', this.shareLink.resourceId]);
			}
		} else if (this.shareLink.type === 'page') {
			this.router.navigate(['/page/', this.shareLink.resourceId]);
		}
	}

}
