import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { AngularSplitModule } from 'angular-split';
import { UserIdleModule } from 'angular-user-idle';
import { CKEditorModule } from 'ckeditor4-angular';
import * as moment from 'moment';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgProgressModule } from 'ngx-progressbar';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth-interceptor';
import { BidRequestComponent } from './bid-request/bid-request.component';
import { DocumentReviewComponent } from './document-review/document-review.component';
import { ExternalShareComponent } from './external-share/external-share.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { FileComponent } from './file/file.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { FavoriteGuard } from './guards/favorite.guard';
import { RecentGuard } from './guards/recent.guard';
import { SharesGuard } from './guards/shares.guard';
import { HeaderComponent } from './header/header.component';
import { AzureComponent } from './landing/azure/azure.component';
import { MessagesComponent } from './messages/messages.component';
import { MFilesToastComponent } from './models/mfiles-toast.component';
import { RecentItemsComponent } from './recent/recent.component';
import { AuthService } from './services/auth.service';
import { EventLogService } from './services/event-log.service';
import { FavoritesService } from './services/favorite.service';
import { RecentService } from './services/recent.service';
import { ShareComponent } from './share/share.component';
import { SharedModule } from './shared/shared.module';
import { SharesComponent } from './shares/shares.component';
import { SpinnerDirective } from './spinner.directive';
import { UserSettingsComponent } from './user/user-settings.component';

export function momentAdapterFactory() {
	return adapterFactory(moment);
}

@NgModule({
	declarations: [
		AppComponent,
		SpinnerDirective,
		HeaderComponent,
		FileComponent,
		FavoritesComponent,
		MessagesComponent,
		MFilesToastComponent,
		RecentItemsComponent,
		ShareComponent,
		UserSettingsComponent,
		ExternalShareComponent,
		AzureComponent,
		BidRequestComponent,
		SharesComponent,
		DocumentReviewComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AngularSplitModule,
		AppRoutingModule,
		CdkTableModule,
		NgProgressModule,
		CommonModule,
		BrowserAnimationsModule,
		CollapseModule,
		TabsModule.forRoot(),
		TypeaheadModule.forRoot(),
		SharedModule,
		CKEditorModule,
		DragDropModule,
		ToastrModule.forRoot({
			positionClass: 'container-alerts',
			toastComponent: MFilesToastComponent,
			toastClass: 'alert',
			iconClasses: {
				error: 'alert-danger',
				info: 'alert-info',
				success: 'alert-success',
				warning: 'alert-warning'
			}
		}),
		NgSelectModule,
		UserIdleModule.forRoot({ idle: 900, timeout: 120, ping: 300 }),
		MatProgressBarModule
	],
	exports: [
		TabsModule
	],
	providers: [
		AuthService,
		EventLogService,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		AuthInterceptor,
		FavoritesService,
		RecentService,
		AdminGuard,
		AuthGuard,
		RecentGuard,
		FavoriteGuard,
		SharesGuard
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
