import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'customCssExtractor'
})
export class CustomCssExtractor implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }

	transform(inCss: string, inClass?: string) {
		if (!inCss || !inClass) {
			return;
		}

		const classNames = this.getClassNames(inCss);
		const propsAndValues = this.getPropsAndValues(inCss);

		for (let i = 0; i < classNames.length && i < propsAndValues.length; i++) {
			const className = classNames[i];
			const propAndValue = propsAndValues[i];

			if (className === inClass) {
				return this.sanitizer.bypassSecurityTrustStyle(propAndValue);
			}
		}
	}

	getClassNames(inCss: string) {
		return inCss.match(/[^.]+(?={)/g).map(name => {
			return name.trim();
		});
	}

	getPropsAndValues(inCss: string) {
		return inCss.match(/[^{\}]+(?=})/g).map(propAndValue => {
			return propAndValue.trim();
		});
	}
}
