import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class RecentService {

	private defaultHeaders: HttpHeaders = new HttpHeaders().set('Accept', 'application/json');

	constructor(
		protected httpClient: HttpClient
	) { }

	get(): Observable<any> {

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = `${environment.serviceUrl}/mfiles/recent`;

		return this.httpClient.get<any[]>(requestUrl, { headers });
	}

}
