<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<h1 class="pull-left" *ngIf="shareLink">
				{{shareLink.type | titlecase}}: {{shareLink.resourceId}} Share
			</h1>
		</header>
	</div>
</div>
<div>
	<div *ngIf="expired">
		The share link has expired on {{shareLink.sharedUntil | date:'shortDate'}}. Please contact the generator of the link so that it can be renewed.
	</div>
	<div *ngIf="!expired && shareLink">
		<div>
			<h4>Share Details:</h4>
			<table>
				<tr>
					<td>Shared By:</td>
					<td>{{shareLink.sharedBy}}</td>
				</tr>
				<tr>
					<td>Shared To:</td>
					<td>{{shareLink.sharedTo}}</td>
				</tr>
				<tr>
					<td>Shared On:</td>
					<td>{{shareLink.sharedOn | date:'shortDate'}}</td>
				</tr>
				<tr>
					<td>Shared Until:</td>
					<td>{{shareLink.sharedUntil | date:'shortDate'}}</td>
				</tr>
				<tr>
					<td>Shared Permissions:</td>
					<td>{{shareLink.permissions | titlecase}}</td>
				</tr>
			</table>
		</div>

		<br>
		<div *ngIf="shareLink.hasAccessCode">
			<label for="accessCode">Access Code:</label>
			<input id="accessCode" type="text" [(ngModel)]="accessCode">
		</div>

		<button type="button" class="btn btn-success" (click)="checkCode()" *ngIf="shareLink.hasAccessCode" [disabled]="shareLink.hasAccessCode && accessCode === ''">Open Share</button>
		<button type="button" class="btn btn-success" (click)="openShare()" *ngIf="!shareLink.hasAccessCode">Open Share</button>
	</div>
</div>